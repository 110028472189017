import {
	Mutation,
	MutationDeleteFileExportArgs,
	Project,
	FileExport,
} from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import useActiveProject from './useActiveProject';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

const MUTATION = gql`
	mutation deleteFileExport($id: ID!) {
		deleteFileExport(id: $id) {
			id
			project {
				id
			}
		}
	}
`;

export default (): ((
	variables: MutationDeleteFileExportArgs
) => Promise<void>) => {
	const project = useActiveProject();

	const [deleteFileExport] = useMutation<
		Pick<Mutation, 'deleteFileExport'>,
		MutationDeleteFileExportArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.deleteFileExport,
	});

	return async (variables): Promise<void> => {
		try {
			await deleteFileExport({
				variables,
				optimisticResponse: {
					deleteFileExport: {
						__typename: 'FileExport',
						id: variables.id,
						project: {
							__typename: 'Project',
							id: project.id,
						} as Project,
					} as FileExport,
				},
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
