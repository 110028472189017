import {
	Box,
	Button,
	Card,
	CardContent,
	Collapse,
	createStyles,
	FormControl,
	Grid,
	makeStyles,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from '@material-ui/core';
import {
	FilterOption,
	FilterOptions,
	Filters,
	Query,
} from '../../typings/graphql';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { gql, OperationVariables, useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { useActiveProject } from 'hooks';
import { articleParams } from './ArticlesTable';
import LocationSelector, { Location } from 'components/LocationSelector';
import ClickToSortByTableHeaderCell from '../../elements/ClickToSortByTableHeaderCell';
import DimensionSelector from '../../components/DimensionSelector';
import ItemsTableData from './ItemsTableData';
import FilterMenu from '../../components/FilterMenu';
import _ from 'lodash';

const useStyles = makeStyles(() =>
	createStyles({
		bgWhite: {
			background: 'white',
		},
		p1: { paddingTop: '1rem', paddingBottom: '1rem' },
		m1: { marginBottom: '1rem' },
		input: {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'rgba(0, 41, 77, 0.5)',
			},
		},
		h2: {
			marginBottom: '0',
		},
	})
);

const FILTER_OPTIONS_QUERY = gql`
	query filterOptions($input: QueryFilterOptionsInput!) {
		filterOptions(input: $input) {
			colors {
				id
				value
			}
			materials {
				id
				value
			}
			categories {
				id
				value
			}
			subcategories {
				id
				value
			}
			height {
				min
				max
			}
			width {
				min
				max
			}
			depth {
				min
				max
			}
			diameter {
				min
				max
			}
		}
	}
`;

export type FilterSwitch = {
	value: string;
	id: string;
	checked: boolean;
};

export type Dimension = {
	type: keyof Filters;
	defaultValue: number[];
	value: number[];
};

const ItemsTableWrapper = (): JSX.Element => {
	const project = useActiveProject();
	const classes = useStyles();
	const searchInputRef = useRef();
	const params = useParams() as articleParams;
	const paramsArticleId = params.articleId ? params.articleId : '';
	const router = useHistory();

	const { data: filterOptionsData } = useQuery<Pick<Query, 'filterOptions'>>(
		FILTER_OPTIONS_QUERY,
		{
			variables: { input: { project: project.id } },
			returnPartialData: true,
			fetchPolicy: 'cache-and-network',
		}
	);

	const initialLocation: Location = {
		building: undefined,
		floor: undefined,
		room: undefined,
	};

	const filterOptions: FilterOptions = filterOptionsData?.filterOptions || {
		categories: [],
		colors: [],
		materials: [],
		subcategories: [],
	};

	const initialFilterState: Filters = {
		colors: [],
		materials: [],
		categories: [],
		subcategories: [],
		height: [],
		width: [],
		depth: [],
		diameter: [],
	};

	const [rowsPerPage, setRowsPerPage] = useState(10);
	const rowsPerPageOptions = [10, 25, 50];
	const [page, setPage] = useState(0);
	const [searchInput, setSearchInput] = useState('');
	const [sortBy, setSortBy] = useState('id');
	const [location, setLocation] = useState<Location>(initialLocation);
	const [reverseSortOrder, setReverseSortOrder] = useState(false);
	const [dimensionsWindowOpen, setDimensionsWindowOpen] = useState(false);
	const [filters, setFilters] = useState<Filters>(initialFilterState);
	const [resetFilters, setResetFilters] = useState(false);
	const [queryVariables, setQueryVariables] = useState<OperationVariables>({
		input: {
			project: project.id,
			limit: rowsPerPage,
			offset: page > 1 ? rowsPerPage * page : 0,
			sortBy: sortBy,
			reverseSortOrder: true,
			filters: filters,
			searchInput: searchInput.trim(),
			location: {
				buildingId: location.building ? location.building.id : '',
				floorId: location.floor ? location.floor.id : '',
				roomId: location.room ? location.room.id : '',
			},
		},
	});
	const [numberOfItems, setNumberOfItems] = useState(0);

	const handleQueryCompleted = (numberOfItems: number) => {
		setNumberOfItems(numberOfItems);
	};

	useEffect(() => {
		setQueryVariables({
			input: {
				project: project.id,
				limit: rowsPerPage,
				offset: page > 0 ? rowsPerPage * page : 0,
				sortBy: sortBy,
				reverseSortOrder: reverseSortOrder,
				filters: filters,
				searchInput: searchInput.trim(),
				location: {
					buildingId: location.building ? location.building.id : '',
					floorId: location.floor ? location.floor.id : '',
					roomId: location.room ? location.room.id : '',
				},
			},
		});
		setResetFilters(false);
	}, [
		project,
		rowsPerPage,
		page,
		sortBy,
		reverseSortOrder,
		filters,
		searchInput,
		location,
	]);

	const handleDimensionChange = (dimension: Dimension) => {
		setFilters({
			...filters,
			[dimension.type]: dimension.value,
		});
	};

	const handleFilterInputChange = (
		value: string,
		checked: boolean,
		filterKey: keyof Filters
	): void => {
		if (
			checked &&
			filterKey !== 'height' &&
			filterKey !== 'width' &&
			filterKey !== 'depth' &&
			filterKey !== 'diameter'
		)
			setFilters({
				...filters,
				[filterKey]: [...filters[filterKey], value],
			});
		else {
			if (
				filterKey !== 'height' &&
				filterKey !== 'width' &&
				filterKey !== 'depth' &&
				filterKey !== 'diameter'
			) {
				const newFilters = {
					...filters,
					[filterKey]: filters[filterKey].filter((filter) => filter !== value),
				};
				setFilters(newFilters);
			}
		}

		setPage(0);
	};

	const handleResetFiltersClick = () => {
		// suppress serachInputRef.current.value is not a string warning
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		searchInputRef.current.value = '';
		setSearchInput('');
		setFilters(initialFilterState);
		setLocation(initialLocation);
		setResetFilters(true);
		setPage(0);
		router.push('/inventory');
	};

	//the "event" parameter is required, but not used
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleClickHeader = (newSortBy: string) => {
		setReverseSortOrder(newSortBy !== sortBy ? false : !reverseSortOrder);
		setSortBy(newSortBy);
		setPage(0);
	};

	const handleSearchInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setSearchInput(event.target.value);
	};

	const handleLocationChange = (newLocation: Location): void => {
		setLocation(newLocation);
	};

	const debounceSearch = useMemo(() => {
		return _.debounce(handleSearchInputChange, 300);
	}, []);

	useEffect(() => {
		return () => {
			debounceSearch.cancel();
		};
	});

	useEffect(() => {
		if (paramsArticleId) {
			setSearchInput(paramsArticleId);
		}
	}, [paramsArticleId]);

	return (
		<>
			<Card className={classes.m1}>
				<CardContent>
					<h2 className={classes.h2}>Search</h2>
					<Grid item xs={12} md={12}>
						<FormControl fullWidth className={classes.p1}>
							<TextField
								inputRef={searchInputRef}
								variant="outlined"
								onChange={debounceSearch}
								defaultValue={paramsArticleId ? paramsArticleId : null}
								placeholder="Search for items and articles"
								inputProps={{ 'aria-label': 'search companies' }}
								classes={{ root: classes.input }}
							/>
						</FormControl>
					</Grid>

					<Box mt={0.5}>
						<Grid container alignItems="center">
							<Grid item xs={6}>
								<h2>Filters</h2>
							</Grid>
							<Grid container item xs={6} justifyContent="flex-end">
								<Button
									onClick={() => setDimensionsWindowOpen(!dimensionsWindowOpen)}
									color="primary"
									variant="contained"
								>
									{dimensionsWindowOpen ? 'Hide' : 'Show more'} filters
								</Button>
							</Grid>
						</Grid>
					</Box>

					<h3>Product</h3>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={3} xl={3}>
									<FilterMenu
										title={'Category'}
										filterKey={'categories'}
										reset={resetFilters}
										onInputChange={handleFilterInputChange}
										options={(filterOptions.categories as FilterOption[]) || []}
									/>
								</Grid>
								<Grid item xs={12} md={3} xl={3}>
									<FilterMenu
										title={'Subcategory'}
										filterKey={'subcategories'}
										reset={resetFilters}
										onInputChange={handleFilterInputChange}
										options={
											(filterOptions.subcategories as FilterOption[]) || []
										}
									/>
								</Grid>
								<Grid item xs={12} md={3} xl={3}>
									<FilterMenu
										title={'Color'}
										filterKey={'colors'}
										reset={resetFilters}
										onInputChange={handleFilterInputChange}
										options={(filterOptions.colors as FilterOption[]) || []}
									/>
								</Grid>
								<Grid item xs={12} md={3} xl={3}>
									<FilterMenu
										title={'Material'}
										filterKey={'materials'}
										reset={resetFilters}
										onInputChange={handleFilterInputChange}
										options={(filterOptions.materials as FilterOption[]) || []}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Collapse in={dimensionsWindowOpen} timeout="auto" unmountOnExit>
								<Grid container spacing={2}>
									{filterOptions.height && (
										<Grid item xs={12} md={3} xl={3}>
											<DimensionSelector
												type={'height'}
												title={'Height'}
												handleInputChange={handleDimensionChange}
												defaultValue={[
													filterOptions.height.min,
													filterOptions.height.max,
												]}
												step={1}
												reset={resetFilters}
											/>
										</Grid>
									)}
									{filterOptions.width && (
										<Grid item xs={12} md={3} xl={3}>
											<DimensionSelector
												type={'width'}
												title={'Width'}
												handleInputChange={handleDimensionChange}
												defaultValue={[
													filterOptions.width.min,
													filterOptions.width.max,
												]}
												step={1}
												reset={resetFilters}
											/>
										</Grid>
									)}
									{filterOptions.depth && (
										<Grid item xs={12} md={3} xl={3}>
											<DimensionSelector
												type={'depth'}
												title={'Depth'}
												handleInputChange={handleDimensionChange}
												defaultValue={[
													filterOptions.depth.min,
													filterOptions.depth.max,
												]}
												step={1}
												reset={resetFilters}
											/>
										</Grid>
									)}
									{filterOptions.diameter && (
										<Grid item xs={12} md={3} xl={3}>
											<DimensionSelector
												type={'diameter'}
												title={'Diameter'}
												handleInputChange={handleDimensionChange}
												defaultValue={[
													filterOptions.diameter.min,
													filterOptions.diameter.max,
												]}
												step={1}
												reset={resetFilters}
											/>
										</Grid>
									)}
								</Grid>
							</Collapse>
						</Grid>
					</Grid>
					<h3>Location</h3>
					<LocationSelector
						onChange={handleLocationChange}
						initialLocation={location}
						reset={resetFilters}
					/>
					<Box display="flex" justifyContent="flex-end" marginTop={'1rem'}>
						<Button
							onClick={handleResetFiltersClick}
							variant="contained"
							color="secondary"
						>
							Reset filters
						</Button>
					</Box>
				</CardContent>
			</Card>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell className={classes.bgWhite} />
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'name'}
								title={'Name'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() => handleClickHeader('name')}
							/>
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'subcategory.category.name'}
								title={'Category'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() =>
									handleClickHeader('subcategory.category.name')
								}
							/>
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'subcategory.name'}
								title={'Subcategory'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() => handleClickHeader('subcategory.name')}
							/>
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'material.name'}
								title={'Material'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() => handleClickHeader('material.name')}
							/>
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'color.name'}
								title={'Color'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() => handleClickHeader('color.name')}
							/>
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'height'}
								title={'Height'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() => handleClickHeader('height')}
							/>
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'width'}
								title={'Width'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() => handleClickHeader('width')}
							/>
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'depth'}
								title={'Depth'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() => handleClickHeader('depth')}
							/>
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'diameter'}
								title={'Diameter'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() => handleClickHeader('diameter')}
							/>
							<ClickToSortByTableHeaderCell
								currentSortBy={sortBy}
								sortByToSet={'numberOfArticles'}
								title={'Quantity'}
								reverseSortOrder={reverseSortOrder}
								onClickHeader={() => handleClickHeader('numberOfArticles')}
							/>
						</TableRow>
					</TableHead>
					<ItemsTableData
						reset={resetFilters}
						queryVariables={queryVariables}
						onQueryCompleted={handleQueryCompleted}
					/>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={rowsPerPageOptions}
				component="div"
				count={numberOfItems}
				rowsPerPage={rowsPerPage}
				page={numberOfItems <= 0 ? 0 : page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</>
	);
};

export default ItemsTableWrapper;
