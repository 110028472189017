import {
	Button,
	FormControlLabel,
	Menu,
	MenuItem,
	Switch,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FilterSwitch } from '../views/Inventory/ItemsTableWrapper';
import { FilterOption, Filters } from '../typings/graphql';
import { sortBy } from 'lodash';

type Props = {
	title: string;
	filterKey: keyof Filters;
	reset: boolean;
	onInputChange: (
		value: string,
		checked: boolean,
		filterKey: keyof Filters
	) => void;
	options: FilterOption[];
};

const FilterMenu = ({
	title,
	filterKey: filterKey,
	reset,
	onInputChange,
	options,
}: Props): JSX.Element => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);
	const [switches, setSwitches] = useState<FilterSwitch[]>([]);

	useEffect(() => {
		if (reset) {
			[...switches].map((x) => (x.checked = false));
			setOpen(false);
		}
	}, [reset, switches]);

	useEffect(() => {
		if (switches.length === 0) {
			setSwitches(options.map((x) => ({ ...x, checked: false })));
		}
	}, [switches, options]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newSwitches = [...switches];
		const newSwitch = newSwitches.find((x) => x.id === event.target.value);
		if (newSwitch !== undefined) newSwitch.checked = !newSwitch.checked;
		setSwitches(newSwitches);
		if (newSwitch !== undefined)
			onInputChange(event.target.value, newSwitch.checked, filterKey);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpen(!open);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(!open);
		setAnchorEl(null);
	};

	const checkedSwitches = switches.filter((x) => x.checked);

	return (
		<>
			<Button
				variant={checkedSwitches.length > 0 ? 'contained' : 'outlined'}
				color="primary"
				onClick={handleClick}
				aria-controls="simple-menu"
				aria-haspopup="true"
				fullWidth
			>
				{checkedSwitches.length > 0
					? checkedSwitches.length > 3
						? checkedSwitches
								.slice(0, 2)
								.map((x) => x.value)
								.join(' + ') +
						  ' and ' +
						  (checkedSwitches.length - 2) +
						  ' more'
						: checkedSwitches.map((x) => x.value).join(' + ')
					: title}
			</Button>
			<Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
				{sortBy(options, [(option) => option.value.toLowerCase()]).map(
					(option) => {
						return (
							<MenuItem
								key={option.id}
								style={{ cursor: 'default' }}
								disableRipple
							>
								<FormControlLabel
									control={
										<Switch
											checked={
												switches.find((x) => x.value === option.value)?.checked
											}
											onChange={handleChange}
											name={option.value}
											value={option.id}
										/>
									}
									label={option.value}
								/>
							</MenuItem>
						);
					}
				)}
			</Menu>
		</>
	);
};

export default FilterMenu;
