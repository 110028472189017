import {
	Building,
	Mutation,
	MutationCreateBuildingArgs,
	Project,
} from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

const MUTATION = gql`
	mutation createBuilding($building: CreateBuildingInput!) {
		createBuilding(building: $building) {
			id
			name
			createdAt
			project {
				id
			}
		}
	}
`;

export default (): ((
	variables: MutationCreateBuildingArgs
) => Promise<void>) => {
	const [addBuilding] = useMutation<
		Pick<Mutation, 'createBuilding'>,
		MutationCreateBuildingArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createBuilding,
	});

	return async (variables): Promise<void> => {
		try {
			await addBuilding({
				variables,
				optimisticResponse: {
					createBuilding: {
						__typename: 'Building',
						id: variables.building.id!,
						name: variables.building.name!,
						project: {
							__typename: 'Project',
							id: variables.building.projectId,
						} as Project,
					} as Building,
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
