import { updateHandlerByName } from '../graphql';
import { Article, Query } from '../typings/graphql';
import { useSnackbar } from 'notistack';
import {
	useDeleteArticles,
	useSetActiveRoom,
	useSetActiveFloor,
	useSetActiveBuilding,
	useSetActiveProject,
	useDeleteRoom,
	useDeleteFloor,
	useDeleteBuilding,
	useDeleteProject,
	useDeleteCompany,
	useSetActiveCompany,
} from '../hooks';

const useDeleteLocation = (setOpenDeleteDialog: (open: boolean) => void) => {
	const setActiveRoom = useSetActiveRoom();
	const setActiveFloor = useSetActiveFloor();
	const setActiveBuilding = useSetActiveBuilding();
	const setActiveProject = useSetActiveProject();
	const setActiveCompany = useSetActiveCompany();
	const snackbar = useSnackbar();

	const deleteCompany = useDeleteCompany((cache, mutationResult) => {
		updateHandlerByName.deleteCompany(cache, mutationResult, '');
	});

	const deleteProject = useDeleteProject((cache, mutationResult) => {
		updateHandlerByName.deleteProject(cache, mutationResult, '');
	});

	const deleteBuilding = useDeleteBuilding((cache, mutationResult) => {
		updateHandlerByName.deleteBuilding(cache, mutationResult, '');
	});

	const deleteFloor = useDeleteFloor((cache, mutationResult) => {
		updateHandlerByName.deleteFloor(cache, mutationResult, '');
	});

	const deleteRoom = useDeleteRoom((cache, mutationResult) => {
		updateHandlerByName.deleteRoom(cache, mutationResult, '');
	});

	const deleteArticles = useDeleteArticles((cache, mutationResult) => {
		updateHandlerByName.deleteArticlesInRoom(cache, mutationResult, '');
	});

	const handleDeleteCompany = (
		locationsToDelete: Pick<Query, 'locations'> | undefined
	) => {
		try {
			if (locationsToDelete && locationsToDelete.locations?.company) {
				locationsToDelete.locations.company.forEach((company) => {
					if (company) {
						deleteCompany({
							id: company.id,
						});
					}
				});
			}
		} catch (e) {
			console.log('error', e);
			snackbar.enqueueSnackbar('Something went wrong', {
				variant: 'error',
			});
		} finally {
			setActiveCompany({ id: '', name: '' });
			setOpenDeleteDialog(false);

			if (locationsToDelete?.locations.projects?.length) {
				handleDeleteProjects(locationsToDelete);
			} else {
				snackbar.enqueueSnackbar('Successfully deleted!', {
					variant: 'success',
				});
			}
		}
	};

	const handleDeleteProjects = (
		locationsToDelete: Pick<Query, 'locations'> | undefined
	) => {
		try {
			if (locationsToDelete && locationsToDelete.locations?.projects) {
				locationsToDelete.locations.projects.forEach((project) => {
					if (project) {
						deleteProject({
							id: project.id,
						});
					}
				});
			}
		} catch (e) {
			console.log('error', e);
			snackbar.enqueueSnackbar('Something went wrong', {
				variant: 'error',
			});
		} finally {
			setActiveProject({ id: '', name: '' });
			setOpenDeleteDialog(false);

			if (locationsToDelete?.locations.buildings?.length) {
				handleDeleteBuildings(locationsToDelete);
			} else {
				snackbar.enqueueSnackbar('Successfully deleted!', {
					variant: 'success',
				});
			}
		}
	};

	const handleDeleteBuildings = (
		locationsToDelete: Pick<Query, 'locations'> | undefined
	) => {
		try {
			if (locationsToDelete && locationsToDelete.locations?.buildings) {
				locationsToDelete.locations.buildings.forEach((building) => {
					if (building) {
						deleteBuilding({
							id: building.id,
						});
					}
				});
			}
		} catch (e) {
			console.log('error', e);
			snackbar.enqueueSnackbar('Something went wrong', {
				variant: 'error',
			});
		} finally {
			setActiveBuilding({ id: '', name: '' });
			setOpenDeleteDialog(false);

			if (locationsToDelete?.locations.floors?.length) {
				handleDeleteFloors(locationsToDelete);
			} else {
				snackbar.enqueueSnackbar('Successfully deleted!', {
					variant: 'success',
				});
			}
		}
	};

	const handleDeleteFloors = (
		locationsToDelete: Pick<Query, 'locations'> | undefined
	) => {
		try {
			if (locationsToDelete && locationsToDelete.locations?.floors) {
				locationsToDelete.locations.floors.forEach((floor) => {
					if (floor) {
						deleteFloor({
							id: floor.id,
						});
					}
				});
			}
		} catch (e) {
			console.log('error', e);
			snackbar.enqueueSnackbar('Something went wrong', {
				variant: 'error',
			});
		} finally {
			setActiveFloor({ id: '', name: '' });
			setOpenDeleteDialog(false);

			if (locationsToDelete?.locations.rooms?.length) {
				handleDeleteRooms(locationsToDelete);
			} else {
				snackbar.enqueueSnackbar('Successfully deleted!', {
					variant: 'success',
				});
			}
		}
	};

	const handleDeleteRooms = (
		locationsToDelete: Pick<Query, 'locations'> | undefined
	) => {
		try {
			if (locationsToDelete && locationsToDelete.locations?.rooms) {
				locationsToDelete.locations.rooms.forEach((room) => {
					if (room) {
						deleteRoom({
							id: room.id,
						});
					}
				});
			}
		} catch (e) {
			console.log('error', e);
			snackbar.enqueueSnackbar('Something went wrong', {
				variant: 'error',
			});
		} finally {
			setActiveRoom({ id: '', name: '' });
			setOpenDeleteDialog(false);

			if (locationsToDelete?.locations?.articles?.length) {
				const articlesToDelete = locationsToDelete?.locations
					.articles as Article[];
				handleDeleteArticles(articlesToDelete);
			} else {
				snackbar.enqueueSnackbar('Successfully deleted!', {
					variant: 'success',
				});
			}
		}
	};

	const handleDeleteArticles = (articlesToDelete: Article[]) => {
		try {
			const articleIds = articlesToDelete.map((article) => article.id);

			deleteArticles({
				ids: articleIds,
			});
		} catch (e) {
			console.log('error', e);
			snackbar.enqueueSnackbar('Something went wrong', {
				variant: 'error',
			});
		} finally {
			setOpenDeleteDialog(false);

			snackbar.enqueueSnackbar('Successfully deleted!', {
				variant: 'success',
			});
		}
	};

	return {
		handleDeleteCompany,
		handleDeleteProjects,
		handleDeleteBuildings,
		handleDeleteFloors,
		handleDeleteRooms,
		handleDeleteArticles,
	};
};

export default useDeleteLocation;
