import React, { useMemo, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

type Props = {
	icon?: React.ReactElement;
	leftTextPad: boolean;
	to: string;
	primary: string;
};

const ListItemLink = (props: Props): JSX.Element => {
	const { icon, primary, to, leftTextPad } = props;

	const renderLink = useMemo(
		() =>
			// eslint-disable-next-line react/display-name
			forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
				(linkProps, ref) => <Link to={to} {...linkProps} innerRef={ref} />
			),
		[to]
	);

	return (
		<ListItem button component={renderLink}>
			{icon && <ListItemIcon>{icon}</ListItemIcon>}
			<ListItemText
				primary={primary}
				style={leftTextPad ? { paddingLeft: 16 } : undefined}
			></ListItemText>
		</ListItem>
	);
};

export default ListItemLink;
