import {
	useCreateProject,
	useSetActiveProject,
	useActiveCompany,
	useMe,
} from '../hooks';
import React, { useState } from 'react';
import spacetime from 'spacetime';
import { v4 as uuid } from 'uuid';
import EnhancedTable from './EnhancedTable';
import { CellOrder, TableItem } from '../elements/SimpleTable';
import nameSort from '../utils/nameSort';
import CreateNameForm, { FormValues } from './CreateNameForm';
import { Popover } from '@material-ui/core';
import { Query } from '../typings/graphql';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { gql } from '@apollo/client/core';
import DeleteLocations from './DeleteLocations';

export const QUERY = gql`
	query getProjectsByCompany($input: QueryProjectsInput!) {
		projects(input: $input) {
			id
			name
			createdAt
			company {
				id
			}
		}
	}
`;

const cellOrder: CellOrder[] = [
	{
		align: 'left',
		name: 'City',
		itemName: 'name',
	},
	{
		align: 'right',
		name: 'Created',
		itemName: 'createdAt',
	},
];

type Props = {
	onSelection: (event?: MouseEvent) => void;
};

const ProjectsTable = ({ onSelection }: Props): JSX.Element => {
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [projectToDelete, setProjectToDelete] = useState<
		TableItem | undefined
	>();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const company = useActiveCompany();
	const me = useMe();
	const setActiveProject = useSetActiveProject();
	const createProject = useCreateProject();

	const { data, refetch } = useQuery<Pick<Query, 'projects'>>(QUERY, {
		variables: { input: { company: company?.id } },
	});
	const projects = data?.projects;

	const handleTriggerPopover = (
		event: React.MouseEvent<HTMLButtonElement>
	): void => setAnchorEl(event.currentTarget);

	const handlePopoverClose = (): void => setAnchorEl(null);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const variables = {
			project: {
				id: uuid(),
				name: values.name,
				companyId: company?.id,
			},
		};
		createProject(variables);
	};

	const handleListItemClick = (item: TableItem): void => {
		setActiveProject({ id: item.id, name: item.name });
		onSelection();
	};

	const sorted = projects
		?.slice(0)
		.sort(nameSort)
		.map((project) => {
			const createdAt = project.createdAt ? project.createdAt : '';

			const createdAtFormatted = spacetime(createdAt)
				.format('iso-short')
				.toString();

			return { ...project, createdAt: createdAtFormatted };
		});

	const open = Boolean(anchorEl);
	const id = open ? 'create-project-popover' : undefined;

	const handleDeleteLocation = (item: TableItem) => {
		setProjectToDelete(item);
		setOpenDeleteDialog(true);
	};

	return (
		<>
			<EnhancedTable
				empty="No cities exist for this company yet"
				reload={refetch}
				items={sorted ? (sorted as TableItem[]) : []}
				searchPlaceholder="Search for city name"
				order={cellOrder}
				onClick={handleTriggerPopover}
				onDeleteClick={handleDeleteLocation}
				onListItemClick={handleListItemClick}
				canCreate={me?.admin}
				canDelete={me?.admin}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<CreateNameForm
					label="City Name"
					onSubmit={handleSubmit}
					onClose={handlePopoverClose}
				/>
			</Popover>
			<DeleteLocations
				setOpenDeleteDialog={setOpenDeleteDialog}
				openDeleteDialog={openDeleteDialog}
				projectToDelete={projectToDelete}
			/>
		</>
	);
};

export default ProjectsTable;
