import { Mutation, MutationCreateColorArgs, Color } from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

const MUTATION = gql`
	mutation createColor($color: CreateColorInput!) {
		createColor(color: $color) {
			id
			name
		}
	}
`;

export default (): ((variables: MutationCreateColorArgs) => Promise<void>) => {
	const [addColor] = useMutation<
		Pick<Mutation, 'createColor'>,
		MutationCreateColorArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createColor,
	});

	return async (variables: MutationCreateColorArgs): Promise<void> => {
		try {
			await addColor({
				variables,
				optimisticResponse: {
					createColor: {
						__typename: 'Color',
						id: variables.color.id!,
						name: variables.color.name,
					} as Color,
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
