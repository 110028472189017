import _ from 'lodash';
import setActiveBuilding from '../activeBuilding/setActiveBuilding';
import setActiveFloor from '../activeFloor/setActiveFloor';
import setActiveRoom from '../activeRoom/setActiveRoom';
import setActiveProject from '../activeProject/setActiveProject';
import { gql } from '@apollo/client/core';

type TVariables = {
	input: {
		rid: string;
		name: string;
	};
};

const QUERY = gql`
	query activeCompany {
		activeCompany @client {
			rid
			name
		}
	}
`;

export default (_root: void, variables: TVariables, { cache }: TContext) => {
	const cacheData = cache.readQuery({ query: QUERY });
	if (cacheData === null) return;

	const newCache = _.cloneDeep(cacheData);
	newCache.activeCompany.rid = variables.input.rid;
	newCache.activeCompany.name = variables.input.name;

	cache.writeQuery({ query: QUERY, data: newCache });

	setActiveProject(undefined, { input: { rid: '', name: '' } }, { cache });
	setActiveBuilding(undefined, { input: { rid: '', name: '' } }, { cache });
	setActiveFloor(undefined, { input: { rid: '', name: '' } }, { cache });
	setActiveRoom(undefined, { input: { rid: '', name: '' } }, { cache });

	return newCache.activeProject;
};

export { QUERY as SET_ACTIVE_COMPANY_QUERY };
