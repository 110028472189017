import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query, ItemSubcategory } from '../typings/graphql';

const QUERY = gql`
	query itemCategories {
		itemCategories {
			id
			name
			subcategories {
				id
				name
			}
		}
	}
`;

export const handleCreateItemSubcategoryUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createItemSubcategory'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createItemSubcategory } = data;

	if (!createItemSubcategory) return;

	const cacheData = cache.readQuery<Pick<Query, 'itemCategories'>>({
		query: QUERY,
	});
	if (cacheData === null) return;

	const itemCategory = cacheData.itemCategories.find(
		(itemCategory) => itemCategory.id === createItemSubcategory.category.id
	);
	if (!itemCategory) return;

	const itemCategories = cacheData.itemCategories.filter(
		(itemCategory) => itemCategory.id !== createItemSubcategory.category.id
	);

	itemCategory?.subcategories.push({
		__typename: 'ItemSubcategory',
		id: createItemSubcategory.id,
		name: createItemSubcategory.name,
	} as ItemSubcategory);

	itemCategories.push(itemCategory);

	cache.writeQuery({
		query: QUERY,
		data: { itemCategories },
	});
};

export const handleDeleteItemSubcategoryUpdate: MutationUpdaterFn<
	Pick<Mutation, 'deleteItemSubcategory'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { deleteItemSubcategory } = data;

	if (!deleteItemSubcategory) return;

	const cacheData = cache.readQuery<Pick<Query, 'itemCategories'>>({
		query: QUERY,
	});
	if (cacheData === null) return;

	const itemCategory = cacheData.itemCategories.find((itemCategory) =>
		itemCategory.subcategories.find(
			(subcategory) => subcategory.id === deleteItemSubcategory.id
		)
	);
	if (!itemCategory) return;

	const itemCategories = cacheData.itemCategories.filter(
		(category) => category.id !== itemCategory.id
	);

	const subcategories = itemCategory?.subcategories.filter(
		(itemSubcategory) => itemSubcategory.id !== deleteItemSubcategory.id
	);

	itemCategory.subcategories = subcategories;

	itemCategories.push(itemCategory);

	cache.writeQuery({ query: QUERY, data: { itemCategories } });
};
