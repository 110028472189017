import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import { Mutation, MutationDeleteArticleArgs } from '../typings/graphql';

const MUTATION = gql`
	mutation deleteArticle($id: ID!) {
		deleteArticle(id: $id) {
			id
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'deleteArticle'>>
) => {
	const [deleteArticle] = useMutation<
		Pick<Mutation, 'deleteArticle'>,
		MutationDeleteArticleArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationDeleteArticleArgs) =>
		deleteArticle({
			variables,
		});
};
