import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
} from '@material-ui/core';
import { TableItem } from 'elements/SimpleTable';
import { Query } from '../typings/graphql';
import useDeleteLocation from 'hooks/useDeleteLocations';
import useLocationWarning from 'hooks/useLocationWarning';

export const QUERY = gql`
	query locations($input: QueryLocationsInput!) {
		locations(input: $input) {
			company {
				id
				name
			}
			projects {
				id
				name
			}
			buildings {
				id
				name
			}
			floors {
				id
				name
			}
			rooms {
				id
				name
			}
			articles {
				id
				item {
					id
				}
			}
		}
	}
`;

type Props = {
	companyToDelete?: TableItem | undefined;
	projectToDelete?: TableItem | undefined;
	buildingToDelete?: TableItem | undefined;
	floorToDelete?: TableItem | undefined;
	roomToDelete?: TableItem | undefined;
	openDeleteDialog: boolean;
	setOpenDeleteDialog: (open: boolean) => void;
};

const DeleteLocations = ({
	companyToDelete,
	projectToDelete,
	buildingToDelete,
	floorToDelete,
	roomToDelete,
	openDeleteDialog,
	setOpenDeleteDialog,
}: Props) => {
	const {
		handleDeleteCompany,
		handleDeleteProjects,
		handleDeleteBuildings,
		handleDeleteFloors,
		handleDeleteRooms,
	} = useDeleteLocation(setOpenDeleteDialog);

	let input;
	if (companyToDelete) {
		input = { company: companyToDelete?.id };
	} else if (projectToDelete) {
		input = { project: projectToDelete?.id };
	} else if (buildingToDelete) {
		input = { building: buildingToDelete?.id };
	} else if (floorToDelete) {
		input = { floor: floorToDelete?.id };
	} else if (roomToDelete) {
		input = { room: roomToDelete?.id };
	}

	const { data: locationsToDelete } = useQuery<Pick<Query, 'locations'>>(
		QUERY,
		{
			variables: {
				input,
			},
			skip: !input,
		}
	);

	const warning = useLocationWarning({
		locationsToDelete,
		companyToDelete,
		projectToDelete,
		buildingToDelete,
		floorToDelete,
		roomToDelete,
	});

	const handleDelete = () => {
		if (companyToDelete) {
			handleDeleteCompany(locationsToDelete);
		} else if (projectToDelete) {
			handleDeleteProjects(locationsToDelete);
		} else if (buildingToDelete) {
			handleDeleteBuildings(locationsToDelete);
		} else if (floorToDelete) {
			handleDeleteFloors(locationsToDelete);
		} else if (roomToDelete) {
			handleDeleteRooms(locationsToDelete);
		}
	};

	return (
		<Dialog
			open={openDeleteDialog}
			onClose={() => setOpenDeleteDialog(false)}
			aria-describedby="delete-locations"
		>
			<DialogContent>
				{locationsToDelete ? (
					<DialogContentText>{warning}</DialogContentText>
				) : (
					<Grid component={'span'}>
						<CircularProgress
							variant="indeterminate"
							style={{ width: '20px', height: '20px' }}
						/>
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
				<Button onClick={() => handleDelete()}>Confirm</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteLocations;
