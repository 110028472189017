import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { updateHandlerByName } from '../graphql';
import {
	MutationCreateRoomArgs,
	Mutation,
	Floor,
	Room,
} from '../typings/graphql';

const MUTATION = gql`
	mutation createRoom($room: CreateRoomInput!) {
		createRoom(room: $room) {
			id
			name
			floor {
				id
			}
		}
	}
`;

export default (): ((variables: MutationCreateRoomArgs) => Promise<void>) => {
	const [addRoom] = useMutation<
		Pick<Mutation, 'createRoom'>,
		MutationCreateRoomArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createRoom,
	});

	return async (variables): Promise<void> => {
		try {
			await addRoom({
				variables,
				optimisticResponse: {
					createRoom: {
						__typename: 'Room',
						id: variables.room.id!,
						name: variables.room.name,
						floor: {
							__typename: 'Floor',
							id: variables.room.floorId,
						} as Floor,
					} as Room,
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
