import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const QUERY = gql`
	query getProjectsByCompany($input: QueryProjectsInput!) {
		projects(input: $input) {
			id
		}
	}
`;

export const handleCreateProjectUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createProject'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createProject } = data;

	if (!createProject) return;

	try {
		const cacheData = cache.readQuery<Pick<Query, 'projects'>>({
			query: QUERY,
			variables: { input: { company: createProject.company.id } },
		});
		if (cacheData === null) return;

		const projects = cacheData.projects.filter(
			(project) => project.id !== createProject.id
		);
		projects.push(createProject);

		cache.writeQuery({
			query: QUERY,
			variables: { input: { company: createProject.company.id } },
			data: { projects },
		});
	} catch (e) {
		// We optimistically write data since nothing exists
		const projects = [createProject];
		cache.writeQuery({
			query: QUERY,
			variables: { input: { company: createProject.company.id } },
			data: { projects },
		});
	}
};

export const handleDeleteProjectUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteProject'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const projectIdToDelete = data.deleteProject?.id;
	const normalizedProjectId = cache.identify({
		id: projectIdToDelete,
		__typename: 'Project',
	});
	cache.evict({ id: normalizedProjectId });
	cache.gc();
};
