import {
	FileFormat,
	Mutation,
	MutationGenerateFileExportArgs,
	Project,
} from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

const MUTATION = gql`
	mutation generateFileExport($input: GenerateFileExportInput!) {
		generateFileExport(input: $input) {
			id
			name
			url
			format
			project {
				id
			}
		}
	}
`;

export default (): ((
	variables: MutationGenerateFileExportArgs
) => Promise<void>) => {
	const [generateFileExport] = useMutation<
		Pick<Mutation, 'generateFileExport'>,
		MutationGenerateFileExportArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.generateFileExport,
	});

	return async (variables): Promise<void> => {
		try {
			await generateFileExport({
				variables,
				optimisticResponse: {
					generateFileExport: {
						__typename: 'FileExport',
						id: variables.input.id!,
						name: variables.input.name,
						url: undefined,
						format:
							variables.input.variant === 'CSV'
								? FileFormat.Csv
								: FileFormat.Pdf,
						project: {
							__typename: 'Project',
							id: variables.input.projectId,
						} as Project,
					},
				},
			});
		} catch (error) {
			console.log('err', error);
			throw error;
		}
	};
};
