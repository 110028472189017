import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import spacetime from 'spacetime';
import { updateHandlerByName } from '../graphql';
import {
	Mutation,
	MutationCreateProjectArgs,
	Company,
	Project,
} from '../typings/graphql';

const MUTATION = gql`
	mutation createProject($project: CreateProjectInput!) {
		createProject(project: $project) {
			id
			name
			createdAt
			company {
				id
			}
		}
	}
`;

export default (): ((
	variables: MutationCreateProjectArgs
) => Promise<void>) => {
	const [addProject] = useMutation<
		Pick<Mutation, 'createProject'>,
		MutationCreateProjectArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createProject,
	});

	return async (variables): Promise<void> => {
		try {
			await addProject({
				variables,
				optimisticResponse: {
					createProject: {
						__typename: 'Project',
						id: variables.project.id!,
						name: variables.project.name,
						createdAt: spacetime.now().format('iso').toString(),
						company: {
							__typename: 'Company',
							id: variables.project.companyId,
						} as Company,
					} as Project,
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
