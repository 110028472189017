import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const QUERY = gql`
	query itemCategories {
		itemCategories {
			id
			name
		}
	}
`;

export const handleCreateItemCategoryUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createItemCategory'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createItemCategory } = data;

	if (!createItemCategory) return;

	try {
		const cacheData = cache.readQuery<Pick<Query, 'itemCategories'>>({
			query: QUERY,
		});
		if (cacheData === null) return;

		const itemCategories = cacheData.itemCategories.filter(
			(itemCategory) => itemCategory.id !== createItemCategory.id
		);
		itemCategories.push(createItemCategory);

		cache.writeQuery({
			query: QUERY,
			data: { itemCategories },
		});
	} catch (e) {
		// We optimistically write data since nothing exists
		const itemCategories = [createItemCategory];
		cache.writeQuery({
			query: QUERY,
			data: { itemCategories },
		});
	}
};

export const handleDeleteItemCategoryUpdate: MutationUpdaterFn<
	Pick<Mutation, 'deleteItemCategory'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { deleteItemCategory } = data;

	if (!deleteItemCategory) return;

	const cacheData = cache.readQuery<Pick<Query, 'itemCategories'>>({
		query: QUERY,
	});
	if (cacheData === null) return;

	const itemCategories = cacheData.itemCategories.filter(
		(itemCategory) => itemCategory.id !== deleteItemCategory.id
	);

	cache.writeQuery({ query: QUERY, data: { itemCategories } });
};
