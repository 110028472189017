import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import { Mutation, MutationDeleteRoomArgs } from '../typings/graphql';

const MUTATION = gql`
	mutation deleteRoom($id: ID!) {
		deleteRoom(id: $id) {
			id
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'deleteRoom'>>
) => {
	const [deleteRoom] = useMutation<
		Pick<Mutation, 'deleteRoom'>,
		MutationDeleteRoomArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationDeleteRoomArgs) => {
		deleteRoom({
			variables,
		});
	};
};
