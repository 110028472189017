import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const QUERY = gql`
	query getFloorByBuilding($input: QueryFloorsInput!) {
		floors(input: $input) {
			id
			name
			building {
				id
			}
		}
	}
`;

export const handleCreateFloorUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createFloor'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createFloor } = data;

	if (!createFloor) return;

	try {
		const cacheData = cache.readQuery<Pick<Query, 'floors'>>({
			query: QUERY,
			variables: { input: { building: createFloor.building.id } },
		});
		if (cacheData === null) return;

		const floors = cacheData.floors.filter(
			(floor) => floor.id !== createFloor.id
		);
		floors.push(createFloor);

		cache.writeQuery({
			query: QUERY,
			variables: { input: { building: createFloor.building.id } },
			data: { floors },
		});
	} catch (e) {
		// We optimistically write data since nothing exists
		const floors = [createFloor];
		cache.writeQuery({
			query: QUERY,
			variables: { input: { building: createFloor.building.id } },
			data: { floors },
		});
	}
};

export const handleDeleteFloorUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteFloor'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const floorIdToDelete = data.deleteFloor?.id;
	const normalizedFloorId = cache.identify({
		id: floorIdToDelete,
		__typename: 'Floor',
	});
	cache.evict({ id: normalizedFloorId });
	cache.gc();
};
