import {
	Button,
	Card,
	CardActions,
	CardContent,
	FormControl,
	TextField,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

export type FormValues = {
	name: string;
};

type Props = {
	label: string;
	onClose: () => void;
	onSubmit: (values: FormValues) => Promise<void>;
};

type FormProps = {
	initialName?: string | null;
};

const InnerForm = (props: FormikProps<FormValues> & Props): JSX.Element => {
	const {
		values,
		touched,
		errors,
		handleChange,
		handleSubmit,
		onClose,
		isSubmitting,
		label,
	} = props;

	return (
		<form onSubmit={handleSubmit}>
			<Card>
				<CardContent>
					<FormControl variant="outlined" fullWidth>
						<TextField
							autoFocus
							fullWidth
							id="choose-name-input"
							label={label}
							name="name"
							margin="dense"
							type="text"
							variant="outlined"
							value={values.name}
							onChange={handleChange}
							error={!!errors.name && touched.name}
							helperText={errors.name ? errors.name : null}
						/>
					</FormControl>
				</CardContent>
				<CardActions style={{ justifyContent: 'flex-end' }}>
					<Button onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button
						type="submit"
						color="primary"
						variant="contained"
						disabled={isSubmitting}
					>
						Submit
					</Button>
				</CardActions>
			</Card>
		</form>
	);
};

const CreateNameForm = withFormik<FormProps & Props, FormValues>({
	mapPropsToValues: (props) => ({
		name: props.initialName || '',
	}),

	validationSchema: Yup.object().shape({
		name: Yup.string().required('Required').trim(),
	}),

	async handleSubmit(values, { props }) {
		props.onClose();
		return props.onSubmit(values);
	},
})(InnerForm);

export default CreateNameForm;
