import React from 'react';
import { getProjectPermissions } from 'utils/helpers';
import { Divider, List, ListSubheader } from '@material-ui/core';
import { useActiveProject, useMe } from 'hooks';
import ListItemLink from 'elements/ListItemLink';

const ActiveProjectList = (): JSX.Element | null => {
	const project = useActiveProject();
	const me = useMe();
	let projectPermissions = null;

	if (me !== undefined) {
		projectPermissions = me?.admin
			? null
			: getProjectPermissions(me, project.id);
	}

	if (!project.id) return null;

	return (
		<>
			<List
				component="nav"
				subheader={
					<ListSubheader component="div">City - {project.name}</ListSubheader>
				}
			>
				<ListItemLink
					leftTextPad={true}
					primary="Items"
					to="/items"
				></ListItemLink>
				{(projectPermissions?.canRead || me?.admin) && (
					<ListItemLink
						leftTextPad={true}
						primary="Exports"
						to="/exports"
					></ListItemLink>
				)}
				{me?.admin && (
					<ListItemLink
						leftTextPad={true}
						primary="Imports"
						to="/imports"
					></ListItemLink>
				)}
			</List>
			<Divider />
		</>
	);
};

export default ActiveProjectList;
