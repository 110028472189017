import { Container } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useScreenSizeIndicator } from '../hooks';

type StyleProps = {
	breakpoint: string;
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
	createStyles({
		content: {
			marginLeft: (props: StyleProps): number =>
				props.breakpoint === 'xs' ? 0 : drawerWidth,
			marginTop: theme.spacing(6),
			backgroundColor: theme.palette.background.default,
			display: 'flex',
			paddingTop: theme.spacing(4),
			minHeight: '94vh',
		},
	})
);

type TProps = {
	// This is the children type used by the MUI Container component, not generally within React.
	children: NonNullable<React.ReactNode>;
};

const Content = ({ children }: TProps): JSX.Element => {
	const breakpoint = useScreenSizeIndicator();
	const classes = useStyles({ breakpoint });

	return (
		<main className={classes.content}>
			<Container maxWidth={false}>{children}</Container>
		</main>
	);
};

export default Content;
