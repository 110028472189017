import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useContext } from 'react';
import { Query } from '../../typings/graphql';
import { CHANGE_MATERIAL_ACTION, ItemFilterContext } from '.';
import { gql } from '@apollo/client/core';

const MATERIALS_QUERY = gql`
	query materials {
		materials {
			id
			name
		}
	}
`;

const FilterMaterialSelect = (): JSX.Element | null => {
	const { state, dispatch } = useContext(ItemFilterContext);

	const { data } = useQuery<Pick<Query, 'materials'>>(MATERIALS_QUERY);

	if (!data?.materials) return null;

	const sorted = data.materials
		.slice(0)
		.sort((a, b) => a.name.localeCompare(b.name));

	return (
		<FormControl variant="outlined" fullWidth>
			<InputLabel id="filter-by-material">Material</InputLabel>
			<Select
				labelId="filter-by-material"
				id="filter-by-material-select"
				value={state.material}
				onChange={(event): void =>
					dispatch({
						type: CHANGE_MATERIAL_ACTION,
						payload: event.target.value,
					})
				}
				labelWidth={127}
				name="material"
				fullWidth
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{sorted.map((el) => (
					<MenuItem key={el.id} value={el.id}>
						{el.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default FilterMaterialSelect;
