import { useQuery } from '@apollo/client';
import {
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	List,
	ListSubheader,
	Switch,
} from '@material-ui/core';
import { USER_PERMS_QUERY } from 'graphql/users';
import useDeleteProjectPermissions from 'hooks/useDeleteProjectPermissions';
import usePutProjectPermissions from 'hooks/usePutProjectPermissions';
import useUpdateEmployment from 'hooks/useUpdateEmployment';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import spacetime from 'spacetime';
import { getEmployment, projPermsToPutInputWithName } from 'utils/helpers';
import { updateHandlerByName } from '../graphql';
import {
	MutationDeleteProjectPermissionsArgs,
	MutationPutProjectPermissionsArgs,
	ProjectPermissions,
	Query,
	QueryUserArgs,
} from '../typings/graphql';
import AddProjPermsListItem from './AddProjPermsListItem';
import { PermsEditorListItem } from './PermsEditorListItem';
import { extractErrorMessage } from 'utils/errorMessages';

type Props = {
	open: boolean;
	handleClose: () => void;
	userId: string;
};

export type PutProjPermsArgsWithName = MutationPutProjectPermissionsArgs & {
	projectName: string;
};

const EditEmploymentDialog = ({
	open,
	userId,
	handleClose,
}: Props): JSX.Element => {
	// We use local state because we will modify it in case the employment or any
	// related permissions are edited. The initial value is undefined, and then updated
	// on query completion. This is solution is based on an answer to the following
	// StackOverflow question about React state dependent on the useQuery hook:
	// https://stackoverflow.com/questions/57577102/store-data-from-usequery-with-usestate
	const [localPermissions, setLocalPermissions] = useState<
		PutProjPermsArgsWithName[] | undefined
	>(undefined);
	const [localStructAdmin, setLocalStructAdmin] = useState<boolean | undefined>(
		undefined
	);
	const [localAuthAdmin, setLocalAuthAdmin] = useState<boolean | undefined>(
		undefined
	);

	const [editing, setEditing] = useState(false);

	const { data: userData, loading: userLoading } = useQuery<
		Pick<Query, 'user'>,
		QueryUserArgs
	>(USER_PERMS_QUERY, {
		variables: { id: userId },
		onCompleted: () => {
			setLocalPermissions(
				userData?.user?.employments[0].projectPermissionsList.map(
					projPermsToPutInputWithName
				)
			);
			setLocalStructAdmin(userData?.user?.employments[0].structAdmin);
			setLocalAuthAdmin(userData?.user?.employments[0].authAdmin);
		},
		skip: !open,
	});

	const saveProjPermChanges = usePutProjectPermissions(
		_.partialRight(updateHandlerByName.putProjectPermissions, userId)
	);

	const saveEmploymentChanges = useUpdateEmployment((cache, mutationResult) =>
		updateHandlerByName.updateEmployment(cache, mutationResult, userId)
	);

	const saveProjPermsDeletion = useDeleteProjectPermissions(
		_.partialRight(updateHandlerByName.deleteProjectPermissions, userId)
	);

	const snackbar = useSnackbar();

	const localsLoaded = () =>
		typeof localPermissions !== 'undefined' &&
		typeof localAuthAdmin !== 'undefined' &&
		typeof localStructAdmin !== 'undefined';

	const handleSaveClick = async () => {
		if (!localsLoaded() || !userData) {
			throw new Error('cannot save when not all local values are defined');
		} else {
			const userEmployment = getEmployment(userData.user);
			const previousPerms: readonly ProjectPermissions[] = getEmployment(
				userData.user
			).projectPermissionsList;
			const localPermsProjectIds = new Set(
				localPermissions?.map((p) => p.input.projectId)
			);
			const permsToDelete: MutationDeleteProjectPermissionsArgs[] =
				previousPerms
					.filter((p) => !localPermsProjectIds.has(p.project.id))
					.map((p) => ({
						input: {
							employmentId: p.employment.id,
							projectId: p.project.id,
						},
					}));
			try {
				await Promise.all([
					...localPermissions!.map(saveProjPermChanges),
					...permsToDelete.map(saveProjPermsDeletion),
					saveEmploymentChanges({
						input: {
							authAdmin: localAuthAdmin,
							structAdmin: localStructAdmin,
							employmentId: userEmployment.id,
						},
					}),
				]);
				setEditing(false);
				handleClose();
				snackbar.enqueueSnackbar(
					`Updated permissions for user ${userData.user?.name}`,
					{ variant: 'success' }
				);
			} catch (e) {
				handleClose();
				const errorMessage = extractErrorMessage(e);
				snackbar.enqueueSnackbar(errorMessage, {
					variant: 'error',
				});
			}
		}
	};

	const checkboxLabels = {
		structAdmin: 'Structure admin',
		authAdmin: 'Authorization admin',
	};
	const getCheckboxState = (adminType: keyof typeof checkboxLabels) =>
		adminType === 'structAdmin' ? localStructAdmin : localAuthAdmin;
	const toggleCheckboxState = (adminType: keyof typeof checkboxLabels) =>
		adminType === 'structAdmin'
			? setLocalStructAdmin(!localStructAdmin)
			: setLocalAuthAdmin(!localAuthAdmin);

	const resetLocals = () => {
		if (!userData?.user) {
			throw new Error('cannot reset state with undefined user');
		}
		setLocalPermissions(
			getEmployment(userData?.user).projectPermissionsList.map(
				projPermsToPutInputWithName
			)
		);
		setLocalStructAdmin(userData?.user?.employments[0].structAdmin);
		setLocalAuthAdmin(userData?.user?.employments[0].authAdmin);
	};

	const toggleEditing = () => {
		resetLocals();
		setEditing(!editing);
	};

	/**
	 * Takes old permissions and updated information as input, and creates a new object representing the
	 * updated project permissions.
	 */
	const makeNewProjPerms = (
		oldPerms: PutProjPermsArgsWithName,
		newPerms: LimitedProjectPermissions
	): PutProjPermsArgsWithName => {
		const updatedPerms = { ...oldPerms };
		updatedPerms.input = { ...updatedPerms.input, ...newPerms };
		return updatedPerms;
	};

	const handleProjectPermissionsChange = (
		idx: number,
		newPerms: LimitedProjectPermissions
	) => {
		if (typeof localPermissions !== 'object') {
			throw new Error('localPermissions was undefined');
		}
		const copiedPermissions = [...localPermissions];
		copiedPermissions[idx] = makeNewProjPerms(copiedPermissions[idx], newPerms);
		setLocalPermissions(copiedPermissions);
	};

	// adds the project permissions to the local list of project permissions to delete,
	// and removes the project permissions from the local list
	const handleProjectPermissionsDelete = (idx: number) => {
		if (typeof localPermissions !== 'object') {
			throw new Error('localPermissions was undefined');
		}

		// remove the project permissions from the local list
		const newPermissions = [...localPermissions];
		newPermissions.splice(idx, 1);
		setLocalPermissions(newPermissions);
	};

	const handleProjectPermissionsAdded = (
		projectId: string,
		projectName: string
	) => {
		if (typeof localPermissions !== 'object') {
			throw new Error(
				'cannot add new permissions if localPermissions is undefined'
			);
		}
		const newProjectPermissions: PutProjPermsArgsWithName = {
			input: {
				canCreate: false,
				canDelete: false,
				canRead: false,
				canUpdate: false,
				employmentId: getEmployment(userData?.user).id,
				expiresAt: spacetime(new Date()).add(1, 'week').format('iso'),
				projectId,
			},
			projectName,
		};
		const newLocalPermissions = [...localPermissions];
		newLocalPermissions.push(newProjectPermissions);
		setLocalPermissions(newLocalPermissions);
	};

	const closeDialog = () => {
		resetLocals();
		setEditing(false);
		handleClose();
	};

	return (
		<Dialog open={open} onClose={closeDialog} fullWidth>
			{userLoading || !localsLoaded() ? (
				<CircularProgress variant="indeterminate" />
			) : (
				<>
					<DialogTitle>Permissions for {userData?.user?.name}</DialogTitle>
					<DialogContent>
						{(['structAdmin', 'authAdmin'] as const).map((adminType) => (
							<FormControlLabel
								key={adminType}
								control={
									<Checkbox
										disabled={!editing}
										checked={getCheckboxState(adminType)}
										onChange={_.partial(toggleCheckboxState, adminType)}
									></Checkbox>
								}
								label={checkboxLabels[adminType]}
							/>
						))}

						<List>
							<ListSubheader>City permissions</ListSubheader>
							{/* the non-null asserttion here is fine here because loading is false */}
							{(localPermissions || []).map((pm, i) => (
								<PermsEditorListItem
									key={i}
									perms={pm.input}
									projectName={pm.projectName}
									disabled={!editing}
									onChange={_.partial(handleProjectPermissionsChange, i)}
									onDelete={_.partial(handleProjectPermissionsDelete, i)}
								/>
							))}
							<AddProjPermsListItem
								onPermsAdded={handleProjectPermissionsAdded}
								existingProjects={
									localPermissions?.map((p) => p.input.projectId) || []
								}
								disabled={!editing}
							/>
						</List>
					</DialogContent>
					<DialogActions>
						<FormControlLabel
							control={<Switch checked={editing} onChange={toggleEditing} />}
							label="Editing"
						/>
						<Button
							disabled={!editing}
							variant="outlined"
							color="secondary"
							onClick={handleSaveClick}
						>
							Save changes
						</Button>
						<Button variant="contained" color="primary" onClick={closeDialog}>
							Cancel
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};

export default EditEmploymentDialog;
