import React, { useState } from 'react';
import { CellOrder, TableItem } from '../elements/SimpleTable';
import {
	useSetActiveRoom,
	useActiveFloor,
	useCreateRoom,
	useMe,
} from '../hooks';
import nameSort from '../utils/nameSort';
import EnhancedTable from './EnhancedTable';
import CreateNameForm, { FormValues } from './CreateNameForm';
import { v4 as uuid } from 'uuid';
import { Popover } from '@material-ui/core';
import { Query } from '../typings/graphql';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { isStructAdmin } from 'utils/helpers';
import DeleteLocations from './DeleteLocations';

const QUERY = gql`
	query rooms($input: QueryRoomsInput!) {
		rooms(input: $input) {
			id
			name
			floor {
				id
			}
		}
	}
`;

const cellOrder: CellOrder[] = [
	{
		align: 'left',
		name: 'Room',
		itemName: 'name',
	},
];

type Props = {
	onSelection: (event?: MouseEvent) => void;
};

const RoomsTable = ({ onSelection }: Props): JSX.Element => {
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [roomToDelete, setRoomToDelete] = useState<TableItem>();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const floor = useActiveFloor();
	const me = useMe();
	const structAdmin = me ? isStructAdmin(me) : false;
	const setActiveRoom = useSetActiveRoom();
	const createRoom = useCreateRoom();

	const { data, refetch } = useQuery<Pick<Query, 'rooms'>>(QUERY, {
		variables: { input: { floor: floor?.id } },
	});
	const sorted = data?.rooms.slice(0).sort(nameSort);
	const open = Boolean(anchorEl);
	const id = open ? 'create-company-popover' : undefined;

	const handleTriggerPopover = (
		event: React.MouseEvent<HTMLButtonElement>
	): void => setAnchorEl(event.currentTarget);

	const handlePopoverClose = (): void => setAnchorEl(null);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const variables = {
			room: {
				id: uuid(),
				name: values.name,
				floorId: floor.id,
			},
		};
		createRoom(variables);
	};

	const handleListItemClick = (item: TableItem): void => {
		setActiveRoom(item);
		onSelection();
	};

	const handleDeleteLocation = (item: TableItem) => {
		setRoomToDelete(item);
		setOpenDeleteDialog(true);
	};

	return (
		<>
			<EnhancedTable
				empty="No rooms exist for this floor yet"
				reload={refetch}
				items={sorted ? (sorted as TableItem[]) : []}
				searchPlaceholder="Search for a room name"
				order={cellOrder}
				onClick={handleTriggerPopover}
				onDeleteClick={handleDeleteLocation}
				onListItemClick={handleListItemClick}
				canCreate={structAdmin}
				canDelete={me?.admin}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<CreateNameForm
					label="Room Name"
					onSubmit={handleSubmit}
					onClose={handlePopoverClose}
				/>
			</Popover>
			<DeleteLocations
				setOpenDeleteDialog={setOpenDeleteDialog}
				openDeleteDialog={openDeleteDialog}
				roomToDelete={roomToDelete}
			/>
		</>
	);
};

export default RoomsTable;
