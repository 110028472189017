import React, { useEffect, useState } from 'react';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	createStyles,
	makeStyles,
} from '@material-ui/core';
import { Building, Floor, Query, Room } from '../typings/graphql';
import { gql, useQuery } from '@apollo/client';
import { useActiveProject } from 'hooks';
import _ from 'lodash';

const BUILDINGS_QUERY = gql`
	query buildings($input: QueryBuildingsInput!) {
		buildings(input: $input) {
			id
			name
			project {
				id
			}
		}
	}
`;

const FLOORS_QUERY = gql`
	query floors($input: QueryFloorsInput!) {
		floors(input: $input) {
			id
			name
			building {
				id
			}
		}
	}
`;

const ROOMS_QUERY = gql`
	query rooms($input: QueryRoomsInput!) {
		rooms(input: $input) {
			id
			name
			floor {
				id
			}
		}
	}
`;

type Props = {
	onChange: (location: Location) => void;
	disabled?: boolean;
	initialLocation: Location;
	reset?: boolean;
};

export type Location = {
	building?: Building;
	floor?: Floor;
	room?: Room;
};

const useStyles = makeStyles(() =>
	createStyles({
		inputLabel: {
			fontSize: 'normal',
			color: '#00294D',
		},
		select: {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'rgba(0, 41, 77, 0.5)',
			},
		},
	})
);

const LocationSelector = ({
	onChange,
	disabled = false,
	initialLocation,
	reset,
}: Props): JSX.Element => {
	const project = useActiveProject();
	const classes = useStyles();
	const [location, setLocation] = useState<Location>(initialLocation);

	useEffect(() => {
		if (reset) setLocation(initialLocation);
	}, [initialLocation, reset]);

	const buildingData = useQuery<Pick<Query, 'buildings'>>(BUILDINGS_QUERY, {
		variables: { input: { project: project?.id } },
	}).data?.buildings;

	const floorData = useQuery<Pick<Query, 'floors'>>(FLOORS_QUERY, {
		variables: {
			input: { building: location?.building?.id },
		},
		skip: !location.building,
	}).data?.floors;

	const roomData = useQuery<Pick<Query, 'rooms'>>(ROOMS_QUERY, {
		variables: { input: { floor: location?.floor?.id } },
		skip: !location.floor,
	}).data?.rooms;

	const handleBuildingChange = (
		event: React.ChangeEvent<{ value: unknown }>
	): void => {
		const currentLocation = _.cloneDeep(location);
		currentLocation.building = buildingData?.find(
			(building) => building.id === event.target.value
		);
		currentLocation.floor = undefined;
		currentLocation.room = undefined;
		setLocation(currentLocation);
		onChange(currentLocation);
	};

	const handleFloorChange = (
		event: React.ChangeEvent<{ value: unknown }>
	): void => {
		const currentLocation = _.cloneDeep(location);
		currentLocation.floor = floorData?.find(
			(floor) => floor.id === event.target.value
		);
		currentLocation.room = undefined;
		setLocation(currentLocation);
		onChange(currentLocation);
	};

	const handleRoomChange = (
		event: React.ChangeEvent<{ value: unknown }>
	): void => {
		const currentLocation = _.cloneDeep(location);
		currentLocation.room = roomData?.find(
			(room) => room.id === event.target.value
		);
		setLocation(currentLocation);
		onChange(currentLocation);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={3}>
				<FormControl
					size="small"
					variant="outlined"
					color="primary"
					fullWidth
					disabled={disabled}
					classes={{ root: classes.select }}
				>
					<InputLabel className={classes.inputLabel}>Building</InputLabel>
					<Select
						value={location.building ? location.building.id : ''}
						onChange={handleBuildingChange}
						label="Building"
					>
						{buildingData
							? buildingData.map((building) => (
									<MenuItem key={building.id} value={building.id}>
										{building.name}
									</MenuItem>
							  ))
							: []}
						<MenuItem value={''}>None</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={3}>
				<FormControl
					size="small"
					fullWidth
					variant="outlined"
					disabled={disabled || floorData === undefined}
					classes={{ root: classes.select }}
				>
					<InputLabel className={classes.inputLabel}>Floor</InputLabel>
					<Select
						value={location.floor ? location.floor.id : ''}
						onChange={handleFloorChange}
						label="Room"
					>
						{floorData
							? floorData.map((floor) => (
									<MenuItem key={floor.id} value={floor.id}>
										{floor.name}
									</MenuItem>
							  ))
							: []}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={3}>
				<FormControl
					fullWidth
					size="small"
					variant="outlined"
					disabled={disabled || roomData === undefined}
				>
					<InputLabel className={classes.inputLabel}>Room</InputLabel>
					<Select
						value={location.room ? location.room.id : ''}
						onChange={handleRoomChange}
						label="Room"
						classes={{ root: classes.select }}
					>
						{roomData
							? roomData.map((room) => (
									<MenuItem key={room.id} value={room.id}>
										{room.name}
									</MenuItem>
							  ))
							: []}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	);
};

export default LocationSelector;
