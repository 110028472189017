import React, { useState } from 'react';
import {
	Button,
	Box,
	TableBody,
	TablePagination,
	TableCell,
	TableRow,
	Table,
	Card,
	TableHead,
} from '@material-ui/core';
import { useActiveProject } from 'hooks';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { Query } from '../typings/graphql';
import { gql } from '@apollo/client/core';

export const ROOMS_QUERY = gql`
	query rooms($input: QueryRoomsInput!) {
		paginatedRooms(input: $input) {
			numberOfRooms
			rooms {
				id
				name
			}
		}
	}
`;

const RoomList = () => {
	const project = useActiveProject();
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [showRoomsList, setShowRoomsList] = useState(false);
	const { data: paginatedRooms } = useQuery<Pick<Query, 'paginatedRooms'>>(
		ROOMS_QUERY,
		{
			variables: {
				input: {
					project: project?.id,
					limit: rowsPerPage,
					offset: page > 0 ? rowsPerPage * page : 0,
				},
			},
			returnPartialData: true,
			fetchPolicy: 'cache-and-network',
		}
	);

	// the "event" parameter is required, but not used
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<Box mb={10}>
			<Button
				onClick={() => setShowRoomsList(!showRoomsList)}
				type="submit"
				color="primary"
				variant="contained"
			>
				{showRoomsList ? 'Hide table' : 'Show rooms'}
			</Button>

			<Card style={{ backgroundColor: 'white', marginTop: '1em' }}>
				{showRoomsList && (
					<>
						{project && (
							<Box ml={2}>
								<h3>Selected city: {project.name}</h3>
							</Box>
						)}
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>ID</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{paginatedRooms?.paginatedRooms.rooms &&
									paginatedRooms.paginatedRooms.rooms.map((room) => (
										<TableRow key={room.id}>
											<TableCell width={'180px'}>{room.name}</TableCell>
											<TableCell>{room.id}</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>

						{paginatedRooms?.paginatedRooms.numberOfRooms && (
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={paginatedRooms.paginatedRooms.numberOfRooms}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						)}
					</>
				)}
			</Card>
		</Box>
	);
};

export default RoomList;
