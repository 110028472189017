import {
	ButtonBase,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { useActiveProject } from 'hooks';
import { memoize } from 'lodash';
import React, { Key, memo } from 'react';
import Image from 'react-image';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, FixedSizeGrid, GridChildComponentProps } from 'react-window';
import {
	FilterCategorySelect,
	FilterColorSelect,
	FilteredItems,
	FilterMaterialSelect,
	FilterSubcategorySelect,
	ItemFilterProvider,
} from './ItemFilterContext';
import { Item } from '../typings/graphql';
import { gql } from '@apollo/client/core';

export const ITEMS_QUERY = gql`
	query getItemsByProject($input: QueryItemsInput!) {
		items(input: $input) {
			id
			image {
				id
				url
			}
			color {
				id
			}
			material {
				id
			}
			subcategory {
				id
				category {
					id
				}
			}
		}
	}
`;

type Props = {
	onClick: (item: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formHeader: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
		containerItems: {
			marginTop: theme.spacing(1),
			height: '100%',
			maxHeight: 'calc(100% - 168px)',
			overflow: 'overlay',
			overflowX: 'hidden',
		},
		imageButton: {
			width: '100%',
			height: '100%',
			backgroundColor: theme.palette.grey[400],
		},
		imageSrc: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
	})
);

const Inner = (props: Props): JSX.Element => {
	const classes = useStyles();
	const project = useActiveProject();

	const { onClick } = props;

	return (
		<>
			<Typography component="h3" className={classes.formHeader}>
				Filter Items By:
			</Typography>

			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={6}>
					<FilterCategorySelect />
				</Grid>
				<Grid item xs={6}>
					<FilterSubcategorySelect />
				</Grid>
				<Grid item xs={6}>
					<FilterMaterialSelect />
				</Grid>
				<Grid item xs={6}>
					<FilterColorSelect />
				</Grid>
			</Grid>

			<FilteredItems projectId={project.id} itemQuery={ITEMS_QUERY}>
				{({ items }): JSX.Element => (
					<div className={classes.containerItems}>
						<AutoSizer>
							{({ height, width }): JSX.Element => (
								<GridList
									height={height}
									width={width}
									items={items}
									onClick={onClick}
								/>
							)}
						</AutoSizer>
					</div>
				)}
			</FilteredItems>
		</>
	);
};

const createItemData = memoize((items, setActiveItem) => ({
	items,
	setActiveItem,
}));

// eslint-disable-next-line react/display-name
const ImageCell = memo(
	({
		style,
		data,
		rowIndex,
		columnIndex,
	}: GridChildComponentProps): JSX.Element => {
		const classes = useStyles();
		const index = rowIndex * 2 + columnIndex;
		const { items, setActiveItem } = data as any;
		const item = items[index];

		return (
			<div style={style}>
				{item && (
					<ButtonBase
						focusRipple
						className={classes.imageButton}
						onClick={(): void => setActiveItem(item.id)}
					>
						<Image src={item.image.url} className={classes.imageSrc} />
					</ButtonBase>
				)}
			</div>
		);
	},
	areEqual
);

const itemCellKey = ({
	data,
	rowIndex,
	columnIndex,
}: {
	data: any;
	rowIndex: number;
	columnIndex: number;
}): Key => {
	const index = rowIndex * 2 + columnIndex;
	return data[index] ? data[index].id : `unknown-${index}`;
};

const GridList = ({
	height,
	width,
	items,
	onClick,
}: {
	height: number;
	width: number;
	items: Item[];
	onClick: (item: string) => void;
}): JSX.Element => {
	const itemData = createItemData(items, onClick);

	return (
		<FixedSizeGrid
			height={height}
			width={width}
			columnCount={2}
			rowCount={Math.ceil(items.length / 2)}
			itemData={itemData}
			columnWidth={width / 2}
			rowHeight={200}
			itemKey={itemCellKey}
			style={{ overflowX: 'hidden' }}
		>
			{ImageCell}
		</FixedSizeGrid>
	);
};

const ImportItemGrid = (props: Props): JSX.Element => (
	<ItemFilterProvider>
		<Inner {...props} />
	</ItemFilterProvider>
);

export default ImportItemGrid;
