import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';

type AppBar = {
	appBar: {
		title: string;
	};
};

const QUERY = gql`
	query appBar {
		appBar @client {
			title
		}
	}
`;

export default () => {
	const { data } = useQuery<AppBar>(QUERY);

	return data?.appBar ?? null;
};
