import { gql } from '@apollo/client/core';

export default gql`
	type ActiveRoom {
		rid: ID!
		name: String!
	}

	input SetActiveRoomInput {
		rid: ID!
		name: String!
	}

	extend type Query {
		activeRoom: ActiveRoom
	}

	extend type Mutation {
		setActiveRoom(input: SetActiveRoomInput!): ActiveRoom
	}
`;
