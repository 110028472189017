import { Button } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CreateUserDialog from 'components/CreateUserDialog';
import { UserTable } from 'components/UserTable';
import React, { useState } from 'react';
import Content from '../components/Content';
import { useActiveCompany, useUpdateAppBar } from '../hooks';

const ManagePermissionsView = (): JSX.Element => {
	useUpdateAppBar('Manage Permissions');
	const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
	const activeCompany = useActiveCompany();
	const activeCompanyExists = !!activeCompany.id;

	const handleCreateUserDialogOpen = () => setCreateUserDialogOpen(true);
	const handleCreateUserDialogClose = () => setCreateUserDialogOpen(false);

	return (
		<Content>
			{activeCompanyExists ? (
				<>
					<UserTable companyId={activeCompany.id} />
					<CreateUserDialog
						open={createUserDialogOpen}
						handleClose={handleCreateUserDialogClose}
					></CreateUserDialog>
					<Button
						startIcon={<PersonAddIcon />}
						onClick={handleCreateUserDialogOpen}
					>
						Create new user
					</Button>
				</>
			) : (
				<div>No active company :(</div>
			)}
		</Content>
	);
};

export default ManagePermissionsView;
