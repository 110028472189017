import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useContext } from 'react';
import { CHANGE_SUBCATEGORY_ACTION, ItemFilterContext } from '.';
import { Query } from '../../typings/graphql';

const SUBCATEGORIES_QUERY = gql`
	query categories {
		itemCategories {
			id
			name
			subcategories {
				id
				name
			}
		}
	}
`;

const FilterSubcategorySelect = (): JSX.Element | null => {
	const { state, dispatch } = useContext(ItemFilterContext);

	const { data } = useQuery<Pick<Query, 'itemCategories'>>(SUBCATEGORIES_QUERY);

	if (!data?.itemCategories) return null;

	const subcategories =
		data.itemCategories.find((cat) => cat.id === state.category)
			?.subcategories || [];

	const sorted = subcategories
		?.slice(0)
		.sort((a, b) => a.name!.localeCompare(b.name!));

	return (
		<FormControl variant="outlined" fullWidth>
			<InputLabel id="filter-by-subcategory">Subcategory</InputLabel>
			<Select
				labelId="filter-by-subcategory"
				id="filter-by-subcategory-select"
				value={state.subcategory}
				onChange={(event): void =>
					dispatch({
						type: CHANGE_SUBCATEGORY_ACTION,
						payload: event.target.value,
					})
				}
				labelWidth={127}
				name="subcategory"
				fullWidth
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{sorted.map((el) => (
					<MenuItem key={el.id} value={el.id}>
						{el.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default FilterSubcategorySelect;
