import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';

type ActiveFloor = {
	activeFloor: {
		id: string;
		name: string;
	};
};

const QUERY = gql`
	query activeFloor {
		activeFloor @client {
			id: rid
			name
		}
	}
`;

export default (): IdName => {
	const { data } = useQuery<ActiveFloor>(QUERY);

	if (!data) return { id: '', name: '' };

	return data.activeFloor;
};
