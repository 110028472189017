import PrivateRoute from 'components/PrivateRoute';
import WaitingComponent from 'components/WaitingComponent';
import { AnimatePresence, motion } from 'framer-motion';
import { useActiveProject, useActiveRoom } from 'hooks';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import ItemsView from 'views/Items';
import MaterialsColorsView from 'views/materialsColors';
import Navigation from './components/Navigation';
import TopBar from './components/TopBar';
import ItemCategoriesView from './views/ItemCategories';
import Overview from './views/Overview';
import RoomView from './views/Room';
import ExportsView from './views/Exports';
import ImportsView from './views/Imports';
import ManagePermissionsView from 'views/PermissionsManager';
import InventoryView from 'views/Inventory/InventoryView';

const AuthedRouter = (): JSX.Element => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const room = useActiveRoom();
	const project = useActiveProject();

	const handleDrawerToggle = (): void => {
		setDrawerOpen(!drawerOpen);
	};

	return (
		<>
			<TopBar openDrawer={handleDrawerToggle} />
			<Navigation open={drawerOpen} closeDrawer={handleDrawerToggle} />
			<AnimatePresence exitBeforeEnter initial={false}>
				<Switch>
					<Route exact path="/itemCategories">
						<WaitingComponent>
							<ItemCategoriesView />
						</WaitingComponent>
					</Route>

					<Route exact path="/materialsColors">
						<WaitingComponent>
							<MaterialsColorsView />
						</WaitingComponent>
					</Route>

					<Route exact path="/exports">
						<WaitingComponent>
							<ExportsView />
						</WaitingComponent>
					</Route>

					<Route exact path="/imports">
						<WaitingComponent>
							<ImportsView />
						</WaitingComponent>
					</Route>

					<Route exact path="/inventory/:articleId?">
						<WaitingComponent>
							<InventoryView />
						</WaitingComponent>
					</Route>

					<Route exact path="/managePermissions">
						<WaitingComponent>
							<ManagePermissionsView />
						</WaitingComponent>
					</Route>

					<PrivateRoute exact path="/room" allow={!!room.id}>
						<WaitingComponent>
							<RoomView />
						</WaitingComponent>
					</PrivateRoute>

					<PrivateRoute exact path="/items" allow={!!project.id}>
						<WaitingComponent>
							<ItemsView />
						</WaitingComponent>
					</PrivateRoute>

					<Route path="/">
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
						>
							<Overview />
						</motion.div>
					</Route>
				</Switch>
			</AnimatePresence>
		</>
	);
};

export default AuthedRouter;
