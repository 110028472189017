import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	syncTrackedMutations,
	getTrackedMutations,
} from '../store/trackedMutations';
import { updateHandlerByName } from '../graphql';
import { FetchResult } from '@apollo/client/link/core/types';
import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';

type TProps = {
	children: React.ReactNode;
};

const SyncMutations = ({ children }: TProps): JSX.Element => {
	const apolloClient = useApolloClient();
	const dispatch = useDispatch();
	const trackedMutations = useSelector(getTrackedMutations);
	const [hasSynced, setHasSynced] = useState(false);

	useEffect(() => {
		const execute = async (): Promise<void> => {
			dispatch(syncTrackedMutations(true));
			const promises: Array<Promise<FetchResult<any>>> = [];
			trackedMutations.forEach((trackedMutation) => {
				const context = JSON.parse(trackedMutation.contextJSON);
				const query = JSON.parse(trackedMutation.queryJSON);
				const variables = JSON.parse(trackedMutation.variablesJSON);

				promises.push(
					apolloClient.mutate({
						context,
						mutation: query,
						variables,
						optimisticResponse: context.optimisticResponse,
						update: updateHandlerByName[trackedMutation.name],
					})
				);
			});

			try {
				console.log(`SYNCING ${trackedMutations.length} MUTATIONS...`);
				// eslint-disable-next-line no-undef
				await Promise.all(promises);
				dispatch(syncTrackedMutations(false));
			} catch (error) {
				// ALLOW TRACKED MUTATIONS TO FAIL
			}
		};
		if (!hasSynced && trackedMutations.length > 0) execute();
		setHasSynced(true);
	}, [hasSynced, apolloClient, dispatch, trackedMutations]);

	return <>{children}</>;
};

export default SyncMutations;
