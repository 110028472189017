import { Dialog } from '@material-ui/core';
import axios from 'axios';
import useCreateImage from 'hooks/useCreateImage';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { updateHandlerByName } from '../graphql';
import { useActiveProject, useUpdateItem } from '../hooks';
import CreateItemForm, { FormValues } from './CreateItemForm';
import { Mutation, Query } from '../typings/graphql';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';

type Props = {
	itemId: string;
	open: boolean;
	onClose: () => void;
};

const EDIT_ITEM_QUERY = gql`
	query item($id: ID!) {
		item(id: $id) {
			id
			name
			width
			height
			depth
			secondaryDepth
			diameter
			color {
				id
				name
			}
			material {
				id
				name
			}
			subcategory {
				id
				name
				category {
					id
					name
				}
			}
			project {
				id
			}
			image {
				id
				url
			}
		}
		itemCategories {
			id
			name
			subcategories {
				id
				name
			}
		}
		materials {
			id
			name
		}
		colors {
			id
			name
		}
	}
`;

const UpdateItemDialog = (props: Props): JSX.Element | null => {
	const { open, onClose, itemId } = props;
	const [imageFile, setImageFile] = useState('');
	const project = useActiveProject();
	const updateItem = useUpdateItem((cache, mutationResult) =>
		updateHandlerByName.updateItem(cache, mutationResult, project.id)
	);

	const handleImageUpload = async (
		data: Pick<Mutation, 'createImage'>
	): Promise<void> => {
		// UPLOAD IMAGE HERE
		// TODO: Fix this
		// This needs to be done via the goddamn redux system.
		// Or we just upload the image to the server and handle the upload
		// to S3 from there
		try {
			await axios.put(data.createImage!.signedUploadUrl, imageFile);
		} catch (err) {
			console.log('Image upload failed');
			console.log(err);
		}
	};

	const createImage = useCreateImage(handleImageUpload);

	const shouldFetch = Boolean(itemId);
	const { data } = useQuery<
		Pick<Query, 'item' | 'itemCategories' | 'materials' | 'colors'>
	>(EDIT_ITEM_QUERY, {
		variables: { id: itemId },
		skip: !shouldFetch,
	});

	if (!data?.item) return null;

	const { item } = data;
	const itemCategories = data?.itemCategories || [];
	const materials = data?.materials || [];
	const colors = data?.colors || [];

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const isNewImage = values.image.imagePreviewUrl !== item.image.url;
		const imageVariables = {
			id: isNewImage ? uuid() : item.image.id,
			projectId: project.id,
		};
		if (isNewImage) {
			setImageFile(values.image.file);
			await createImage(
				{
					image: imageVariables,
				},
				values.image.imagePreviewUrl
			);
		}

		// We find these and provide them to the createItem mutation so that the optimistic values are correct.
		const category = itemCategories.find((category) =>
			category.subcategories.find(
				(subcategory) => subcategory.id === values.subcategory
			)
		);
		if (!category) {
			throw new Error('ItemCategory does not exist.');
		}
		const subcategory = category?.subcategories.find(
			(subcategory) => subcategory.id === values.subcategory
		);
		if (!subcategory) {
			throw new Error('ItemSubcategory does not exist.');
		}

		const color = colors.find((color) => color.id === values.color);
		const material = materials.find(
			(material) => material.id === values.material
		);
		const itemVariables = {
			id: itemId,
			name: values.name,
			projectId: project.id,
			itemSubcategoryId: values.subcategory,
			imageId: imageVariables.id,
			...(values.width ? { width: parseFloat(values.width) } : {}),
			...(values.depth ? { depth: parseFloat(values.depth) } : {}),
			...(values.height ? { height: parseFloat(values.height) } : {}),
			...(values.secondaryDepth
				? { secondaryDepth: parseFloat(values.secondaryDepth) }
				: {}),
			...(values.diameter ? { diameter: parseFloat(values.diameter) } : {}),
			...(values.material ? { materialId: values.material } : {}),
			...(values.color ? { colorId: values.color } : {}),
		};

		await updateItem({ item: itemVariables }, values.image.imagePreviewUrl, {
			project: project.id,
			subcategory: subcategory.id,
			color: color!.id,
			material: material!.id,
			image: imageVariables.id,
		});

		onClose();
	};

	return (
		<Dialog
			open={open}
			maxWidth="lg"
			onClose={onClose}
			aria-labelledby="form-edit-item"
		>
			<CreateItemForm
				itemName={item.name}
				title="Edit Item"
				disableComment
				initialName={item.name}
				initialWidth={item.width?.toString()}
				initialHeight={item.height?.toString()}
				initialDepth={item.depth?.toString()}
				initialDiameter={item.diameter?.toString()}
				initialSecondaryDepth={item.secondaryDepth?.toString()}
				initialCategory={item.subcategory.category.id}
				initialSubcategory={item.subcategory.id}
				initialColor={item.color?.id}
				initialMaterial={item.material?.id}
				initialImage={item.image.url}
				itemCategories={itemCategories}
				materials={materials}
				colors={colors}
				onClose={onClose}
				onSubmit={handleSubmit}
			/>
		</Dialog>
	);
};

export default UpdateItemDialog;
