import {
	handleCreateArticleUpdate,
	handleDeleteArticle,
	handleDeleteArticlesInRoom,
	handleUpdateArticleInRoom,
} from './articles';
import {
	handleCreateBuildingUpdate,
	handleDeleteBuildingUpdate,
} from './buildings';
import { handleCreateColorUpdate, handleDeleteColorUpdate } from './colors';
import {
	handleCreateCompanyUpdate,
	handleDeleteCompanyUpdate,
} from './companies';
import { handleCreateFloorUpdate, handleDeleteFloorUpdate } from './floors';
import {
	handleCreateItemCategoryUpdate,
	handleDeleteItemCategoryUpdate,
} from './itemCategories';
import { handleCreateItemUpdate, handleUpdateItemUpdate } from './items';
import {
	handleCreateItemSubcategoryUpdate,
	handleDeleteItemSubcategoryUpdate,
} from './itemSubcategories';
import {
	handleCreateMaterialUpdate,
	handleDeleteMaterialUpdate,
} from './materials';
import {
	handleDeleteFileExport,
	handleGenerateFileExportUpdate,
} from './fileExport';
import {
	handleCreateProjectUpdate,
	handleDeleteProjectUpdate,
} from './projects';
import { handleCreateRoomUpdate, handleDeleteRoomUpdate } from './rooms';
import {
	handleSetArticleCollectionCount,
	handleAddCommentToArticleInCollection,
	handleRemoveCommentFromArticleInCollection,
} from './articleCollection';
import {
	handleDeleteProjectPermissionsUpdate,
	handlePutProjectPermissionsUpdate,
	handleUpdateEmploymentUpdate,
	handleCreateUserUpdate,
	handleDeleteUserUpdate,
} from './users';

export const updateHandlerByName = {
	createCompany: handleCreateCompanyUpdate,
	deleteCompany: handleDeleteCompanyUpdate,
	createProject: handleCreateProjectUpdate,
	deleteProject: handleDeleteProjectUpdate,
	createBuilding: handleCreateBuildingUpdate,
	deleteBuilding: handleDeleteBuildingUpdate,
	createFloor: handleCreateFloorUpdate,
	deleteFloor: handleDeleteFloorUpdate,
	createRoom: handleCreateRoomUpdate,
	deleteRoom: handleDeleteRoomUpdate,
	createArticle: handleCreateArticleUpdate,
	createItem: handleCreateItemUpdate,
	createUser: handleCreateUserUpdate,
	updateItem: handleUpdateItemUpdate,
	updateArticleInRoom: handleUpdateArticleInRoom,
	deleteArticle: handleDeleteArticle,
	deleteArticlesInRoom: handleDeleteArticlesInRoom,
	createItemCategory: handleCreateItemCategoryUpdate,
	deleteItemCategory: handleDeleteItemCategoryUpdate,
	createItemSubcategory: handleCreateItemSubcategoryUpdate,
	deleteItemSubcategory: handleDeleteItemSubcategoryUpdate,
	createMaterial: handleCreateMaterialUpdate,
	deleteMaterial: handleDeleteMaterialUpdate,
	createColor: handleCreateColorUpdate,
	deleteColor: handleDeleteColorUpdate,
	generateFileExport: handleGenerateFileExportUpdate,
	deleteFileExport: handleDeleteFileExport,
	setArticleCollectionCount: handleSetArticleCollectionCount,
	addCommentToArticleInCollection: handleAddCommentToArticleInCollection,
	removeCommentFromArticleInCollection:
		handleRemoveCommentFromArticleInCollection,
	putProjectPermissions: handlePutProjectPermissionsUpdate,
	deleteProjectPermissions: handleDeleteProjectPermissionsUpdate,
	updateEmployment: handleUpdateEmploymentUpdate,
	deleteUser: handleDeleteUserUpdate,
};
