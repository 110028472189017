import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import loggerLink from 'apollo-link-logger';
import QueueLink from 'apollo-link-queue';
import SerializingLink from 'apollo-link-serialize';
import trackerLink from '../utils/trackerLink';
import { store } from '../store';
import { resolvers, typeDefs } from './local/schema';
import authLink from 'utils/authLink';
import { SET_ACTIVE_PROJECT_QUERY } from './local/mutations/activeProject/setActiveProject';
import { SET_ACTIVE_BUILDING_QUERY } from './local/mutations/activeBuilding/setActiveBuilding';
import { SET_ACTIVE_FLOOR_QUERY } from './local/mutations/activeFloor/setActiveFloor';
import { SET_ACTIVE_ROOM_QUERY } from './local/mutations/activeRoom/setActiveRoom';
import { SET_ACTIVE_COMPANY_QUERY } from './local/mutations/activeCompany/setActiveCompany';
import { SET_APP_BAR_TITLE_QUERY } from './local/mutations/appBar/setAppBarTitle';

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors, networkError }) => {
	console.log('Caught Apollo Client Error');
	if (graphQLErrors) {
		console.log('GRAPHQL_ERRORS:', graphQLErrors);
	}

	if (networkError) {
		console.log('NETWORK_ERROR:', networkError);
	}
});
export const queueLink = new QueueLink();
const serializingLink = new SerializingLink();
const httpLink = new HttpLink({
	uri: process.env.REACT_APP_SERVER_URI,
});

export default new ApolloClient({
	cache,
	link: ApolloLink.from([
		loggerLink,
		errorLink,
		trackerLink(store.dispatch),
		queueLink,
		serializingLink,
		authLink,
		httpLink,
	]),
	typeDefs,
	resolvers,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
	},
});

export const initLocalState = async (): Promise<void> => {
	cache.writeQuery({
		query: SET_APP_BAR_TITLE_QUERY,
		data: {
			appBar: {
				__typename: 'AppBar',
				title: '',
			},
		},
	});
	cache.writeQuery({
		query: SET_ACTIVE_COMPANY_QUERY,
		data: {
			activeCompany: {
				__typename: 'ActiveCompany',
				rid: '',
				name: '',
			},
		},
	});
	cache.writeQuery({
		query: SET_ACTIVE_PROJECT_QUERY,
		data: {
			activeProject: {
				__typename: 'ActiveProject',
				rid: '',
				name: '',
			},
		},
	});
	cache.writeQuery({
		query: SET_ACTIVE_BUILDING_QUERY,
		data: {
			activeBuilding: {
				__typename: 'ActiveBuilding',
				rid: '',
				name: '',
			},
		},
	});
	cache.writeQuery({
		query: SET_ACTIVE_FLOOR_QUERY,
		data: {
			activeFloor: {
				__typename: 'ActiveFloor',
				rid: '',
				name: '',
			},
		},
	});
	cache.writeQuery({
		query: SET_ACTIVE_ROOM_QUERY,
		data: {
			activeRoom: {
				__typename: 'ActiveRoom',
				rid: '',
				name: '',
			},
		},
	});
};
