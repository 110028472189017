import React, { ChangeEvent, useState } from 'react';
import { Button, Grid, Box, CircularProgress, Card } from '@material-ui/core';
import { useCreateArticle, useActiveProject } from 'hooks';
import { MutationCreateArticleArgs, Query } from '../typings/graphql';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { gql } from '@apollo/client/core';
import validateCSV from 'utils/importValidation';
import { parse, ParseResult } from 'papaparse';

interface CsvData {
	Comment: string | null;
	ItemID: string;
	RoomID: string;
}

export const ITEMS_QUERY = gql`
	query getItemsByProject($input: QueryItemsInput!) {
		items(input: $input) {
			id
			project {
				id
			}
		}
	}
`;

const UploadArticlesCard = () => {
	const createArticle = useCreateArticle();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [uploadedCSV, setUploadedCSV] = useState<CsvData[]>();
	const [fileName, setFileName] = useState<string>();
	const snackbar = useSnackbar();
	const project = useActiveProject();
	const { data } = useQuery<Pick<Query, 'items'>>(ITEMS_QUERY, {
		variables: { input: { project: project.id } },
		returnPartialData: true,
		fetchPolicy: 'cache-and-network',
	});

	const changeHandler = function (event: ChangeEvent<HTMLInputElement>) {
		const { files } = event.target;

		if (files) {
			setFileName(files[0].name);
			const input = files[0];

			if (input.type !== 'text/csv') {
				snackbar.enqueueSnackbar('File must be of type .CSV', {
					variant: 'error',
				});
			} else {
				parse(input, {
					header: true,
					complete: function (results: ParseResult<CsvData>) {
						const reformattedResults = results.data.map((obj) => {
							if (obj.Comment === null) {
								obj.Comment = '';
							}
							return obj;
						});

						setUploadedCSV(reformattedResults);
					},
				});
			}
		}
	};

	const handleSubmit = () => {
		if (uploadedCSV) {
			setIsLoading(true);

			const itemsFromCsvFile = uploadedCSV.map((item) => item.ItemID);
			const itemsInDatabase: string[] | undefined = data?.items.map(
				(item) => item.id
			);

			const validated = validateCSV(
				uploadedCSV,
				itemsInDatabase,
				itemsFromCsvFile
			);

			// validateCSV returns a string with error message if any error
			if (typeof validated === 'string') {
				snackbar.enqueueSnackbar(validated, {
					variant: 'error',
				});

				setIsLoading(false);
			} else {
				const articleVariables: MutationCreateArticleArgs[] = uploadedCSV.map(
					(obj) => ({
						article: {
							id: uuid(),
							comment: obj.Comment || '',
							roomId: obj.RoomID,
							itemId: obj.ItemID,
						},
					})
				);

				articleVariables.forEach((obj) => {
					createArticle(obj);
				});

				snackbar.enqueueSnackbar('Articles uploaded to your project!', {
					variant: 'success',
				});

				setIsLoading(false);
			}
		}
	};

	return (
		<Box mt={2} mb={3}>
			<Card style={{ padding: '1em' }}>
				<h1>Upload articles</h1>
				<strong>
					You can import articles by downloading a template from the bottom
					right corner.
				</strong>
				<p>
					Each article is defined on its own row in the file. Item ID and room
					ID is required on each row. The item ID is found in the items menu,
					and available rooms in your chosen project is presented below.
				</p>

				<Box mt={4}>
					<Grid container spacing={1} alignItems="center">
						<Grid item>
							<Button component="label" color="secondary" variant="outlined">
								Upload File
								<input type="file" hidden onChange={changeHandler} />
							</Button>
						</Grid>

						<Grid item>{fileName ? fileName : null}</Grid>
					</Grid>
				</Box>

				<Box mt={1} mb={1}>
					<Grid container spacing={1} alignItems="center">
						<Grid item>
							<Button
								onClick={handleSubmit}
								type="submit"
								color="secondary"
								variant="contained"
								disabled={isLoading ? true : false}
								style={uploadedCSV ? { display: 'block' } : { display: 'none' }}
							>
								Submit
							</Button>
						</Grid>

						{isLoading && (
							<Grid item>
								<CircularProgress
									variant="indeterminate"
									style={{ width: '20px', height: '20px' }}
								/>
							</Grid>
						)}
					</Grid>
				</Box>
			</Card>
		</Box>
	);
};

export default UploadArticlesCard;
