import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { Query, Maybe, User } from '../typings/graphql';

const QUERY = gql`
	query me {
		me {
			id
			name
			admin
			employments {
				id
				authAdmin
				structAdmin
				company {
					id
					name
				}
				createdAt
				projectPermissionsList {
					id
					project {
						id
					}
					expiresAt
					canCreate
					canRead
					canUpdate
					canDelete
				}
			}
		}
	}
`;

export default (): Maybe<User> => {
	const { data } = useQuery<Pick<Query, 'me'>>(QUERY);
	return data ? data.me : undefined;
};
