import useViewportSize from './useViewportSize';

type SizeIndicator = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export default (): SizeIndicator => {
	const [width] = useViewportSize();
	let sizeIndicator: SizeIndicator = 'xl';

	if (width < 1920) sizeIndicator = 'lg';
	if (width < 1280) sizeIndicator = 'md';
	if (width < 960) sizeIndicator = 'sm';
	if (width < 600) sizeIndicator = 'xs';
	return sizeIndicator;
};
