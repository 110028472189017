import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useContext } from 'react';
import { Query } from '../../typings/graphql';
import { CHANGE_COLOR_ACTION, ItemFilterContext } from '.';
import { gql } from '@apollo/client/core';

const COLORS_QUERY = gql`
	query colors {
		colors {
			id
			name
		}
	}
`;

const FilterColorSelect = (): JSX.Element | null => {
	const { state, dispatch } = useContext(ItemFilterContext);

	const { data } = useQuery<Pick<Query, 'colors'>>(COLORS_QUERY);

	if (!data?.colors) return null;

	const sorted = data.colors
		.slice(0)
		.sort((a, b) => a.name.localeCompare(b.name));

	return (
		<FormControl variant="outlined" fullWidth>
			<InputLabel id="filter-by-color">Color</InputLabel>
			<Select
				labelId="filter-by-color"
				id="filter-by-color-select"
				value={state.color}
				onChange={(event): void =>
					dispatch({
						type: CHANGE_COLOR_ACTION,
						payload: event.target.value,
					})
				}
				labelWidth={127}
				name="color"
				fullWidth
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{sorted.map((el) => (
					<MenuItem key={el.id} value={el.id}>
						{el.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default FilterColorSelect;
