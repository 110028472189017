import { useState, useEffect } from 'react';
import {
	LocalStorageManager,
	theLocalStorageManager,
} from 'utils/storageManager';

export default (): boolean => {
	const [token, setToken] = useState(theLocalStorageManager.getItem('token'));

	// Set up a subscription to theLocalStorageManager which updates the token state variable.
	// The effect function should return a function which cancels the subscription. Such a function
	// is returned by theLocalStorageManager, and is provided as the cleanup function to useEffect.
	// This effect should only be run once, so we use an empty list of dependencies.
	useEffect(() => {
		// will be called when updates are made to theLocalStorageManager
		const callback = (sm: LocalStorageManager) => {
			setToken(sm.getItem('token'));
		};
		// subscribe to theLocalStorageManager, and obtain a function cancelling the subscription
		const unsubscribe = theLocalStorageManager.subscribe(callback);
		// the cleanup function is the unsubscribe function
		return unsubscribe;
	}, []);

	return Boolean(token);
};
