import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { updateHandlerByName } from '../graphql';
import {
	MutationDeleteItemArgs,
	Mutation,
	ItemCategory,
} from '../typings/graphql';

const MUTATION = gql`
	mutation deleteItemCategory($id: ID!) {
		deleteItemCategory(id: $id) {
			id
		}
	}
`;

export default (): ((variables: MutationDeleteItemArgs) => Promise<void>) => {
	const [deleteItemCategory] = useMutation<
		Pick<Mutation, 'deleteItemCategory'>,
		MutationDeleteItemArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.deleteItemCategory,
	});

	return async (variables: { id: string }): Promise<void> => {
		try {
			await deleteItemCategory({
				variables,
				optimisticResponse: {
					deleteItemCategory: {
						__typename: 'ItemCategory',
						id: variables.id,
					} as ItemCategory,
				},
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
