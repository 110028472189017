import { gql } from '@apollo/client/core';
import { Grid } from '@material-ui/core';
import ItemCard from 'components/ItemCard';
import {
	FilterCategorySelect,
	FilterColorSelect,
	FilteredItems,
	FilterMaterialSelect,
	FilterSubcategorySelect,
	ItemFilterProvider,
} from 'components/ItemFilterContext';
import UpdateItemDialog from 'components/UpdateItemDialog';
import { memoize } from 'lodash';
import React, { Key, memo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, FixedSizeGrid, GridChildComponentProps } from 'react-window';
import { getProjectPermissions } from 'utils/helpers';
import Content from '../components/Content';
import {
	useActiveProject,
	useMe,
	useUpdateAppBar,
	useViewportSize,
} from '../hooks';
import { Item } from '../typings/graphql';

export const ITEMS_QUERY = gql`
	query getItemsByProject($input: QueryItemsInput!) {
		items(input: $input) {
			id
			name
			width
			height
			depth
			secondaryDepth
			diameter
			project {
				id
			}
			image {
				id
				url
			}
			color {
				id
				name
			}
			material {
				id
				name
			}
			subcategory {
				id
				name
				category {
					id
					name
				}
			}
		}
	}
`;

const InnerView = (): JSX.Element | null => {
	useUpdateAppBar('Items');
	const project = useActiveProject();
	const [width] = useViewportSize();
	const [itemId, setItemId] = useState('');
	const me = useMe();
	const projectPermissions = me?.admin
		? null
		: getProjectPermissions(me, project.id);

	let columnCount = 0;

	if (width < 530) {
		columnCount = 1;
	} else if (width < 600) {
		columnCount = 2;
	} else if (width < 800) {
		columnCount = 1;
	} else if (width < 1050) {
		columnCount = 2;
	} else if (width < 1300) {
		columnCount = 3;
	} else if (width < 1550) {
		columnCount = 4;
	} else if (width < 1800) {
		columnCount = 5;
	} else if (width < 2050) {
		columnCount = 6;
	} else if (width < 2300) {
		columnCount = 7;
	} else {
		columnCount = 8;
	}

	const open = Boolean(itemId);

	return (
		<Content>
			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={3}>
					<FilterCategorySelect />
				</Grid>
				<Grid item xs={3}>
					<FilterSubcategorySelect />
				</Grid>
				<Grid item xs={3}>
					<FilterMaterialSelect />
				</Grid>
				<Grid item xs={3}>
					<FilterColorSelect />
				</Grid>
			</Grid>

			<FilteredItems projectId={project.id} itemQuery={ITEMS_QUERY}>
				{({ items }) => (
					<AutoSizer>
						{({ width, height }) => {
							return (
								<GridList
									height={height}
									width={width}
									items={items}
									columnCount={columnCount}
									onEdit={setItemId}
									canUpdate={projectPermissions?.canUpdate || me?.admin}
								/>
							);
						}}
					</AutoSizer>
				)}
			</FilteredItems>

			<UpdateItemDialog
				open={open}
				itemId={itemId}
				onClose={(): void => setItemId('')}
			></UpdateItemDialog>
		</Content>
	);
};

const createItemData = memoize(
	(items, setEditItem, columnCount, canUpdate) => ({
		items,
		setEditItem,
		columnCount,
		canUpdate,
	})
);

// eslint-disable-next-line react/display-name
const ItemCell = memo(
	({
		style,
		data,
		rowIndex,
		columnIndex,
	}: GridChildComponentProps): JSX.Element => {
		const { items, setEditItem, columnCount, canUpdate } = data as any;
		const index = rowIndex * columnCount + columnIndex;
		return (
			<div style={style}>
				{items[index] && (
					<ItemCard
						item={items[index]}
						onEdit={(): void => setEditItem(items[index].id)}
						canUpdate={canUpdate}
					/>
				)}
			</div>
		);
	},
	areEqual
);

const itemCellKey = ({
	data,
	rowIndex,
	columnIndex,
}: {
	data: any;
	rowIndex: number;
	columnIndex: number;
}): Key => {
	const { items, columnCount } = data;
	const index = rowIndex * columnCount + columnIndex;
	return items[index] ? items[index].id : `unknown-${index}`;
};

const GridList = ({
	height,
	width,
	items,
	columnCount,
	onEdit,
	canUpdate,
}: {
	height: number;
	width: number;
	columnCount: number;
	items: Item[];
	onEdit: (item: string) => void;
	canUpdate?: boolean;
}): JSX.Element => {
	const itemData = createItemData(items, onEdit, columnCount, canUpdate);
	const divWidth = columnCount * 240 + columnCount * 16 + 20;

	return (
		<FixedSizeGrid
			height={height - 100}
			width={divWidth}
			columnCount={columnCount}
			rowCount={Math.ceil(items.length / columnCount)}
			itemData={itemData}
			columnWidth={256}
			rowHeight={416}
			itemKey={itemCellKey}
			style={{
				marginTop: 16,
				marginLeft: (width - divWidth) / 2 + 10,
				overflowX: 'hidden',
			}}
		>
			{ItemCell}
		</FixedSizeGrid>
	);
};

const ItemsView = (): JSX.Element => (
	<ItemFilterProvider>
		<InnerView />
	</ItemFilterProvider>
);

export default ItemsView;
