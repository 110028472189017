import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import { Mutation, MutationDeleteFloorArgs } from '../typings/graphql';

const MUTATION = gql`
	mutation deleteFloor($id: ID!) {
		deleteFloor(id: $id) {
			id
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'deleteFloor'>>
) => {
	const [deleteFloor] = useMutation<
		Pick<Mutation, 'deleteFloor'>,
		MutationDeleteFloorArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationDeleteFloorArgs) => {
		deleteFloor({
			variables,
		});
	};
};
