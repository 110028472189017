import { gql } from '@apollo/client/core';

export default gql`
	type ActiveFloor {
		rid: ID!
		name: String!
	}

	input SetActiveFloorInput {
		rid: ID!
		name: String!
	}

	extend type Query {
		activeFloor: ActiveFloor
	}

	extend type Mutation {
		setActiveFloor(input: SetActiveFloorInput!): ActiveFloor
	}
`;
