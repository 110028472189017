import { gql, MutationUpdaterFn } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import {
	Mutation,
	MutationPutProjectPermissionsArgs,
} from '../typings/graphql';

const MUTATION = gql`
	mutation putProjectPermissions($input: PutProjectPermissionsInput!) {
		putProjectPermissions(input: $input) {
			authAdmin
			structAdmin
			user {
				name
			}
			projectPermissionsList {
				canCreate
				canRead
				canUpdate
				canDelete
				project {
					name
				}
			}
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'putProjectPermissions'>>
) => {
	const [putProjectPermissions] = useMutation<
		Pick<Mutation, 'putProjectPermissions'>,
		MutationPutProjectPermissionsArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationPutProjectPermissionsArgs) =>
		putProjectPermissions({
			variables,
		});
};
