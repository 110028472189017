import { gql } from '@apollo/client/core';

export default gql`
	type ActiveProject {
		rid: ID!
		name: String!
	}

	input SetActiveProjectInput {
		rid: ID!
		name: String!
	}

	extend type Query {
		activeProject: ActiveProject
	}

	extend type Mutation {
		setActiveProject(input: SetActiveProjectInput!): ActiveProject
	}
`;
