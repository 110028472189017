import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

type ActiveBuilding = {
	id: string;
	name: string;
};

const MUTATION = gql`
	mutation setActiveBuilding($input: SetActiveBuildingInput!) @client {
		setActiveBuilding(input: $input) @client
	}
`;

export default () => {
	const [setActiveBuilding] = useMutation(MUTATION);

	return (building: ActiveBuilding): void => {
		setActiveBuilding({
			variables: { input: { rid: building.id, name: building.name } },
		});
	};
};
