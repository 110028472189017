import { Grid } from '@material-ui/core';
import Content from 'components/Content';
import ItemCategoriesTable from 'components/ItemCategoriesTable';
import { TableItem } from 'elements/SimpleTable';
import React, { useState } from 'react';
import ItemSubcategoriesTable from 'components/ItemSubcategoriesTable';
import { useUpdateAppBar } from 'hooks';

const ItemSpecificsView = (): JSX.Element => {
	useUpdateAppBar('Categories');
	const [activeCategory, setActiveCategory] = useState('');

	const handleCategoryClick = (item: TableItem): void => {
		setActiveCategory(item.id);
	};

	return (
		<Content>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6}>
					<ItemCategoriesTable onSelection={handleCategoryClick} />
				</Grid>
				<Grid item xs={12} sm={6}>
					{activeCategory && (
						<ItemSubcategoriesTable category={activeCategory} />
					)}
				</Grid>
			</Grid>
		</Content>
	);
};

export default ItemSpecificsView;
