import { Dialog } from '@material-ui/core';
import React from 'react';
import UpdateArticleCommentForm, {
	FormValues,
} from './UpdateArticleCommentForm';
import { useAddCommentToArticleInCollection } from 'hooks';
import { updateHandlerByName } from '../graphql';

type Props = {
	title: string;
	description: string;
	open: boolean;
	comment?: string | null;
	onClose: () => void | undefined;
	roomId: string;
	itemId: string;
};

const AddCommentToArticleDialog = (props: Props): JSX.Element => {
	const { title, description, comment, open, onClose, roomId, itemId } = props;
	const addCommentToArticleInCollection = useAddCommentToArticleInCollection(
		(cache, mutationResult) =>
			updateHandlerByName.addCommentToArticleInCollection(
				cache,
				mutationResult,
				roomId
			)
	);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const variables = {
			query: {
				itemId,
				roomId,
			},
			comment: values.comment,
		};
		addCommentToArticleInCollection(variables);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="add-comment-to-article-dialog"
		>
			<UpdateArticleCommentForm
				title={title}
				description={description}
				initialComment={comment}
				onClose={onClose}
				onSubmit={handleSubmit}
			/>
		</Dialog>
	);
};

export default AddCommentToArticleDialog;
