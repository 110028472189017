import { createStyles, makeStyles, TableCell } from '@material-ui/core';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(() =>
	createStyles({
		th: {
			background: 'white',
			'&:hover': {
				background: '#f1f1f1',
				cursor: 'pointer',
			},
		},
		flexNoWrap: {
			display: 'flex',
			alignItems: 'center',
			flexWrap: 'nowrap',
		},
	})
);

type Props = {
	onClickHeader: () => void;
	reverseSortOrder: boolean;
	currentSortBy: string;
	sortByToSet: string;
	title: string;
};

const ClickToSortByTableHeaderCell = ({
	onClickHeader,
	reverseSortOrder,
	currentSortBy,
	sortByToSet,
	title,
}: Props): JSX.Element => {
	const classes = useStyles();
	return (
		<TableCell className={classes.th} onClick={onClickHeader}>
			<div className={classes.flexNoWrap}>
				<span>{title}</span>
				{currentSortBy === sortByToSet ? (
					reverseSortOrder ? (
						<KeyboardArrowUpIcon />
					) : (
						<KeyboardArrowDownIcon />
					)
				) : (
					''
				)}
			</div>
		</TableCell>
	);
};

export default ClickToSortByTableHeaderCell;
