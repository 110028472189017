import { Dialog } from '@material-ui/core';
import { updateHandlerByName } from '../graphql';
import { useActiveCompany } from 'hooks';
import useCreateUser from 'hooks/useCreateUser';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
// import { useSnackbar } from 'notistack';
import React from 'react';
import { extractErrorMessage } from 'utils/errorMessages';
import { CreateUserForm, FormValues } from './CreateUserForm';

type Props = {
	open: boolean;
	handleClose: () => void;
};

const CreateUserDialog = (props: Props): JSX.Element => {
	const { open, handleClose } = props;
	const snackbar = useSnackbar();
	const activeCompany = useActiveCompany();
	const createUser = useCreateUser((cache, mutationResult) =>
		updateHandlerByName.createUser(cache, mutationResult, activeCompany.id)
	);
	const existsActiveCompany = !!activeCompany.id;

	const handleSubmit = async (values: FormValues): Promise<void> => {
		if (existsActiveCompany) {
			try {
				const response = await createUser({
					input: {
						password: values.password,
						name: values.username,
						companyId: activeCompany.id,
					},
				});
				snackbar.enqueueSnackbar(
					`User ${response.data?.createUser?.name} created`,
					{
						variant: 'success',
					}
				);
			} catch (e) {
				const errorMessage = extractErrorMessage(e);
				snackbar.enqueueSnackbar(errorMessage, { variant: 'error' });
			} finally {
				handleClose();
			}
		} else {
			throw new Error('cannot create user with no active company');
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
			{existsActiveCompany ? (
				<CreateUserForm
					handleSubmit={handleSubmit}
					companyId={activeCompany.id}
				/>
			) : (
				<div>No active company :(</div>
			)}
		</Dialog>
	);
};

export default CreateUserDialog;
