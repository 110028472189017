import React from 'react';
import { Tooltip, Fab } from '@material-ui/core';
import { useUpdateAppBar } from 'hooks';
import UploadArticlesCard from 'components/UploadArticles';
import FabsContainer from '../components/FabsContainer';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import RoomList from 'components/RoomList';
import Content from '../components/Content';
import { unparse } from 'papaparse';

const ImportsView = (): JSX.Element => {
	useUpdateAppBar('Imports');
	const csvTemplate = [
		{
			ItemID: null,
			Article: null,
			Building: null,
			Floor: null,
			Room: null,
			RoomID: null,
			Width: null,
			Height: null,
			Depth: null,
			SecondaryDepth: null,
			Diameter: null,
			Category: null,
			Subcategory: null,
			Color: null,
			Material: null,
			Comment: null,
		},
	];

	const exportEmptyTemplate = () => {
		const csv = unparse(csvTemplate, { header: true });
		const csvData = new Blob([csv], { type: 'text/csv' });
		const csvURL = window.URL.createObjectURL(csvData);

		const downloadLink = document.createElement('a');
		downloadLink.href = csvURL;
		downloadLink.setAttribute('download', 'template.csv');
		downloadLink.click();
	};

	return (
		<Content>
			<UploadArticlesCard />

			<RoomList />

			<FabsContainer>
				<Tooltip title="Create empty CSV template" placement="left">
					<Fab
						style={{ backgroundColor: '#008000' }}
						size="medium"
						aria-label="create-csv-export"
						onClick={exportEmptyTemplate}
					>
						<FileIcon style={{ color: '#FFFFFF' }}></FileIcon>
					</Fab>
				</Tooltip>
			</FabsContainer>
		</Content>
	);
};

export default ImportsView;
