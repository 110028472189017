import { Divider, Drawer, List, ListSubheader } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useEffect, useState } from 'react';
import ListItemLink from '../elements/ListItemLink';
import { useScreenSizeIndicator } from '../hooks';
import ActiveProjectList from './ActiveProjectList';
import ActiveRoomList from './ActiveRoomList';
import AccountList from './AccountList';
import logo from '../images/orlogo.jpg';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		toolbar: theme.mixins.toolbar as CSSProperties,
	})
);

type TMenuItem = {
	text: string;
	icon?: JSX.Element;
	to: string;
};

const itemSpecificsNavigation: TMenuItem[] = [
	{
		text: 'Categories',
		to: '/itemCategories',
	},
	{
		text: 'Materials & Colors',
		to: '/materialsColors',
	},
];

type Props = {
	open: boolean;
	closeDrawer: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Navigation = (props: Props): JSX.Element => {
	const { open, closeDrawer } = props;
	const classes = useStyles();
	const breakpoint = useScreenSizeIndicator();
	const [drawerVariant, setDrawerVariant] = useState<'permanent' | 'temporary'>(
		'permanent'
	);

	useEffect(() => {
		if (breakpoint === 'xs' && drawerVariant !== 'temporary') {
			setDrawerVariant('temporary');
		}
		if (breakpoint !== 'xs' && drawerVariant !== 'permanent') {
			setDrawerVariant('permanent');
		}
	}, [drawerVariant, breakpoint]);

	return (
		<Drawer
			className={classes.drawer}
			variant={drawerVariant}
			open={open}
			onClose={closeDrawer}
			classes={{ paper: classes.drawerPaper }}
			anchor="left"
			ModalProps={{ keepMounted: true }}
		>
			<div className={classes.toolbar} style={{ height: 64 }}>
				<img src={logo} style={{ width: '100%' }} alt="Logo" />
			</div>
			<Divider />
			<List>
				<ListSubheader>Item Specifics</ListSubheader>
				{itemSpecificsNavigation.map((item) => (
					<ListItemLink
						leftTextPad={true}
						key={item.text}
						to={item.to}
						primary={item.text}
						icon={item?.icon}
					></ListItemLink>
				))}
			</List>
			<Divider />
			<ActiveProjectList />
			<ActiveRoomList />
			<AccountList />
		</Drawer>
	);
};

export default Navigation;
