import React from 'react';
import { FormikProps, withFormik } from 'formik';
import {
	DialogTitle,
	DialogContent,
	FormControl,
	TextField,
	Grid,
	DialogActions,
	Button,
} from '@material-ui/core';
import * as yup from 'yup';

export type FormValues = {
	oldPassword: string;
	newPassword: string;
};

type Props = {
	handleSubmit: (values: FormValues) => Promise<void>;
};

type FormProps = {
	initialOldPassword?: string;
	initialNewPassword?: string;
};

const InnerForm = (props: FormikProps<FormValues> & Props): JSX.Element => {
	const { values, touched, errors, handleChange, handleSubmit, isSubmitting } =
		props;

	return (
		<form onSubmit={handleSubmit}>
			<DialogTitle id="form-change-password">Change Password</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item sm={12}>
						<FormControl variant="outlined" fullWidth>
							<TextField
								autoFocus
								id="old-password-input"
								name="oldPassword"
								type="password"
								label="Old Password"
								value={values.oldPassword}
								onChange={handleChange}
								error={!!errors.oldPassword && touched.oldPassword}
								helperText={errors.oldPassword || ' '}
							/>
						</FormControl>
					</Grid>
					<Grid item sm={12}>
						<FormControl variant="outlined" fullWidth>
							<TextField
								id="new-password-input"
								name="newPassword"
								type="password"
								label="New Password"
								value={values.newPassword}
								onChange={handleChange}
								error={!!errors.newPassword && touched.newPassword}
								helperText={errors.newPassword || ' '}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					disabled={isSubmitting}
				>
					Submit
				</Button>
			</DialogActions>
		</form>
	);
};

const ChangePasswordForm = withFormik<FormProps & Props, FormValues>({
	mapPropsToValues: (props) => ({
		oldPassword: props.initialOldPassword || '',
		newPassword: props.initialOldPassword || '',
	}),

	validationSchema: yup.object().shape({
		oldPassword: yup.string().required('Required'),
		newPassword: yup
			.string()
			.required('Required')
			.min(6, 'Password must be at least 6 characters.'),
	}),

	async handleSubmit(values, { props }) {
		return props.handleSubmit(values);
	},
})(InnerForm);

export default ChangePasswordForm;
