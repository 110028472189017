import { gql, useQuery } from '@apollo/client';
import {
	IconButton,
	ListItemSecondaryAction,
	MenuItem,
	Select,
	SelectProps,
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import { useActiveCompany } from 'hooks';
import React, { useState } from 'react';
import { Project, Query } from '../typings/graphql';

const PROJECT_SELECT_LABEL = 'project-select-label';
const DEFAULT_SELECT_VALUE = ' '; // not sure why the empty string doesnt work here

type AddProjPermsListItemProps = {
	/**
	 * List of project ids for which permissions already exist.
	 */
	existingProjects: string[];
	onPermsAdded: (projectId: string, projectName: string) => void;
	disabled: boolean;
};

const QUERY = gql`
	query company($id: ID!) {
		company(id: $id) {
			id
			projects {
				id
				name
			}
		}
	}
`;

const AddProjPermsListItem = ({
	existingProjects,
	onPermsAdded,
	disabled,
}: AddProjPermsListItemProps) => {
	// this may contain an empty name and id, but that should never happen when this component is rendered.
	const { id: companyId } = useActiveCompany();
	const { data, loading } = useQuery<Pick<Query, 'company'>>(QUERY, {
		variables: {
			id: companyId,
		},
	});

	const [selectedProjectId, setSelectedProjectId] =
		useState(DEFAULT_SELECT_VALUE);
	const handleSelectChange: SelectProps['onChange'] = (e) => {
		setSelectedProjectId(e.target.value as string);
	};
	const handleAddClick = () => {
		const [selectedProject] = getAvailableProjects().filter(
			(p) => p.id === selectedProjectId
		);
		// if, for some reason, we cannot find the selected project, we throw an error
		if (!selectedProject) {
			throw new Error(
				`failed to find project with id ${selectedProjectId} in available projects`
			);
		}
		setSelectedProjectId(DEFAULT_SELECT_VALUE);
		onPermsAdded(selectedProject.id, selectedProject.name);
	};

	const getAvailableProjects = (): Project[] => {
		const existingProjectIds = new Set(existingProjects);
		// available projects to add are all those which are not in the set of existing project ids
		const availableProjectIds =
			loading || !data || !data.company
				? []
				: data.company.projects.filter((p) => !existingProjectIds.has(p.id));
		return availableProjectIds;
	};

	return (
		<ListItem>
			<ListItemText>Add new city permissions...</ListItemText>
			<ListItemSecondaryAction>
				<Select
					onChange={handleSelectChange}
					value={selectedProjectId}
					labelId={PROJECT_SELECT_LABEL}
					disabled={disabled}
				>
					<MenuItem value={DEFAULT_SELECT_VALUE}>
						<em>City name</em>
					</MenuItem>
					{getAvailableProjects().map((p) => (
						<MenuItem key={p.id} value={p.id}>
							{p.name}
						</MenuItem>
					))}
				</Select>
				<IconButton
					onClick={handleAddClick}
					color="secondary"
					disabled={disabled || selectedProjectId === DEFAULT_SELECT_VALUE}
				>
					<AddIcon />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

export default AddProjPermsListItem;
