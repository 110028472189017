import {
	ItemCategory,
	Mutation,
	MutationCreateItemCategoryArgs,
} from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

const MUTATION = gql`
	mutation createItemCategory($itemCategory: CreateItemCategoryInput!) {
		createItemCategory(itemCategory: $itemCategory) {
			id
			name
		}
	}
`;

export default (): ((
	variables: MutationCreateItemCategoryArgs
) => Promise<void>) => {
	const [addItemCategory] = useMutation<
		Pick<Mutation, 'createItemCategory'>,
		MutationCreateItemCategoryArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createItemCategory,
	});

	return async (variables): Promise<void> => {
		try {
			await addItemCategory({
				variables,
				optimisticResponse: {
					createItemCategory: {
						__typename: 'ItemCategory',
						id: variables.itemCategory.id!,
						name: variables.itemCategory.name,
					} as ItemCategory,
				},
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
