import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import { Mutation, MutationDeleteCompanyArgs } from '../typings/graphql';

const MUTATION = gql`
	mutation deleteCompany($id: ID!) {
		deleteCompany(id: $id) {
			id
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'deleteCompany'>>
) => {
	const [deleteCompany] = useMutation<
		Pick<Mutation, 'deleteCompany'>,
		MutationDeleteCompanyArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationDeleteCompanyArgs) => {
		deleteCompany({
			variables,
		});
	};
};
