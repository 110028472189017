import React, { useState } from 'react';
import EnhancedTable from './EnhancedTable';
import { CellOrder, TableItem } from '../elements/SimpleTable';
import {
	useSetActiveFloor,
	useActiveBuilding,
	useCreateFloor,
	useMe,
} from '../hooks';
import nameSort from '../utils/nameSort';
import CreateNameForm, { FormValues } from './CreateNameForm';
import { v4 as uuid } from 'uuid';
import { Popover } from '@material-ui/core';
import { Query } from '../typings/graphql';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { isStructAdmin } from 'utils/helpers';
import DeleteLocations from './DeleteLocations';

const QUERY = gql`
	query floors($input: QueryFloorsInput!) {
		floors(input: $input) {
			id
			name
			building {
				id
			}
		}
	}
`;

const cellOrder: CellOrder[] = [
	{
		align: 'left',
		name: 'Floor',
		itemName: 'name',
	},
];

type Props = {
	onSelection: (event?: MouseEvent) => void;
};

const FloorsTable = ({ onSelection }: Props): JSX.Element => {
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [floorToDelete, setFloorToDelete] = useState<TableItem | undefined>();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const building = useActiveBuilding();
	const me = useMe();
	const structAdmin = me ? isStructAdmin(me) : false;
	const setActiveFloor = useSetActiveFloor();
	const createFloor = useCreateFloor();

	const { data, refetch } = useQuery<Pick<Query, 'floors'>>(QUERY, {
		variables: { input: { building: building?.id } },
	});

	const handleTriggerPopover = (
		event: React.MouseEvent<HTMLButtonElement>
	): void => setAnchorEl(event.currentTarget);

	const handlePopoverClose = (): void => setAnchorEl(null);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const variables = {
			floor: {
				id: uuid(),
				name: values.name,
				buildingId: building.id,
			},
		};
		createFloor(variables);
	};

	const handleListItemClick = (item: TableItem): void => {
		setActiveFloor(item);
		onSelection();
	};

	const sorted = data?.floors.slice(0).sort(nameSort);

	const open = Boolean(anchorEl);
	const id = open ? 'create-company-popover' : undefined;

	const handleDeleteLocation = (item: TableItem) => {
		setFloorToDelete(item);
		setOpenDeleteDialog(true);
	};

	return (
		<>
			<EnhancedTable
				empty="No floors exist for this building yet"
				reload={refetch}
				items={sorted ? (sorted as TableItem[]) : []}
				searchPlaceholder="Search for a floor name"
				order={cellOrder}
				onClick={handleTriggerPopover}
				onDeleteClick={handleDeleteLocation}
				onListItemClick={handleListItemClick}
				canCreate={structAdmin}
				canDelete={me?.admin}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<CreateNameForm
					label="Floor Name"
					onSubmit={handleSubmit}
					onClose={handlePopoverClose}
				/>
			</Popover>
			<DeleteLocations
				setOpenDeleteDialog={setOpenDeleteDialog}
				openDeleteDialog={openDeleteDialog}
				floorToDelete={floorToDelete}
			/>
		</>
	);
};

export default FloorsTable;
