import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	FormControl,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

export type FormValues = {
	comment: string;
};

type Props = {
	title: string;
	description: string;
	comment?: string | null;
	onClose: () => void;
	onSubmit: (values: FormValues) => Promise<void>;
};

type FormProps = {
	initialComment?: string | null;
};

const InnerForm = (props: FormikProps<FormValues> & Props): JSX.Element => {
	const {
		values,
		touched,
		errors,
		handleChange,
		handleSubmit,
		onClose,
		isSubmitting,
		title,
		description,
	} = props;

	return (
		<form onSubmit={handleSubmit}>
			<DialogTitle id="update-article-comment-dialog">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{description}</DialogContentText>
				<FormControl variant="outlined" fullWidth>
					<TextField
						autoFocus
						fullWidth
						id="choose-comment-input"
						label="Comment"
						name="comment"
						margin="dense"
						type="text"
						multiline
						variant="outlined"
						value={values.comment}
						onChange={handleChange}
						error={!!errors.comment && touched.comment}
						helperText={errors.comment ? errors.comment : null}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button
					type="submit"
					color="primary"
					variant="contained"
					disabled={isSubmitting}
				>
					Confirm
				</Button>
			</DialogActions>
		</form>
	);
};

const AddCommentToArticleForm = withFormik<FormProps & Props, FormValues>({
	mapPropsToValues: (props) => ({
		comment: props.initialComment || '',
	}),

	validationSchema: Yup.object().shape({
		comment: Yup.string().required('Required').trim(),
	}),

	async handleSubmit(values, { setSubmitting, props }) {
		await props.onSubmit(values);
		setSubmitting(false);
		props.onClose();
	},
})(InnerForm);

export default AddCommentToArticleForm;
