import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const QUERY = gql`
	query fileExports($input: QueryFileExportsInput!) {
		fileExports(input: $input) {
			id
			name
			url
			format
			project {
				id
			}
		}
	}
`;

export const handleGenerateFileExportUpdate: MutationUpdaterFn<
	Pick<Mutation, 'generateFileExport'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { generateFileExport } = data;

	if (!generateFileExport) return;

	const cacheData = cache.readQuery<Pick<Query, 'fileExports'>>({
		query: QUERY,
		variables: { input: { project: generateFileExport.project.id } },
	});
	if (cacheData === null) return;

	const fileExports = [...cacheData.fileExports];

	fileExports.push(generateFileExport);

	cache.writeQuery({
		query: QUERY,
		data: { fileExports },
		variables: { input: { project: generateFileExport.project.id } },
	});
};

export const handleDeleteFileExport: MutationUpdaterFn<
	Pick<Mutation, 'deleteFileExport'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { deleteFileExport } = data;

	if (!deleteFileExport) return;

	const cacheData = cache.readQuery<Pick<Query, 'fileExports'>>({
		query: QUERY,
		variables: { input: { project: deleteFileExport.project.id } },
	});
	if (cacheData === null) return;

	const fileExports = cacheData.fileExports.filter(
		(fileExport) => fileExport.id !== deleteFileExport.id
	);

	cache.writeQuery({
		query: QUERY,
		data: { fileExports },
		variables: { input: { project: deleteFileExport.project.id } },
	});
};
