import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';
import { ActionType } from '../store/types';
import {
	addTrackedMutation,
	removeTrackedMutation,
} from '../store/trackedMutations';
import { ApolloLink } from '@apollo/client';

export default (dispatch: Dispatch<ActionType>) =>
	new ApolloLink((operation, forward) => {
		if (forward === undefined) {
			return null;
		}

		const name: string = operation.operationName;
		const queryJSON = JSON.stringify(operation.query);
		const variablesJSON = JSON.stringify(operation.variables);
		const context = operation.getContext();
		// Remove unused variables from the cache that just takes up space.
		delete context['cache'];
		delete context['getCacheKey'];
		delete context['optimisticResponse'];

		const id = context.id ? context.id : uuid();
		if (context.tracked !== undefined && context.id === undefined) {
			context.id = id;
			const contextJSON = JSON.stringify(context);
			dispatch(
				addTrackedMutation({
					contextJSON,
					id,
					name,
					queryJSON,
					variablesJSON,
				})
			);
		}

		return forward(operation).map((data) => {
			if (context.tracked !== undefined) {
				dispatch(removeTrackedMutation(id));
			}

			return data;
		});
	});
