import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Slider, createStyles } from '@material-ui/core';
import { Dimension } from '../views/Inventory/ItemsTableWrapper';
import { Filters } from '../typings/graphql';

type Props = {
	type: keyof Filters;
	title: string;
	defaultValue: number[];
	step: number;
	handleInputChange: (dimension: Dimension) => void;
	reset: boolean;
};

const useStyles = makeStyles(() =>
	createStyles({
		h4: {
			marginBottom: '0',
		},
		slider: {
			color: 'pink',
			margin: '0em 0.4em',
		},
	})
);

const DimensionSelector = ({
	type,
	title,
	defaultValue,
	step,
	handleInputChange,
	reset,
}: Props): JSX.Element => {
	const [value, setValue] = useState<number[]>(defaultValue);
	const classes = useStyles();

	const handleChangeCommited = (
		//the "event" parameter is required, but not used
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		event: object,
		value: number | number[]
	): void => {
		setValue(value as number[]);
		const dimension: Dimension = {
			type: type,
			value: value as number[],
			defaultValue: defaultValue,
		};
		handleInputChange(dimension);
	};

	//the "event" parameter is required, but not used
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const handleChange = (event: object, value: number | number[]): void => {
		setValue(value as number[]);
	};

	useEffect(() => {
		if (reset) {
			setValue(defaultValue);
		}
	}, [reset, defaultValue]);

	return (
		<Box>
			<h4 className={classes.h4}>{title}</h4>
			<Box className={classes.slider}>
				<Slider
					disabled={defaultValue[0] === defaultValue[1]}
					onChange={handleChange}
					value={value}
					min={defaultValue[0]}
					max={defaultValue[1]}
					defaultValue={defaultValue}
					step={step}
					aria-labelledby="range-slider"
					valueLabelDisplay="auto"
					onChangeCommitted={handleChangeCommited}
				/>
			</Box>
		</Box>
	);
};

export default DimensionSelector;
