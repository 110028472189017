import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import { Mutation, MutationDeleteProjectArgs } from '../typings/graphql';

const MUTATION = gql`
	mutation deleteProject($id: ID!) {
		deleteProject(id: $id) {
			id
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'deleteProject'>>
) => {
	const [deleteProject] = useMutation<
		Pick<Mutation, 'deleteProject'>,
		MutationDeleteProjectArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationDeleteProjectArgs) => {
		deleteProject({
			variables,
		});
	};
};
