import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const QUERY = gql`
	query getRoomsByFloor($input: QueryRoomsInput!) {
		rooms(input: $input) {
			id
			name
			floor {
				id
			}
		}
	}
`;

export const handleCreateRoomUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createRoom'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createRoom } = data;

	if (!createRoom) return;

	try {
		const cacheData = cache.readQuery<Pick<Query, 'rooms'>>({
			query: QUERY,
			variables: { input: { floor: createRoom.floor.id } },
		});
		if (cacheData === null) return;

		const rooms = cacheData.rooms.filter((room) => room.id !== createRoom.id);
		rooms.push(createRoom);

		cache.writeQuery({
			query: QUERY,
			variables: { input: { floor: createRoom.floor.id } },
			data: { rooms },
		});
	} catch (e) {
		// We optimistically write data since nothing exists
		const rooms = [createRoom];
		cache.writeQuery({
			query: QUERY,
			variables: { input: { floor: createRoom.floor.id } },
			data: { rooms },
		});
	}
};

export const handleDeleteRoomUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteRoom'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const roomIdToDelete = data.deleteRoom?.id;
	const normalizedRoomId = cache.identify({
		id: roomIdToDelete,
		__typename: 'Room',
	});
	cache.evict({ id: normalizedRoomId });
	cache.gc();
};
