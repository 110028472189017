import { gql, MutationUpdaterFn } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { Mutation, MutationUpdateEmploymentArgs } from '../typings/graphql';

const MUTATION = gql`
	mutation updateEmployment($input: UpdateEmploymentInput!) {
		updateEmployment(input: $input) {
			id
			authAdmin
			structAdmin
			user {
				name
			}
			projectPermissionsList {
				canCreate
				canRead
				canUpdate
				canDelete
				project {
					name
				}
			}
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'updateEmployment'>>
) => {
	const [updateEmployment] = useMutation<
		Pick<Mutation, 'updateEmployment'>,
		MutationUpdateEmploymentArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationUpdateEmploymentArgs) =>
		updateEmployment({
			variables,
		});
};
