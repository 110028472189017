import React from 'react';
import {
	makeStyles,
	createStyles,
	Card,
	CardActions,
	IconButton,
} from '@material-ui/core';
import ArticleCardContent from './ArticleCardContent';
import EditIcon from '@material-ui/icons/Edit';
import { Item } from '../typings/graphql';

const useStyles = makeStyles(() =>
	createStyles({
		card: {
			width: 240,
			margin: 'auto',
		},
		actions: {
			justifyContent: 'flex-end',
		},
	})
);

type Props = {
	item: Item;
	onEdit: () => void;
	canUpdate?: boolean;
};

const ItemCard = (props: Props): JSX.Element => {
	const { item, onEdit, canUpdate } = props;
	const classes = useStyles();

	const {
		name,
		width,
		height,
		depth,
		subcategory,
		material,
		color,
		image,
		id,
	} = item;
	const { category } = subcategory;

	return (
		<Card className={classes.card}>
			<ArticleCardContent
				itemName={name || null}
				id={id}
				contentHeight={175}
				imageUrl={image.url}
				category={category.name!}
				subcategory={subcategory.name!}
				width={width}
				depth={depth}
				height={height}
				material={material?.name || null}
				color={color?.name || null}
			/>
			<CardActions className={classes.actions}>
				{canUpdate && (
					<IconButton onClick={onEdit}>
						<EditIcon />
					</IconButton>
				)}
			</CardActions>
		</Card>
	);
};

export default ItemCard;
