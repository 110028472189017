import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

type ActiveFloor = {
	id: string;
	name: string;
};

const MUTATION = gql`
	mutation setActiveFloor($input: SetActiveFloorInput!) @client {
		setActiveFloor(input: $input) @client
	}
`;

export default () => {
	const [setActiveFloor] = useMutation(MUTATION);

	return (building: ActiveFloor): void => {
		setActiveFloor({
			variables: { input: { rid: building.id, name: building.name } },
		});
	};
};
