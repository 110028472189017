import { gql, MutationUpdaterFn } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { Mutation, MutationCreateUserArgs } from '../typings/graphql';

const OPTIMISTIC_RESPONSE_ID = 'CREATE_USER_OPTIMISTIC_RESPONSE_ID';

const MUTATION = gql`
	mutation createUser($input: CreateUserInput!) {
		createUser(input: $input) {
			id
			name
		}
	}
`;

export default (update: MutationUpdaterFn<Pick<Mutation, 'createUser'>>) => {
	const [createUser] = useMutation<
		Pick<Mutation, 'createUser'>,
		MutationCreateUserArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		optimisticResponse: (vars) => ({
			createUser: {
				name: vars.input.name,
				admin: false,
				id: OPTIMISTIC_RESPONSE_ID,
				employments: [],
			},
		}),
		update,
	});

	return (variables: MutationCreateUserArgs) =>
		createUser({
			variables,
		});
};
