import { Grid } from '@material-ui/core';
import Content from 'components/Content';
import MaterialsTable from 'components/MaterialsTable';
import ColorsTable from 'components/ColorsTable';
import React from 'react';
import { useUpdateAppBar } from '../hooks';

const MaterialsColorsView = (): JSX.Element => {
	useUpdateAppBar('Materials & Colors');

	return (
		<Content>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6}>
					<MaterialsTable />
				</Grid>
				<Grid item xs={12} sm={6}>
					<ColorsTable />
				</Grid>
			</Grid>
		</Content>
	);
};

export default MaterialsColorsView;
