import { gql, MutationUpdaterFn } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { Mutation, MutationDeleteUserArgs } from '../typings/graphql';

const MUTATION = gql`
	mutation deleteUser($id: ID!) {
		deleteUser(id: $id)
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'deleteUser'>>
) => {
	const [deleteUser] = useMutation<
		Pick<Mutation, 'deleteUser'>,
		MutationDeleteUserArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationDeleteUserArgs) =>
		deleteUser({
			variables,
		});
};
