import {
	AppBar,
	Box,
	Button,
	FormControl,
	IconButton,
	InputBase,
	Paper,
	Toolbar,
	Tooltip,
	Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ReloadIcon from '@material-ui/icons/Replay';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import SimpleTable, { CellOrder, TableItem } from '../elements/SimpleTable';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		gridItem: {
			display: 'flex',
		},
		search: {
			backgroundColor: theme.palette.grey[200],
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		},
		buttonCreate: {
			marginRight: theme.spacing(1),
		},
		iconButton: {
			padding: theme.spacing(1),
		},
		empty: {
			height: 150,
			color: theme.palette.grey['500'],
		},
		tablePaper: {
			maxHeight: '80vh',
			overflowY: 'auto',
		},
	})
);

type Props = {
	empty: string;
	items: TableItem[];
	order: CellOrder[];
	reload: () => void;
	searchPlaceholder?: string;
	disableSearch?: boolean;
	header?: string;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onListItemClick?: (item: TableItem) => void;
	onListItemHover?: (item: TableItem) => void;
	onDeleteClick?: (item: TableItem) => void;
	style?: React.CSSProperties;
	canCreate?: boolean;
	canDelete?: boolean;
};

const EnhancedTable = ({
	empty,
	items,
	order,
	reload,
	onClick,
	header = '',
	searchPlaceholder,
	disableSearch = false,
	onListItemClick,
	onListItemHover,
	onDeleteClick,
	style,
	canCreate,
	canDelete,
}: Props): JSX.Element => {
	const classes = useStyles();
	const [searchInput, setSearchInput] = useState('');
	const [filteredItems, setFilteredItems] = useState(items);

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setSearchInput(event.target.value);
	};

	useEffect(() => {
		setFilteredItems(
			items.filter((item) =>
				item.name.toLowerCase().includes(searchInput.toLowerCase())
			)
		);
	}, [searchInput, items]);

	return (
		<Paper>
			<AppBar
				color="default"
				position="static"
				className={classes.search}
				elevation={0}
			>
				<Toolbar variant="dense">
					<Grid container spacing={2}>
						{header.length > 0 && (
							<Grid item className={classes.gridItem}>
								<Box display="flex" my="auto">
									<Typography variant="h6" component="h6">
										{header}
									</Typography>
								</Box>
							</Grid>
						)}
						{!disableSearch && (
							<Grid item className={classes.gridItem}>
								<Box display="flex" my="auto">
									<SearchIcon />
								</Box>
							</Grid>
						)}
						<Grid item xs className={classes.gridItem}>
							{!disableSearch && (
								<Box display="flex" my="auto" style={{ width: '100%' }}>
									<FormControl fullWidth>
										<InputBase
											value={searchInput}
											onChange={handleInputChange}
											placeholder={searchPlaceholder}
											inputProps={{ 'aria-label': 'search companies' }}
										/>
									</FormControl>
								</Box>
							)}
						</Grid>
						<Grid item>
							<Tooltip
								title={canCreate ? 'Create new' : 'Permission denied'}
								aria-label="create"
							>
								<span>
									<Button
										variant="contained"
										color="primary"
										className={classes.buttonCreate}
										onClick={onClick}
										disabled={!canCreate}
										aria-details={'hello'}
									>
										Create
									</Button>
								</span>
							</Tooltip>

							<Tooltip title="Reload" placement="top">
								<span>
									<IconButton
										className={classes.iconButton}
										onClick={(): void => reload()}
									>
										<ReloadIcon />
									</IconButton>
								</span>
							</Tooltip>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Paper className={classes.tablePaper} style={style}>
				{items.length === 0 ? (
					<Box display="flex" mx="auto" my="auto" className={classes.empty}>
						<Typography style={{ margin: 'auto' }}>{empty}</Typography>
					</Box>
				) : (
					<SimpleTable
						items={filteredItems}
						order={order}
						onClick={onListItemClick}
						onHover={onListItemHover}
						onDeleteClick={onDeleteClick}
						canDelete={canDelete}
					/>
				)}
			</Paper>
		</Paper>
	);
};

export default EnhancedTable;
