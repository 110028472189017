import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

type ActiveProject = {
	id: string;
	name: string;
};

const MUTATION = gql`
	mutation setActiveProject($input: SetActiveProjectInput!) @client {
		setActiveProject(input: $input) @client
	}
`;

export default () => {
	const [setActiveProject] = useMutation(MUTATION);

	return (project: ActiveProject): void => {
		setActiveProject({
			variables: { input: { rid: project.id, name: project.name } },
		});
	};
};
