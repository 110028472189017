interface CsvData {
	Comment: string | null;
	ItemID: string;
	RoomID: string;
}

const validateCSV = (
	uploadedCSV: CsvData[],
	itemsInDatabase: string[] | undefined,
	itemsFromCsvFile: string[]
) => {
	if (!uploadedCSV) return;

	const missingItemId = uploadedCSV.filter((obj) => obj.ItemID === '');
	const missingRoom = uploadedCSV.filter((obj) => obj.RoomID === '');
	let error;

	if (missingItemId?.length > 0) {
		error = 'At least one item-ID field in the file is empty';
		return error;
	} else if (missingRoom?.length > 0) {
		error = 'At least one room-ID field in the file is empty';
		return error;
	} else if (!itemsFromCsvFile.every((id) => itemsInDatabase?.includes(id))) {
		error =
			'At least one item-ID in the file does not exist on your project. Please create the item before importing articles';
		return error;
	} else {
		return;
	}
};

export default validateCSV;
