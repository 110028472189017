import { gql } from '@apollo/client/core';

export default gql`
	type ActiveBuilding {
		rid: ID!
		name: String!
	}

	input SetActiveBuildingInput {
		rid: ID!
		name: String!
	}

	extend type Query {
		activeBuilding: ActiveBuilding
	}

	extend type Mutation {
		setActiveBuilding(input: SetActiveBuildingInput!): ActiveBuilding
	}
`;
