import { motion } from 'framer-motion';
import React, { Suspense } from 'react';

type Props = {
	children: React.ReactNode;
};

const WaitingComponent = ({ children }: Props): JSX.Element => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1, transition: { duration: 0.2 } }}
			exit={{ opacity: 0, transition: { duration: 0.2 } }}
		>
			<Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
		</motion.div>
	);
};

export default WaitingComponent;
