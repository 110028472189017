import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { useAppBar, useScreenSizeIndicator } from '../hooks';
import LocationList from './LocationList';

type StyleProps = {
	breakpoint: string;
};

const drawerWidth = 240;

const useStyles = makeStyles(() =>
	createStyles({
		appBar: {
			width: (props: StyleProps): string =>
				props.breakpoint === 'xs' ? '100%' : `calc(100% - ${drawerWidth}px)`,
			marginLeft: (props: StyleProps): number =>
				props.breakpoint === 'xs' ? 0 : drawerWidth,
		},
	})
);

type Props = {
	openDrawer: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const TopBar = (props: Props): JSX.Element => {
	const { openDrawer } = props;
	const breakpoint = useScreenSizeIndicator();
	const classes = useStyles({ breakpoint });
	const appBar = useAppBar();

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar>
				{breakpoint === 'xs' && (
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={openDrawer}
					>
						<MenuIcon />
					</IconButton>
				)}
				<Typography noWrap variant="h6">
					{appBar?.title}
				</Typography>
				<LocationList />
			</Toolbar>
		</AppBar>
	);
};

export default TopBar;
