import { gql } from '@apollo/client/core';
import _ from 'lodash';

type TVariables = {
	input: {
		rid: string;
		name: string;
	};
};

type ActiveRoom = {
	__typename: 'ActiveRoom';
	rid: string;
	name: string;
};

const QUERY = gql`
	query activeRoom {
		activeRoom @client {
			rid
			name
		}
	}
`;

export default (
	_root: void,
	variables: TVariables,
	{ cache }: TContext
): ActiveRoom | null => {
	const cacheData = cache.readQuery({ query: QUERY });
	if (cacheData === null) return null;

	const newCache = _.cloneDeep(cacheData);
	newCache.activeRoom.rid = variables.input.rid;
	newCache.activeRoom.name = variables.input.name;

	cache.writeQuery({ query: QUERY, data: newCache });
	return newCache.activeRoom;
};

export { QUERY as SET_ACTIVE_ROOM_QUERY };
