import {
	Button,
	Card,
	createStyles,
	FormControl,
	makeStyles,
	Popper,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		confirmButtons: {
			margin: theme.spacing(1),
		},
	})
);
export type FormValues = {
	instances: number;
};

type Props = {
	editable: boolean;
	onSubmit: (values: FormValues) => Promise<void>;
	onClick: () => void;
};

type FormProps = {
	initialInstances: number;
};

const InnerForm = (props: FormikProps<FormValues> & Props): JSX.Element => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
	const {
		values,
		handleChange,
		initialValues,
		errors,
		touched,
		handleSubmit,
		isSubmitting,
		editable,
		onClick,
	} = props;

	useEffect(() => {
		if (!editable && anchorEl) {
			setAnchorEl(null);
			onClick();
		} else if (anchorEl && isSubmitting) {
			setAnchorEl(null);
			onClick();
		}
	}, [anchorEl, isSubmitting, editable, onClick]);

	const handlePopoverClose = (): void => {
		setAnchorEl(null);
		onClick();
	};

	const divRef = useRef<HTMLDivElement>(null);

	const handleClick = (): void => {
		if (divRef.current) {
			setAnchorEl(divRef.current);
			onClick();
		}
	};

	const popoverOpen = Boolean(anchorEl);
	const id = popoverOpen ? 'remove-article-popover' : undefined;

	return (
		<div ref={divRef}>
			{!popoverOpen ? (
				<Typography onClick={handleClick}>{initialValues.instances}</Typography>
			) : (
				<form onSubmit={handleSubmit}>
					<FormControl variant="outlined" style={{ maxWidth: 100 }}>
						<TextField
							autoFocus
							id="choose-name-input"
							label="Nr"
							name="instances"
							margin="dense"
							type="number"
							variant="outlined"
							value={values.instances}
							onChange={handleChange}
							error={!!errors.instances && touched.instances}
						/>
					</FormControl>
					<Popper
						id={id}
						anchorEl={anchorEl}
						open={popoverOpen}
						placement="bottom"
					>
						<Card>
							<Button
								className={classes.confirmButtons}
								onClick={handlePopoverClose}
								color="primary"
							>
								Cancel
							</Button>
							<Button
								className={classes.confirmButtons}
								onClick={(): void => handleSubmit()}
								variant="contained"
								color="primary"
							>
								Submit
							</Button>
						</Card>
					</Popper>
				</form>
			)}
		</div>
	);
};

const ArticleInstancesForm = withFormik<FormProps & Props, FormValues>({
	mapPropsToValues: (props) => ({
		instances: props.initialInstances,
	}),

	validationSchema: Yup.object().shape({
		instances: Yup.number()
			.required('Required')
			.integer('Must be integer')
			.positive('Must be positive'),
	}),

	async handleSubmit(values, { props }) {
		await props.onSubmit(values);
	},

	enableReinitialize: true,
})(InnerForm);

export default ArticleInstancesForm;
