import {
	CircularProgress,
	createStyles,
	IconButton,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Fab,
} from '@material-ui/core';
import { mdiDelete, mdiDownload, mdiPdfBox, mdiFileExcelBox } from '@mdi/js';
import Icon from '@mdi/react';
import Content from '../components/Content';
import FabsContainer from '../components/FabsContainer';
import React, { useEffect } from 'react';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import {
	useActiveProject,
	useUpdateAppBar,
	useDeleteFileExport,
	useGenerateFileExport,
	useMe,
} from '../hooks';
import { v4 as uuid } from 'uuid';
import spacetime from 'spacetime';
import {
	Query,
	MutationGenerateFileExportArgs,
	FileExportVariant,
} from '../typings/graphql';
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from '../utils/errorMessages';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { getProjectPermissions } from 'utils/helpers';

const EXPORTS_QUERY = gql`
	query fileExports($input: QueryFileExportsInput!) {
		fileExports(input: $input) {
			id
			name
			url
			format
			project {
				id
			}
		}
	}
`;

const useStyles = makeStyles(() =>
	createStyles({
		fileIcon: {
			display: 'flex',
			margin: 'auto',
		},
		downloadIcon: {
			display: 'flex',
			marginLeft: 'auto',
		},
	})
);

const ExportsView = (): JSX.Element => {
	useUpdateAppBar('Exports');
	const classes = useStyles();
	const project = useActiveProject();
	const deleteFileExport = useDeleteFileExport();
	const generateFileExport = useGenerateFileExport();
	const { enqueueSnackbar } = useSnackbar();
	const me = useMe();
	let canDelete = false;
	if (me) {
		const projectPermissions = me?.admin
			? null
			: getProjectPermissions(me, project.id);
		canDelete = projectPermissions?.canDelete || me?.admin;
	}

	const { data, stopPolling, startPolling } = useQuery<
		Pick<Query, 'fileExports'>
	>(EXPORTS_QUERY, {
		variables: { input: { project: project.id } },
		fetchPolicy: 'cache-and-network',
		pollInterval: 5000,
	});

	useEffect(() => {
		const fileExports = data?.fileExports || [];
		if (fileExports?.length > 0 && !fileExports?.some((exp) => !exp.url)) {
			stopPolling();
		} else if (fileExports.some((exp) => !exp.url)) {
			startPolling(5000);
		}
	}, [data, stopPolling, startPolling]);

	const sorted = data?.fileExports
		.slice(0)
		.sort((a, b) => a.name.localeCompare(b.name) * -1);

	const submit = async (format: FileExportVariant): Promise<void> => {
		let name;
		if (format === FileExportVariant.Csv) {
			name = 'excel';
		} else if (format === FileExportVariant.PdfByRoom) {
			name = 'by-room';
		} else {
			name = 'summary';
		}

		const variables: MutationGenerateFileExportArgs = {
			input: {
				id: uuid(),
				name: `${name}-${spacetime
					.now()
					.format('{iso-short}-{time-24}:{second-pad}')}`,
				projectId: project.id,
				variant: format,
			},
		};
		try {
			await generateFileExport(variables);
		} catch (error) {
			const message = extractErrorMessage(error);
			enqueueSnackbar(message, { variant: 'error' });
		}
	};

	return (
		<Content>
			<TableContainer component={Paper}>
				<Table aria-label="exports table">
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox"></TableCell>
							<TableCell padding="none">Name</TableCell>
							<TableCell padding="normal" align="right">
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sorted &&
							sorted.map((fileExport) => {
								return (
									<TableRow key={fileExport.id}>
										<TableCell component="th" scope="row" padding="checkbox">
											<Icon
												className={classes.fileIcon}
												path={
													fileExport.format === 'pdf'
														? mdiPdfBox
														: mdiFileExcelBox
												}
												size={fileExport.format === 'csv' ? 1.07 : 1.2}
												color={
													fileExport.format === 'pdf' ? '#DB4437' : '#008000'
												}
											/>
										</TableCell>
										<TableCell padding="none">{fileExport.name}</TableCell>
										<TableCell
											align="right"
											style={{ paddingTop: 6, paddingBottom: 6 }}
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'flex-end',
												}}
											>
												{fileExport.url && (
													<Tooltip title="Download Export">
														<IconButton
															style={{ padding: 8 }}
															component={'a'}
															{...({
																href: fileExport.url,
																target: '_blank',
																rel: 'noopener noreferrer no-refresh',
															} as any)}
														>
															<Icon
																className={classes.downloadIcon}
																path={mdiDownload}
																size={1}
																color="gray"
															/>
														</IconButton>
													</Tooltip>
												)}
												{!fileExport.url && (
													<CircularProgress
														style={{ marginRight: 8 }}
														size={25}
													/>
												)}

												<Tooltip
													title={
														canDelete ? 'Delete Export' : 'Permission denied'
													}
												>
													<span>
														<IconButton
															style={{ padding: 8 }}
															disabled={!canDelete}
															onClick={(): Promise<void> =>
																deleteFileExport({ id: fileExport.id })
															}
														>
															<Icon
																className={classes.downloadIcon}
																path={mdiDelete}
																size={1}
																color="gray"
															/>
														</IconButton>
													</span>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>

			<FabsContainer>
				<Tooltip title="Create Room Export" placement="left">
					<Fab
						size="medium"
						color="primary"
						aria-label="create-room-export"
						onClick={(): Promise<void> => submit(FileExportVariant.PdfByRoom)}
					>
						<FileIcon />
					</Fab>
				</Tooltip>

				<Tooltip title="Create Summary Export" placement="left">
					<Fab
						size="medium"
						color="secondary"
						aria-label="create-summary-export"
						onClick={(): Promise<void> => submit(FileExportVariant.PdfSummary)}
					>
						<FileIcon />
					</Fab>
				</Tooltip>

				<Tooltip title="Create CSV Export" placement="left">
					<Fab
						style={{ backgroundColor: '#008000' }}
						size="medium"
						aria-label="create-csv-export"
						onClick={(): Promise<void> => submit(FileExportVariant.Csv)}
					>
						<FileIcon style={{ color: '#FFFFFF' }}></FileIcon>
					</Fab>
				</Tooltip>
			</FabsContainer>
		</Content>
	);
};

export default ExportsView;
