import { gql, useMutation } from '@apollo/client';
import {
	Mutation,
	MutationUpdateArticleArgs,
	Room,
	Item,
} from '../typings/graphql';

const MUTATION = gql`
	mutation updateArticle($article: UpdateArticleInput!) {
		updateArticle(article: $article) {
			id
			comment
			owner
			placement
			condition
			leaseStart
			leaseEnd
			lastInventoryDate
			inventoryDoneBy
			purchasedAt
			room {
				id
			}
			item {
				id
			}
		}
	}
`;

export default (): ((
	variables: MutationUpdateArticleArgs,
	roomId: string,
	itemId: string
) => Promise<void>) => {
	const [updateArticle] = useMutation<
		Pick<Mutation, 'updateArticle'>,
		MutationUpdateArticleArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
	});

	return async (variables, roomId, itemId): Promise<void> => {
		try {
			await updateArticle({
				variables,
				optimisticResponse: {
					updateArticle: {
						__typename: 'Article',
						id: variables.article.id,
						comment: variables.article.comment,
						owner: variables.article.owner,
						placement: variables.article.placement,
						condition: variables.article.condition,
						leaseStart: variables.article.leaseStart,
						leaseEnd: variables.article.leaseEnd,
						inventoryDoneBy: variables.article.inventoryDoneBy,
						lastInventoryDate: variables.article.lastInventoryDate,
						purchasedAt: variables.article.purchasedAt,
						room: {
							__typename: 'Room',
							id: variables.article.roomId || roomId,
						} as Room,
						item: {
							__typename: 'Item',
							id: variables.article.itemId || itemId,
						} as Item,
					},
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
