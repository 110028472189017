import React from 'react';
import { Grid, makeStyles, createStyles, Theme } from '@material-ui/core';
import LoginCard from 'components/LoginCard';
import { useScreenSizeIndicator } from '../hooks';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		leftSide: {
			backgroundColor: theme.palette.primary.main,
		},
	})
);

const LoginView = (): JSX.Element => {
	const classes = useStyles();
	const sizeIndicator = useScreenSizeIndicator();

	if (sizeIndicator === 'xs' || sizeIndicator === 'sm') {
		return (
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: '100vh' }}
			>
				<Grid item xs={8}>
					<LoginCard />
				</Grid>
			</Grid>
		);
	} else {
		return (
			<Grid container style={{ height: '100vh', width: '100vw' }}>
				<Grid item xs={6} className={classes.leftSide}></Grid>
				<Grid item xs={6}>
					<Grid
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justifyContent="center"
						style={{ minHeight: '100vh' }}
					>
						<Grid item xs={8}>
							<LoginCard />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
};

export default LoginView;
