/**
 * Class which provides subscriptions to local storage changes made through the same instance of this class.
 * Exported only for type definitions; do not instantiate. Use the singleton object which is also exported from
 * this module.
 */
export class LocalStorageManager {
	private nextId = 0;
	private subscribers: {
		[id: number]: (newStorage: LocalStorageManager) => void;
	} = {};

	// calls the callback of each active subscriber
	private notifySubscribers(): void {
		Object.values(this.subscribers).forEach((callback) => callback(this));
	}

	/**
	 * Subscribes to changes made to the local storage managed by this manager. Only changes made via this instance
	 * cause a notification to be sent.
	 * @param callback The function to call after the update is complete. Should take a reference to
	 * this object as a parameter for getting and/or setting new values.
	 * @returns a function which cancels the subscription.
	 */
	public subscribe(
		callback: (newStorage: LocalStorageManager) => void
	): () => void {
		// Get an id and increment next id
		const assignedId = this.nextId;
		this.nextId = this.nextId + 1;

		// Register the callback
		this.subscribers[assignedId] = callback;

		// Create a function for unsubscribing (avoiding the use of `this`, which I dont fully understand)
		const subscribers = this.subscribers;
		const unsubscribe = () => {
			delete subscribers[assignedId];
		};
		return unsubscribe;
	}

	public getItem(key: string): string | null {
		return localStorage.getItem(key);
	}

	/**
	 * Set an item and notify all subscribers.
	 */
	public setItem(key: string, value: string): void {
		localStorage.setItem(key, value);
		this.notifySubscribers();
	}

	/**
	 * Remove an item and notify all subscribers.
	 */
	public removeItem(key: string): void {
		localStorage.removeItem(key);
		this.notifySubscribers();
	}
}

export const theLocalStorageManager = new LocalStorageManager();
