import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Mutation, MutationChangePasswordArgs } from '../typings/graphql';
import { extractErrorMessage } from '../utils/errorMessages';
import ChangePasswordForm, { FormValues } from './ChangePasswordForm';

const MUTATION = gql`
	mutation changePassword($input: ChangePasswordInput!) {
		changePassword(input: $input)
	}
`;

type Props = {
	open: boolean;
	handleClose: () => void;
};

const ChangePasswordDialog = (props: Props): JSX.Element => {
	const { open, handleClose } = props;
	const { enqueueSnackbar } = useSnackbar();

	const [changePassword] = useMutation<
		Pick<Mutation, 'changePassword'>,
		MutationChangePasswordArgs
	>(MUTATION);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		try {
			await changePassword({ variables: { input: values } });
			enqueueSnackbar(`Successfully changed password.`, { variant: 'success' });
			handleClose();
		} catch (error) {
			const message = extractErrorMessage(error);
			enqueueSnackbar(message, { variant: 'error' });
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
			<ChangePasswordForm handleSubmit={handleSubmit} />
		</Dialog>
	);
};

export default ChangePasswordDialog;
