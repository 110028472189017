import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider';
import { persistCache } from 'apollo3-cache-persist';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOnline } from '../hooks';
import { setOnline } from '../store/online';
import client, { queueLink } from './client';

type Props = {
	children: React.ReactNode;
};

const ApolloPersist = ({ children }: Props): JSX.Element | null => {
	const online = useOnline();
	const dispatch = useDispatch();
	const [persisted, setPersisted] = useState(false);

	useEffect(() => {
		dispatch(setOnline(navigator.onLine));
	}, [dispatch]);

	useEffect(() => {
		if (online) {
			queueLink.open();
		} else {
			queueLink.close();
		}
	}, [online]);

	// Apollo persist cache
	useEffect(() => {
		const execute = async (): Promise<void> => {
			await persistCache({
				cache: client.cache,
				storage: window.localStorage,
			});
			setPersisted(true);
		};

		execute();
	}, []);

	if (!persisted) return null;

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloPersist;
