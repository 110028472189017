import { combineReducers, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import online from './online';
import trackedMutations from './trackedMutations';
import { ActionType, State } from './types';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['trackedMutations'],
};

const appReducer = combineReducers({
	online,
	trackedMutations,
});

const LOGOUT = 'LOGOUT';

interface LogoutAction {
	type: typeof LOGOUT;
}

export type LogoutActionType = LogoutAction;

export const logout = (): LogoutAction => ({
	type: LOGOUT,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const rootReducer = (state: State | undefined, action: ActionType) => {
	if (action.type === LOGOUT) {
		storage.removeItem('persist:root');
		state = undefined;
	}

	return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, devToolsEnhancer({}));
const persistor = persistStore(store);

export { store, persistor };
