import { Dialog } from '@material-ui/core';
import axios from 'axios';
import useCreateImage from 'hooks/useCreateImage';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
	useActiveProject,
	useActiveRoom,
	useCreateArticle,
	useCreateItem,
} from '../hooks';
import CreateItemForm, { FormValues } from './CreateItemForm';
import { Mutation, Query } from '../typings/graphql';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { gql } from '@apollo/client/core';

type Props = {
	open: boolean;
	onClose: () => void;
};

const CATEGORIES_MATERIALS_COLORS_QUERY = gql`
	query itemSpecifics {
		itemCategories {
			id
			name
			subcategories {
				id
				name
			}
		}
		materials {
			id
			name
		}
		colors {
			id
			name
		}
	}
`;

const CreateArticleDialog = ({ open, onClose }: Props): JSX.Element | null => {
	const [imageFile, setImageFile] = useState('');
	// const screenSizeIndicator = useScreenSizeIndicator();
	const project = useActiveProject();
	const room = useActiveRoom();
	const createItem = useCreateItem();
	const createArticle = useCreateArticle();

	const handleImageUpload = async (
		data: Pick<Mutation, 'createImage'>
	): Promise<void> => {
		// UPLOAD IMAGE HERE
		// TODO: Fix this
		// This needs to be done via the goddamn redux system.
		// Or we just upload the image to the server and handle the upload
		// to S3 from there
		try {
			if (data.createImage) {
				await axios.put(data.createImage?.signedUploadUrl, imageFile);
			}
		} catch (err) {
			console.log('Image upload failed');
			console.log(err);
		}
	};

	const createImage = useCreateImage(handleImageUpload);

	const { data } = useQuery<
		Pick<Query, 'itemCategories' | 'materials' | 'colors'>
	>(CATEGORIES_MATERIALS_COLORS_QUERY);

	const itemCategories = data?.itemCategories || [];
	const materials = data?.materials || [];
	const colors = data?.colors || [];

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const imageVariables = {
			id: uuid(),
			projectId: project.id,
		};

		setImageFile(values.image.file);
		createImage(
			{
				image: imageVariables,
			},
			values.image.imagePreviewUrl
		);

		// We find these and provide them to the createItem mutation so that the optimistic values are correct.
		const category = itemCategories.find((category) =>
			category.subcategories.find(
				(subcategory) => subcategory.id === values.subcategory
			)
		);
		const subcategory = category?.subcategories.find(
			(subcategory) => subcategory.id === values.subcategory
		);
		const color = colors.find((color) => color.id === values.color);
		const material = materials.find(
			(material) => material.id === values.material
		);
		const name = values.name ? values.name : '';
		const item = {
			id: uuid(),
			projectId: project.id,
			itemSubcategoryId: values.subcategory,
			imageId: imageVariables.id,
			name: name,
			...(values.width ? { width: parseFloat(values.width) } : {}),
			...(values.depth ? { depth: parseFloat(values.depth) } : {}),
			...(values.height ? { height: parseFloat(values.height) } : {}),
			...(values.secondaryDepth
				? { secondaryDepth: parseFloat(values.secondaryDepth) }
				: {}),
			...(values.diameter ? { diameter: parseFloat(values.diameter) } : {}),
			...(values.material ? { materialId: values.material } : {}),
			...(values.color ? { colorId: values.color } : {}),
		};

		createItem({ item }, values.image.imagePreviewUrl, {
			name: name,
			project: project.id,
			subcategory: subcategory!.id,
			material: material!.id,
			color: color!.id,
			image: imageVariables.id,
		});

		const articleVariables = {
			id: uuid(),
			comment: values.comment,
			roomId: room?.id,
			itemId: item.id,
		};

		createArticle({ article: articleVariables });
		onClose();
	};

	return (
		<Dialog
			open={open}
			// fullWidth={screenSizeIndicator === 'xs'}
			maxWidth={false}
			onClose={onClose}
			aria-labelledby="form-create-article"
		>
			<CreateItemForm
				title="Create New Article"
				itemCategories={itemCategories}
				materials={materials}
				colors={colors}
				onClose={onClose}
				onSubmit={handleSubmit}
			/>
		</Dialog>
	);
};

export default CreateArticleDialog;
