import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import {
	createStyles,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
} from '@material-ui/core';
import ExitIcon from '@material-ui/icons/ExitToApp';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import VpnKey from '@material-ui/icons/VpnKey';
import ListItemLink from 'elements/ListItemLink';
import { useMe } from 'hooks';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store';
import { isAuthAdmin } from 'utils/helpers';
import { theLocalStorageManager } from 'utils/storageManager';
import ChangePasswordDialog from './ChangePasswordDialog';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			marginTop: 'auto',
		},
	})
);

const AccountList = (): JSX.Element | null => {
	const classes = useStyles();
	const client = useApolloClient();
	const [open, setOpen] = useState(false);

	const me = useMe();
	const authAdmin = me ? isAuthAdmin(me) : false;
	const dispatch = useDispatch();

	const handleLogout = async (): Promise<void> => {
		theLocalStorageManager.removeItem('token');
		await client.clearStore();
		dispatch(logout());

		/**
		 * Reloading page here because otherwise the mutation
		 * to login doesn't work. No idea why.
		 */
		window.location.reload();
	};

	const handleOpen = (): void => setOpen(true);
	const handleClose = (): void => setOpen(false);

	return (
		<List component="nav" className={classes.root}>
			<ListItemLink
				primary="Inventory"
				to="/inventory"
				icon={<SearchOutlined />}
				leftTextPad={false}
			/>
			<ListItem button onClick={handleOpen}>
				<ListItemIcon>
					<VpnKey />
				</ListItemIcon>
				<ListItemText primary="Change password" />
			</ListItem>
			{authAdmin && (
				<ListItemLink
					primary="Manage Users"
					to="/managePermissions"
					icon={<SupervisedUserCircleIcon />}
					leftTextPad={false}
				/>
			)}
			<ChangePasswordDialog open={open} handleClose={handleClose} />
			<ListItem button onClick={handleLogout}>
				<ListItemIcon>
					<ExitIcon />
				</ListItemIcon>
				<ListItemText primary="Logout" />
			</ListItem>
		</List>
	);
};

export default AccountList;
