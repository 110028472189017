import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const ITEMS_QUERY = gql`
	query getItemsByProject($input: QueryItemsInput!) {
		items(input: $input) {
			id
			width
			height
			depth
			secondaryDepth
			diameter
			project {
				id
			}
		}
	}
`;

export const handleCreateItemUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createItem'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createItem } = data;

	if (!createItem) return;

	try {
		const cacheData = cache.readQuery<Pick<Query, 'items'>>({
			query: ITEMS_QUERY,
			variables: { input: { project: createItem.project.id } },
		});
		if (cacheData === null) return;

		const { items } = cacheData;
		const newItems = { items: [...items, createItem] };

		cache.writeQuery({
			query: ITEMS_QUERY,
			variables: { input: { project: createItem.project.id } },
			data: newItems,
		});
	} catch (e) {
		// We optimistically write data since nothing exists
		const emptyCache = { items: [createItem] };
		cache.writeQuery({
			query: ITEMS_QUERY,
			variables: { input: { project: createItem.project.id } },
			data: emptyCache,
		});
	}
};

export const handleUpdateItemUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'updateItem'>
> = (cache, { data }, projectId) => {
	if (data === undefined || data === null) return;

	const { updateItem } = data;

	if (!updateItem) return;

	const cacheData = cache.readQuery<Pick<Query, 'items'>>({
		query: ITEMS_QUERY,
		variables: { input: { project: projectId } },
	});

	const items =
		cacheData?.items.filter((item) => item.id !== updateItem.id) || [];
	items.push(updateItem);

	cache.writeQuery({
		query: ITEMS_QUERY,
		variables: { input: { project: projectId } },
		data: { items },
	});
};
