import { gql, MutationUpdaterFn } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import {
	Mutation,
	MutationDeleteProjectPermissionsArgs,
} from '../typings/graphql';

const MUTATION = gql`
	mutation deleteProjectPermissions($input: DeleteProjectPermissionsInput!) {
		deleteProjectPermissions(input: $input) {
			authAdmin
			structAdmin
			user {
				name
			}
			projectPermissionsList {
				canCreate
				canRead
				canUpdate
				canDelete
				project {
					name
				}
			}
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'deleteProjectPermissions'>>
) => {
	const [deleteProjectPermissions] = useMutation<
		Pick<Mutation, 'deleteProjectPermissions'>,
		MutationDeleteProjectPermissionsArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationDeleteProjectPermissionsArgs) =>
		deleteProjectPermissions({
			variables,
		});
};
