import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

type ActiveRoom = {
	id: string;
	name: string;
};

const MUTATION = gql`
	mutation setActiveRoom($input: SetActiveRoomInput!) @client {
		setActiveRoom(input: $input) @client
	}
`;

export default () => {
	const [setActiveRoom] = useMutation(MUTATION);

	return (room: ActiveRoom): void => {
		setActiveRoom({
			variables: { input: { rid: room.id, name: room.name } },
		});
	};
};
