import { useQuery } from '@apollo/client';
import {
	CircularProgress,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { SMALL_USERS_QUERY } from 'graphql/users';
import React, { useState } from 'react';
import { Query, QueryUsersArgs } from '../typings/graphql';
import EditEmploymentDialog from './EditEmploymentDialog';
import DeleteUserDialog from './DeleteUserDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useActiveCompany } from 'hooks';

type UserTableProps = {
	companyId: string;
};

type DialogRowProps = {
	name: string;
	id: string;
	structAdmin: boolean;
	authAdmin: boolean;
};

const RowAndDialog = ({ name, id, structAdmin, authAdmin }: DialogRowProps) => {
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const activeCompany = useActiveCompany();

	return (
		<>
			<EditEmploymentDialog
				userId={id}
				open={editDialogOpen}
				handleClose={() => setEditDialogOpen(false)}
			></EditEmploymentDialog>
			<DeleteUserDialog
				userId={id}
				userName={name}
				companyId={activeCompany.id}
				open={deleteDialogOpen}
				handleClose={() => setDeleteDialogOpen(false)}
			></DeleteUserDialog>
			<TableRow>
				<TableCell>{name}</TableCell>
				<TableCell align="center">
					{structAdmin ? <CheckIcon /> : <CloseIcon />}
				</TableCell>
				<TableCell align="center">
					{authAdmin ? <CheckIcon /> : <CloseIcon />}
				</TableCell>
				<TableCell align="center">
					<IconButton onClick={() => setEditDialogOpen(true)}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={() => setDeleteDialogOpen(true)}>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>
		</>
	);
};

export const UserTable = ({ companyId }: UserTableProps): JSX.Element => {
	const { data, loading /*, refetch */ } = useQuery<
		Pick<Query, 'users'>,
		QueryUsersArgs
	>(SMALL_USERS_QUERY, { variables: { companyId } });
	return loading ? (
		<CircularProgress variant="indeterminate" />
	) : (
		<TableContainer>
			<Table>
				<TableBody>
					{(data?.users || []).map((u) => {
						const companyEmployment = u.employments.filter(
							(e) => e.company.id === companyId
						)[0];
						return (
							<RowAndDialog
								key={u.id}
								name={u.name}
								id={u.id}
								structAdmin={companyEmployment?.structAdmin}
								authAdmin={companyEmployment?.authAdmin}
							/>
						);
					})}
				</TableBody>
				<TableHead>
					<TableRow>
						<TableCell>Username</TableCell>
						<TableCell align="center">Structure admin</TableCell>
						<TableCell align="center">Authorization admin</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
			</Table>
		</TableContainer>
	);
};
