import { Mutation, MutationDeleteMaterialArgs } from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

const MUTATION = gql`
	mutation deleteMaterial($id: ID!) {
		deleteMaterial(id: $id) {
			id
			name
		}
	}
`;

export default (): ((
	variables: MutationDeleteMaterialArgs
) => Promise<void>) => {
	const [deleteMaterial] = useMutation<
		Pick<Mutation, 'deleteMaterial'>,
		MutationDeleteMaterialArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.deleteMaterial,
	});

	return async (variables): Promise<void> => {
		try {
			await deleteMaterial({
				variables,
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
