import { gql } from '@apollo/client/core';
import _ from 'lodash';
import setActiveRoom from '../activeRoom/setActiveRoom';

type TVariables = {
	input: {
		rid: string;
		name: string;
	};
};

type ActiveFloor = {
	__typename: 'ActiveFloor';
	rid: string;
	name: string;
};

const QUERY = gql`
	query activeFloor {
		activeFloor @client {
			rid
			name
		}
	}
`;

export default (
	_root: void,
	variables: TVariables,
	{ cache }: TContext
): ActiveFloor | null => {
	const cacheData = cache.readQuery({ query: QUERY });
	if (cacheData === null) return null;

	const newCache = _.cloneDeep(cacheData);
	newCache.activeFloor.rid = variables.input.rid;
	newCache.activeFloor.name = variables.input.name;

	cache.writeQuery({ query: QUERY, data: newCache });

	setActiveRoom(undefined, { input: { rid: '', name: '' } }, { cache });

	return newCache.activeFloor;
};

export { QUERY as SET_ACTIVE_FLOOR_QUERY };
