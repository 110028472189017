import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { Image, Mutation, MutationCreateImageArgs } from '../typings/graphql';

const MUTATION = gql`
	mutation createImage($image: CreateImageInput!) {
		createImage(image: $image) {
			signedUploadUrl
			image {
				id
				url
			}
		}
	}
`;

type HookReturn = (
	variables: MutationCreateImageArgs,
	previewUrl?: string
) => Promise<void>;

export default (
	handleCompleted: (data: Pick<Mutation, 'createImage'>) => Promise<void>
): HookReturn => {
	const [addImage] = useMutation<
		Pick<Mutation, 'createImage'>,
		MutationCreateImageArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		onCompleted: (data) => handleCompleted(data),
	});

	return async (variables, previewUrl): Promise<void> => {
		try {
			await addImage({
				variables,
				optimisticResponse: {
					createImage: {
						__typename: 'CreateImagePayload',
						signedUploadUrl: '',
						image: {
							__typename: 'Image',
							id: variables.image.id!,
							url: previewUrl || '',
						} as Image,
					},
				},
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
