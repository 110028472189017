import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import { times } from 'lodash';
import {
	Mutation,
	MutationDeleteArticlesArgs,
	Article,
} from '../typings/graphql';

const MUTATION = gql`
	mutation deleteArticles($ids: [ID!]!) {
		deleteArticles(ids: $ids) {
			id
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'deleteArticles'>>
): (({ ids }: { ids: string[] }) => void) => {
	const [deleteArticles] = useMutation<
		Pick<Mutation, 'deleteArticles'>,
		MutationDeleteArticlesArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandler,
	});

	return async (variables: { ids: string[] }): Promise<void> => {
		try {
			const articles: Article[] = [];

			times(variables.ids.length, (index) => {
				articles.push({
					__typename: 'Article',
					id: variables.ids[index],
				} as Article);
			});

			await deleteArticles({
				variables,
				optimisticResponse: {
					deleteArticles: articles,
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
