import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import WaitingComponent from './components/WaitingComponent';
import PrivateRoute from './components/PrivateRoute';
import AuthedRouter from 'authedRouter';
import useLoggedIn from 'hooks/useLoggedIn';
import LoginView from 'views/Login';

const SiteRouter = (): JSX.Element => {
	const loggedIn = useLoggedIn();
	const history = useHistory();
	const location = useLocation();
	const [redirectToPrev] = useState(location.pathname);

	useEffect(() => {
		if (!loggedIn) {
			history.push('/login');
		} else if (redirectToPrev === '/login') {
			history.push('/');
		} else {
			history.push(redirectToPrev);
		}
	}, [loggedIn, history, redirectToPrev]);

	return (
		<>
			<Switch>
				<Route path="/login">
					<WaitingComponent>
						<LoginView />
					</WaitingComponent>
				</Route>

				<PrivateRoute path="/" allow={loggedIn}>
					<AuthedRouter />
				</PrivateRoute>
			</Switch>
		</>
	);
};

export default SiteRouter;
