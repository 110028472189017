import { gql, useQuery } from '@apollo/client';
import {
	Box,
	Card,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Article, Query } from '../../typings/graphql';
import ArticleRow from './ArticleRow';
import ClickToSortByTableHeaderCell from '../../elements/ClickToSortByTableHeaderCell';
import { useParams } from 'react-router-dom';

export const ARTICLES_BY_ITEM_QUERY = gql`
	query getArticlesByItem($input: QueryArticlesInput!) {
		articles(input: $input) {
			id
			comment
			owner
			lastInventoryDate
			inventoryDoneBy
			leaseStart
			leaseEnd
			placement
			condition
			purchasedAt
			item {
				id
			}
			room {
				id
				name
				floor {
					id
					name
					building {
						id
						name
					}
				}
			}
		}
	}
`;

type Props = {
	itemId: string;
	input: Record<string, unknown>;
};

export type articleParams = {
	articleId: string;
};

const ArticlesTable = ({ itemId, input }: Props): JSX.Element => {
	const params = useParams() as articleParams;
	const paramsArticleId = params.articleId ? params.articleId : '';
	const [sortedArticles, setSortedArticles] = useState<Article[]>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [sortBy, setSortBy] = useState('id');
	const [reverseSortOrder, setReverseSortOrder] = useState(false);
	const { data: articleData } = useQuery<Pick<Query, 'articles'>>(
		ARTICLES_BY_ITEM_QUERY,
		{
			variables: {
				input: paramsArticleId
					? { ids: paramsArticleId }
					: { ...input, item: itemId },
			},
			returnPartialData: true,
			fetchPolicy: 'cache-and-network',
		}
	);
	const loaded = articleData?.articles !== undefined;

	useEffect(() => {
		if (loaded) setSortedArticles(articleData?.articles);
	}, [loaded, articleData]);

	//the "event" parameter is required, but not used
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleSortByColumns = (newSortBy: string) => {
		const newReverseSortOrder =
			newSortBy !== sortBy ? false : !reverseSortOrder;
		setReverseSortOrder(newReverseSortOrder);
		setSortBy(newSortBy);

		const sorter = (article: Article) =>
			typeof _.get(article, newSortBy) === 'string'
				? _.get(article, newSortBy).toLowerCase()
				: _.get(article, newSortBy);
		setSortedArticles(
			_.orderBy(
				sortedArticles,
				[sorter],
				newReverseSortOrder ? ['desc'] : ['asc']
			)
		);
		setPage(0);
	};

	return (
		<Card style={{ backgroundColor: 'white', margin: '1rem' }}>
			{loaded ? (
				<>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<ClickToSortByTableHeaderCell
										currentSortBy={sortBy}
										sortByToSet={'id'}
										title={'ID'}
										reverseSortOrder={reverseSortOrder}
										onClickHeader={() => handleSortByColumns('id')}
									/>
									<ClickToSortByTableHeaderCell
										currentSortBy={sortBy}
										sortByToSet={'room.floor.building.name'}
										title={'Building'}
										reverseSortOrder={reverseSortOrder}
										onClickHeader={() =>
											handleSortByColumns('room.floor.building.name')
										}
									/>
									<ClickToSortByTableHeaderCell
										currentSortBy={sortBy}
										sortByToSet={'room.floor.name'}
										title={'Room'}
										reverseSortOrder={reverseSortOrder}
										onClickHeader={() => handleSortByColumns('room.floor.name')}
									/>
									<ClickToSortByTableHeaderCell
										currentSortBy={sortBy}
										sortByToSet={'placement'}
										title={'Placement'}
										reverseSortOrder={reverseSortOrder}
										onClickHeader={() => handleSortByColumns('placement')}
									/>
									<ClickToSortByTableHeaderCell
										currentSortBy={sortBy}
										sortByToSet={'condition'}
										title={'Condition'}
										reverseSortOrder={reverseSortOrder}
										onClickHeader={() => handleSortByColumns('condition')}
									/>
									<ClickToSortByTableHeaderCell
										currentSortBy={sortBy}
										sortByToSet={'comment'}
										title={'Comment'}
										reverseSortOrder={reverseSortOrder}
										onClickHeader={() => handleSortByColumns('comment')}
									/>
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{sortedArticles
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((article) => {
										return (
											<ArticleRow
												key={article.id}
												article={article}
												itemId={itemId}
											/>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 100]}
						component="div"
						count={sortedArticles.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			) : (
				<Box display="flex" justifyContent="center" margin={5}>
					<CircularProgress variant="indeterminate" />
				</Box>
			)}
		</Card>
	);
};

export default ArticlesTable;
