import { gql } from '@apollo/client/core';
import _ from 'lodash';

type TVariables = {
	title: string;
};

const QUERY = gql`
	query appBar {
		appBar @client {
			title
		}
	}
`;

export default (_root: void, variables: TVariables, { cache }: TContext) => {
	const cacheData = cache.readQuery({ query: QUERY });

	const newCache = _.cloneDeep(cacheData);
	newCache.appBar.title = variables.title;

	cache.writeQuery({ query: QUERY, data: newCache });
	return newCache.appBar;
};

export { QUERY as SET_APP_BAR_TITLE_QUERY };
