import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import {
	Mutation,
	MutationSetArticleCollectionCountArgs,
	Item,
} from '../typings/graphql';

const MUTATION = gql`
	mutation setArticleCollectionCount(
		$query: QueryArticleCollectionInput!
		$count: Int!
	) {
		setArticleCollectionCount(query: $query, count: $count) {
			id
			comment
			count
			item {
				id
			}
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'setArticleCollectionCount'>>
): ((variables: MutationSetArticleCollectionCountArgs) => Promise<void>) => {
	const [setArticleCollectionCount] = useMutation<
		Pick<Mutation, 'setArticleCollectionCount'>,
		MutationSetArticleCollectionCountArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandler,
	});

	return async (variables): Promise<void> => {
		try {
			const { query, count } = variables;

			const id = query.comment
				? `${query.itemId}@${query.comment}`
				: query.itemId;

			await setArticleCollectionCount({
				variables,
				optimisticResponse: {
					setArticleCollectionCount: {
						__typename: 'ArticleCollection',
						id,
						count,
						comment: query.comment,
						item: {
							__typename: 'Item',
							id: query.itemId,
						} as Item,
					},
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
