import {
	Mutation,
	MutationCreateItemSubcategoryArgs,
	ItemCategory,
} from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

const MUTATION = gql`
	mutation createItemSubcategory(
		$itemSubcategory: CreateItemSubcategoryInput!
	) {
		createItemSubcategory(itemSubcategory: $itemSubcategory) {
			id
			name
			category {
				id
			}
		}
	}
`;

export default () => {
	const [addItemSubcategory] = useMutation<
		Pick<Mutation, 'createItemSubcategory'>,
		MutationCreateItemSubcategoryArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createItemSubcategory,
	});

	return async (
		variables: MutationCreateItemSubcategoryArgs
	): Promise<void> => {
		try {
			await addItemSubcategory({
				variables,
				optimisticResponse: {
					createItemSubcategory: {
						__typename: 'ItemSubcategory',
						id: variables.itemSubcategory.id!,
						name: variables.itemSubcategory.name,
						category: {
							__typename: 'ItemCategory',
							id: variables.itemSubcategory.itemCategoryId,
						} as ItemCategory,
					},
				},
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
