import {
	CardActionArea,
	CardContent,
	CardMedia,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import ImageWrapper from 'elements/ImageWrapper';
import React, { useState } from 'react';
import ImageEnlargeDialog from './ImageEnlargeDialog';
import { useSnackbar } from 'notistack';
import copyString from 'hooks/useCopyString';

type Props = {
	itemName: Nullable<string>;
	id: string;
	imageUrl: string;
	category: string;
	subcategory: string;
	material: Nullable<string>;
	color: Nullable<string>;
	width?: Nullable<number>;
	depth?: Nullable<number>;
	height?: Nullable<number>;
	comment?: Nullable<string>;
	instantImage?: boolean;
	contentHeight?: number;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rightAlign: {
			textAlign: 'right',
		},
		imageContainer: {
			width: 240,
			height: 160,
			backgroundColor: theme.palette.grey[400],
		},
		content: {
			height: (props: { contentHeight?: number }): number =>
				props.contentHeight ? props.contentHeight : 200,
			overflow: 'auto',
		},
		image: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
		button: {
			backgroundColor: 'transparent',
			border: '1px solid black',
			borderRadius: '4px',
		},
	})
);

type Row = {
	title: string;
	content: string;
	subtitle?: boolean;
	noGutters?: boolean;
};

const ArticleCardContent = (props: Props): JSX.Element => {
	const {
		itemName,
		imageUrl,
		category,
		subcategory,
		material,
		color,
		width,
		depth,
		height,
		comment,
		contentHeight,
		id,
	} = props;
	const [imageEnlargeOpen, setImageEnlargeOpen] = useState(false);
	const styleProps = { contentHeight };
	const snackbar = useSnackbar();
	const classes = useStyles(styleProps);

	const rows: Row[] = [
		{
			title: 'Name:',
			content: itemName || 'No name added.',
		},
		{
			title: 'ID:',
			content: id,
		},
		{
			title: 'Category:',
			content: category,
		},
		{
			title: 'Subcategory:',
			content: subcategory,
		},
	];

	if (material) rows.push({ title: 'Material:', content: material });
	if (color) rows.push({ title: 'Color:', content: color });
	if (width) {
		rows.push({
			title: 'Dimensions:',
			content: `${width}x${height}x${depth}`,
			noGutters: true,
		});
		rows.push({
			title: '',
			content: 'WxHxD',
			subtitle: true,
		});
	}

	const Row = ({ title, content, subtitle, noGutters }: Row): JSX.Element => {
		return (
			<>
				<Grid item xs={6}>
					<Typography
						gutterBottom={!noGutters}
						variant="body2"
						color="textPrimary"
						component="p"
					>
						{title}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography
						gutterBottom={!noGutters}
						variant={subtitle ? 'caption' : 'body2'}
						color={subtitle ? 'textSecondary' : 'textPrimary'}
						component="p"
						className={classes.rightAlign}
					>
						{title === 'ID:' ? (
							<button
								onClick={copyItemId}
								value={content}
								className={classes.button}
							>
								Copy ID
							</button>
						) : (
							content
						)}
					</Typography>
				</Grid>
			</>
		);
	};

	const copyItemId = (event: React.MouseEvent<HTMLButtonElement>) => {
		const copiedId = copyString(event);

		snackbar.enqueueSnackbar(`Copied ${copiedId} to clipboard!`, {
			variant: 'success',
		});
	};

	return (
		<>
			<CardActionArea onClick={(): void => setImageEnlargeOpen(true)}>
				<CardMedia className={classes.imageContainer}>
					<ImageWrapper className={classes.image} src={imageUrl} retries={3} />
				</CardMedia>
			</CardActionArea>
			<CardContent className={classes.content}>
				<Grid container spacing={0}>
					{rows.map(({ title, content, subtitle, noGutters }) => (
						<Row
							key={`${title}-${content}`}
							title={title}
							content={content}
							subtitle={subtitle}
							noGutters={noGutters}
						/>
					))}
				</Grid>

				{comment && (
					<Typography variant="body2" color="textSecondary" component="p">
						{comment}
					</Typography>
				)}
			</CardContent>
			<ImageEnlargeDialog
				open={imageEnlargeOpen}
				onClose={(): void => setImageEnlargeOpen(false)}
				imageUrl={imageUrl}
			/>
		</>
	);
};

export default ArticleCardContent;
