import { Card, CardMedia } from '@material-ui/core';
import { initLocalState } from '../graphql/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import LoginForm, { FormValues } from './LoginForm';
import logo from '../images/orlogo.jpg';
import { extractErrorMessage } from '../utils/errorMessages';
import { gql } from '@apollo/client/core';
import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { theLocalStorageManager } from 'utils/storageManager';

const LOGIN_MUTATION = gql`
	mutation login($input: LoginInput!) {
		login(input: $input) {
			user {
				id
				name
			}
			token
		}
	}
`;

type Response = {
	login: {
		user: {
			id: string;
			name: string;
		};
		token: string;
	};
};

const LoginCard = (): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const client = useApolloClient();
	const [login] = useMutation<Response>(LOGIN_MUTATION);

	const handleLogin = async (values: FormValues): Promise<void> => {
		try {
			const response = await login({ variables: { input: values } });
			if (response.data?.login.token) {
				theLocalStorageManager.setItem('token', response.data.login.token);
				await client.clearStore();
				await initLocalState();
			}
		} catch (error) {
			const message = extractErrorMessage(error);
			enqueueSnackbar(message, { variant: 'error' });
		}
		return;
	};

	return (
		<Card>
			<CardMedia
				component="img"
				image={logo}
				style={{ padding: 8, width: '100%' }}
			></CardMedia>
			<LoginForm onSubmit={handleLogin} />
		</Card>
	);
};

export default LoginCard;
