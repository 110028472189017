import {
	Checkbox,
	Collapse,
	FormControlLabel,
	FormGroup,
	IconButton,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
	DateTimePicker,
	DateTimePickerProps,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import _ from 'lodash';
import React, { useState } from 'react';
import spacetime from 'spacetime';
// needed for mui pickers components, see https://v4.mui.com/components/pickers/
import DateFnsUtils from '@date-io/date-fns';
import { PutProjectPermissionsInput } from '../typings/graphql';

type PermsEditorProps = {
	perms: PutProjectPermissionsInput;
	projectName: string;
	disabled: boolean;
	onChange: (newPerms: LimitedProjectPermissions) => void;
	onDelete: () => void;
};

export const PermsEditorListItem = ({
	perms,
	disabled,
	projectName,
	onChange,
	onDelete,
}: PermsEditorProps) => {
	const [expanded, setExpanded] = useState(false);
	const toggleExpanded = () => setExpanded(!expanded);
	const resetNonReadPerms = (lpp: LimitedProjectPermissions) => {
		const newPerms = _.cloneDeep(lpp);
		newPerms.canCreate = false;
		newPerms.canUpdate = false;
		newPerms.canDelete = false;
		return newPerms;
	};
	const makeNewPerms = <K extends keyof LimitedProjectPermissions>(
		key: K,
		value: LimitedProjectPermissions[K]
	) => {
		let newPerms: LimitedProjectPermissions = _.cloneDeep(perms);
		if (key === 'canRead') {
			newPerms = resetNonReadPerms(newPerms);
		}
		newPerms[key] = value;
		return newPerms;
	};
	const labels = {
		canCreate: 'Create',
		canRead: 'Read',
		canUpdate: 'Update',
		canDelete: 'Delete',
	};

	const getNativeExpiryDate = () => spacetime(perms.expiresAt).toNativeDate();

	const handleExpiryDateChange: DateTimePickerProps['onChange'] = (date) => {
		if (date) {
			const newPerms = _.cloneDeep(perms);
			newPerms.expiresAt = spacetime(date).format('iso');
			onChange(newPerms);
		} else {
			throw new Error('invalid date entered through date and time picker');
		}
	};

	return (
		<>
			<ListItem button onClick={toggleExpanded}>
				<ListItemText primary={projectName} />
				<ListItemSecondaryAction>
					<IconButton onClick={toggleExpanded}>
						{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</IconButton>
					<IconButton onClick={onDelete} disabled={disabled} color="secondary">
						<DeleteIcon />
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
			<Collapse in={expanded}>
				<FormGroup>
					{(['canCreate', 'canUpdate', 'canDelete'] as const).map(
						(permType) => (
							<FormControlLabel
								key={permType}
								control={
									<Checkbox
										disabled={disabled || !perms.canRead}
										checked={perms[permType]}
										onChange={(e) =>
											onChange(makeNewPerms(permType, e.target.checked))
										}
									/>
								}
								label={labels[permType]}
							/>
						)
					)}
					<FormControlLabel
						control={
							<Checkbox
								disabled={disabled}
								checked={perms.canRead}
								onChange={(e) =>
									onChange(makeNewPerms('canRead', e.target.checked))
								}
							/>
						}
						label="Read"
					/>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<FormControlLabel
							label="Expiry date"
							disabled={disabled}
							control={
								<DateTimePicker
									ampm={false}
									value={getNativeExpiryDate()}
									onChange={handleExpiryDateChange}
									format="yyyy-MM-dd HH:mm"
								/>
							}
						/>
					</MuiPickersUtilsProvider>
				</FormGroup>
			</Collapse>
		</>
	);
};
