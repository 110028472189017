import appBar from './appBar';
import activeCompany from './activeCompany';
import activeProject from './activeProject';
import activeBuilding from './activeBuilding';
import activeFloor from './activeFloor';
import activeRoom from './activeRoom';

export default {
	Mutation: {
		...appBar,
		...activeCompany,
		...activeProject,
		...activeBuilding,
		...activeFloor,
		...activeRoom,
	},
};
