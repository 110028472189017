import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Tooltip,
} from '@material-ui/core';
import {
	DateTimePicker,
	DateTimePickerProps,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import spacetime from 'spacetime';
import { Article, UpdateArticleInput } from '../typings/graphql';
import { useActiveProject, useMe, useUpdateArticle } from 'hooks';
import { getProjectPermissions } from 'utils/helpers';
import { extractErrorMessage } from 'utils/errorMessages';
import { setDateOrThrow } from 'utils/setDateState';
import LocationSelector, { Location } from './LocationSelector';
import { QRCodeSVG } from 'qrcode.react';

type Props = {
	open: boolean;
	handleClose: () => void;
	article: Article;
	host: string;
};

const EditArticleDialog = ({
	open,
	handleClose,
	article,
	host,
}: Props): JSX.Element => {
	const [editing, setEditing] = useState(false);
	const [roomId, setRoomId] = useState(article.room.id);
	const [comment, setComment] = useState(article.comment);
	const [owner, setOwner] = useState(article.owner);
	const [placement, setPlacement] = useState(article.placement);
	const [condition, setCondition] = useState(article.condition);
	const getNativeDate = (date: string) => spacetime(date).toNativeDate();
	const [purchasedAt, setPurchasedAt] = useState(
		article.purchasedAt ? article.purchasedAt : new Date().toISOString()
	);
	const [lastInventoryDate, setLastInventoryDate] = useState(
		article.lastInventoryDate
			? getNativeDate(article.lastInventoryDate)
			: undefined
	);
	const [leaseStart, setLeaseStart] = useState(
		article.leaseStart ? article.leaseStart : new Date().toISOString()
	);
	const [leaseEnd, setLeaseEnd] = useState(
		article.leaseEnd ? article.leaseEnd : new Date().toISOString()
	);
	const baseURL =
		host === 'localhost:3000' ? 'http://' + host : 'https://' + host;

	const saveArticleChanges = useUpdateArticle();
	const me = useMe();
	const project = useActiveProject();

	let canEdit = false;
	if (me) {
		const projectPermissions = me?.admin
			? null
			: getProjectPermissions(me, project.id);
		canEdit = projectPermissions?.canUpdate || me?.admin;
	}

	const snackbar = useSnackbar();

	useEffect(() => {
		setComment(article.comment);
		setOwner(article.owner);
	}, [article]);

	const toggleEditing = () => {
		setEditing(!editing);
	};

	const closeDialog = () => {
		setEditing(false);
		handleClose();
	};

	const handleSaveClick = async () => {
		try {
			await saveArticleChanges(
				{
					article: {
						id: article.id,
						owner: owner,
						comment: comment,
						roomId: roomId,
						itemId: article.item.id,
						lastInventoryDate: new Date().toISOString(),
						purchasedAt: purchasedAt,
						inventoryDoneBy: me?.name,
						placement: placement,
						condition: condition,
						leaseStart: leaseStart,
						leaseEnd: leaseEnd,
					} as UpdateArticleInput,
				},
				article.room.id,
				article.item.id
			);
			setLastInventoryDate(new Date());
			snackbar.enqueueSnackbar(`Updated article properties`, {
				variant: 'success',
			});
		} catch (e) {
			const errorMessage = extractErrorMessage(e);
			snackbar.enqueueSnackbar(errorMessage, {
				variant: 'error',
			});
		} finally {
			setEditing(false);
			handleClose();
		}
	};

	const handleCommentInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setComment(event.target.value);
	};

	const handleOwnerInputchange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setOwner(event.target.value);
	};

	const handlePlacementChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		setPlacement(event.target.value as string);
	};

	const handleConditionChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		setCondition(event.target.value as string);
	};

	const handleRoomChange = (location: Location) => {
		if (location.room) setRoomId(location.room?.id);
	};

	const handlePurchasedAtDateChange: DateTimePickerProps['onChange'] = (
		date
	) => {
		setDateOrThrow(date, setPurchasedAt);
	};

	const handleLeaseStartDateChange: DateTimePickerProps['onChange'] = (
		date
	) => {
		setDateOrThrow(date, setLeaseStart);
	};

	const handleLeaseEndDateChange: DateTimePickerProps['onChange'] = (date) => {
		setDateOrThrow(date, setLeaseEnd);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Article details</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h3>Inventory</h3>
						{lastInventoryDate ? (
							<>
								<b>At: {lastInventoryDate.toLocaleString()}</b>
								<br />
								<b>By: {article.inventoryDoneBy}</b>
							</>
						) : (
							<>{'No inventory data found'}</>
						)}
					</Grid>
					<Grid item xs={6}>
						<h3>QR code</h3>
						<QRCodeSVG value={baseURL + '/inventory/' + article.id}></QRCodeSVG>
					</Grid>
					<Grid item xs={12}>
						<h3>Article</h3>
						<TextField
							id="outlined-multiline-static"
							disabled={!editing}
							label="Owner"
							value={owner ? owner : ''}
							variant="outlined"
							onChange={handleOwnerInputchange}
							size="small"
						/>
					</Grid>
					<Grid item xs={6}>
						<FormControl
							fullWidth
							variant="outlined"
							disabled={!editing}
							size="small"
						>
							<InputLabel>Condition</InputLabel>
							<Select
								defaultValue={''}
								value={condition ? condition : ''}
								onChange={handleConditionChange}
								label="Placement"
							>
								<MenuItem value={'New'}>New</MenuItem>
								<MenuItem value={'Damaged'}>Damaged</MenuItem>
								<MenuItem value={'Worn'}>Worn</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="outlined-multiline-static"
							disabled={!editing}
							label="Comment"
							multiline
							fullWidth
							rows={4}
							value={comment ? comment : ''}
							variant="outlined"
							onChange={handleCommentInputChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<h3>Location</h3>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FormControl
									fullWidth
									variant="outlined"
									disabled={!editing}
									size="small"
								>
									<InputLabel>Placement</InputLabel>
									<Select
										defaultValue={''}
										value={placement ? placement : ''}
										onChange={handlePlacementChange}
										label="Placement"
									>
										<MenuItem value={'Home'}>Home</MenuItem>
										<MenuItem value={'Office'}>Office</MenuItem>
										<MenuItem value={'Storage'}>Storage</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<LocationSelector
									disabled={!editing}
									onChange={handleRoomChange}
									initialLocation={{
										building: article.room.floor.building,
										floor: article.room.floor,
										room: article.room,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<h3>Admin</h3>
						<Grid container spacing={1}>
							<Grid item xs={12} md={4}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DateTimePicker
										disabled={!editing}
										label="Date of purchase"
										margin="normal"
										ampm={false}
										value={getNativeDate(purchasedAt)}
										onChange={handlePurchasedAtDateChange}
										format="yyyy-MM-dd HH:mm"
										fullWidth
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Grid item xs={12} md={4}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DateTimePicker
										disabled={!editing}
										label="Lease start"
										margin="normal"
										ampm={false}
										value={getNativeDate(leaseStart)}
										onChange={handleLeaseStartDateChange}
										format="yyyy-MM-dd HH:mm"
										fullWidth
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Grid item xs={12} md={4}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DateTimePicker
										disabled={!editing}
										label="Lease end"
										margin="normal"
										ampm={false}
										value={getNativeDate(leaseEnd)}
										onChange={handleLeaseEndDateChange}
										format="yyyy-MM-dd HH:mm"
										fullWidth
									/>
								</MuiPickersUtilsProvider>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<Box mt={4}>
				<DialogActions>
					<Tooltip
						title={canEdit ? 'Edit article' : 'Permission denied'}
						aria-label="create"
					>
						<span>
							<FormControlLabel
								control={
									<Switch
										disabled={!canEdit}
										checked={editing}
										onChange={toggleEditing}
									/>
								}
								label="Editing"
							/>
						</span>
					</Tooltip>
					<Button
						disabled={!editing}
						variant="outlined"
						color="secondary"
						onClick={handleSaveClick}
					>
						Save changes
					</Button>
					<Button variant="contained" color="primary" onClick={closeDialog}>
						Cancel
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default EditArticleDialog;
