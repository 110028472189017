import React, { createContext, useReducer } from 'react';

type InnerState = {
	category: string;
	subcategory: string;
	material: string;
	color: string;
};

type State = {
	state: InnerState;
	dispatch: React.Dispatch<any>;
};

export const CHANGE_CATEGORY_ACTION = 'change_category_action';
export const CHANGE_SUBCATEGORY_ACTION = 'change_subcategory_action';
export const CHANGE_MATERIAL_ACTION = 'change_material_action';
export const CHANGE_COLOR_ACTION = 'change_color_action';

type ActionType = {
	type:
		| 'change_category_action'
		| 'change_subcategory_action'
		| 'change_material_action'
		| 'change_color_action';
	payload: string;
};

const defaultState = {
	category: '',
	subcategory: '',
	material: '',
	color: '',
};

export const ItemFilterContext = createContext<State>({
	state: defaultState,
	dispatch: () => {
		throw new Error('Dispatch not implemented');
	},
});

type Props = {
	children: React.ReactNode;
};

export const ItemFilterProvider = ({ children }: Props): JSX.Element => {
	const { Provider } = ItemFilterContext;

	const [state, dispatch] = useReducer(
		(state: InnerState, action: ActionType) => {
			const { type } = action;
			switch (type) {
				case CHANGE_CATEGORY_ACTION:
					return {
						...state,
						category: action.payload,
						subcategory: '',
					};
				case CHANGE_SUBCATEGORY_ACTION:
					return {
						...state,
						subcategory: action.payload,
					};
				case CHANGE_MATERIAL_ACTION:
					return {
						...state,
						material: action.payload,
					};
				case CHANGE_COLOR_ACTION:
					return {
						...state,
						color: action.payload,
					};
				default: {
					return state;
				}
			}
		},
		defaultState
	);

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { default as FilterCategorySelect } from './FilterCategorySelect';
export { default as FilterSubcategorySelect } from './FilterSubcategorySelect';
export { default as FilterColorSelect } from './FilterColorSelect';
export { default as FilterMaterialSelect } from './FilterMaterialSelect';
export { default as FilteredItems } from './FilteredItems';
