import {
	ItemSubcategory,
	Mutation,
	MutationCreateItemArgs,
	Project,
	Color,
	Material,
	Image,
	Item,
} from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

const MUTATION = gql`
	mutation createItem($item: CreateItemInput!) {
		createItem(item: $item) {
			id
			name
			width
			height
			depth
			secondaryDepth
			diameter
			project {
				id
			}
			subcategory {
				id
			}
			material {
				id
			}
			color {
				id
			}
			image {
				id
				url
			}
		}
	}
`;

type ItemIds = {
	name: string;
	project: string;
	subcategory: string;
	material: string;
	color: string;
	image: string;
};

export default (): ((
	variables: MutationCreateItemArgs,
	previewImageUrl: string,
	ids: ItemIds
) => Promise<void>) => {
	const [addItem] = useMutation<
		Pick<Mutation, 'createItem'>,
		MutationCreateItemArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createItem,
	});

	return async (variables, previewImageUrl, ids): Promise<void> => {
		try {
			await addItem({
				variables,
				optimisticResponse: {
					createItem: {
						__typename: 'Item',
						id: variables.item.id!,
						name: variables.item.name,
						width: variables.item.width,
						depth: variables.item.depth,
						height: variables.item.height,
						secondaryDepth: variables.item.secondaryDepth,
						diameter: variables.item.diameter,
						project: {
							__typename: 'Project',
							id: variables.item.projectId || ids.project,
						} as Project,
						subcategory: {
							__typename: 'ItemSubcategory',
							id: variables.item.itemSubcategoryId || ids.subcategory,
						} as ItemSubcategory,
						material: {
							__typename: 'Material',
							id: variables.item.materialId || ids.material,
						} as Material,
						color: {
							__typename: 'Color',
							id: variables.item.colorId || ids.color,
						} as Color,
						image: {
							__typename: 'Image',
							id: variables.item.imageId || ids.image,
							url: previewImageUrl,
						} as Image,
					} as Item,
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
