import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import spacetime from 'spacetime';
import { updateHandlerByName } from '../graphql';
import {
	MutationCreateCompanyArgs,
	Mutation,
	Company,
} from '../typings/graphql';

const MUTATION = gql`
	mutation createCompany($company: CreateCompanyInput!) {
		createCompany(company: $company) {
			id
			name
			createdAt
		}
	}
`;

export default (): ((
	variables: MutationCreateCompanyArgs
) => Promise<void>) => {
	const [addCompany] = useMutation<
		Pick<Mutation, 'createCompany'>,
		MutationCreateCompanyArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createCompany,
	});

	return async (variables): Promise<void> => {
		try {
			await addCompany({
				variables,
				optimisticResponse: {
					createCompany: {
						__typename: 'Company',
						id: variables.company.id!,
						name: variables.company.name,
						createdAt: spacetime.now().format('iso-short').toString(),
					} as Company,
				},
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
