import { useQuery } from '@apollo/client/react/hooks/useQuery';
import React, { useContext } from 'react';
import { ItemFilterContext } from '.';
import { Item, Query } from '../../typings/graphql';

type RenderItems = ({ items }: { items: Item[] }) => React.ReactNode;
type Props = {
	children: RenderItems;
	projectId: string;
	itemQuery: any;
};

const FilteredItems = ({
	children,
	projectId,
	itemQuery,
}: Props): JSX.Element => {
	const { state } = useContext(ItemFilterContext);

	const { data } = useQuery<Pick<Query, 'items'>>(itemQuery, {
		variables: { input: { project: projectId } },
		returnPartialData: true,
		fetchPolicy: 'cache-and-network',
	});

	const items = data?.items || [];

	const { category, subcategory, material, color } = state;

	const filterByCategory = (item: Item): boolean =>
		category ? item.subcategory.category.id === category : true;
	const filterBySubcategory = (item: Item): boolean =>
		subcategory ? item.subcategory.id === subcategory : true;
	const filterByMaterial = (item: Item): boolean => {
		if (material && item.material) {
			return item.material.id === material;
		} else if (material) {
			return false;
		} else {
			return true;
		}
	};
	const filterByColor = (item: Item): boolean => {
		if (color && item.color) {
			return item.color.id === color;
		} else if (color) {
			return false;
		} else {
			return true;
		}
	};
	const filters = [
		filterByCategory,
		filterBySubcategory,
		filterByMaterial,
		filterByColor,
	];

	const filteredItems = filters.reduce(
		(data, filterFn) => data.filter(filterFn),
		items
	);

	filteredItems.sort((a: Item, b: Item) => a.id.localeCompare(b.id));

	return <>{children({ items: filteredItems })}</>;
};

export default FilteredItems;
