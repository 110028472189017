import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { useEffect } from 'react';

const MUTATION = gql`
	mutation setAppBarTitle($title: String!) {
		setAppBarTitle(title: $title) @client
	}
`;

export default (title: string): void => {
	const [setAppBar] = useMutation(MUTATION);

	useEffect(() => {
		setAppBar({ variables: { title } });
	}, [title, setAppBar]);
};
