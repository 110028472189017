import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import { Mutation, MutationDeleteBuildingArgs } from '../typings/graphql';

const MUTATION = gql`
	mutation deleteBuilding($id: ID!) {
		deleteBuilding(id: $id) {
			id
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'deleteBuilding'>>
) => {
	const [deleteBuilding] = useMutation<
		Pick<Mutation, 'deleteBuilding'>,
		MutationDeleteBuildingArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},
		update: updateHandler,
	});

	return (variables: MutationDeleteBuildingArgs) => {
		deleteBuilding({
			variables,
		});
	};
};
