import {
	Mutation,
	MutationCreateMaterialArgs,
	Material,
} from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { gql } from '@apollo/client/core';

const MUTATION = gql`
	mutation createMaterial($material: CreateMaterialInput!) {
		createMaterial(material: $material) {
			id
			name
		}
	}
`;

export default (): ((
	variables: MutationCreateMaterialArgs
) => Promise<void>) => {
	const [addMaterial] = useMutation<
		Pick<Mutation, 'createMaterial'>,
		MutationCreateMaterialArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createMaterial,
	});

	return async (variables): Promise<void> => {
		try {
			await addMaterial({
				variables,
				optimisticResponse: {
					createMaterial: {
						__typename: 'Material',
						id: variables.material.id!,
						name: variables.material.name,
					} as Material,
				},
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
