import {
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
	makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import EditArticleDialog from '../../components/EditArticleDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteArticleDialog from 'components/DeleteArticleDialog';
import { Article } from '../../typings/graphql';
import { useActiveProject, useDeleteArticle, useMe } from 'hooks';
import { updateHandlerByName } from '../../graphql';
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from 'utils/errorMessages';
import { getProjectPermissions } from 'utils/helpers';
import { useParams } from 'react-router-dom';
import { articleParams } from './ArticlesTable';

type Props = {
	article: Article;
	itemId: string;
};

const useStyles = makeStyles({
	'@keyframes flicker': {
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0.5,
		},
	},
	flicker: {
		animationName: '$flicker',
		animationDuration: '700ms',
		animationIterationCount: '4',
		animationDirection: 'alternate',
		animationTimingFunction: 'ease-in-out',
		backgroundColor: '#ddd',
	},
});

const ArticleRow = ({ article, itemId }: Props): JSX.Element => {
	const host = window.location.host;
	const params = useParams() as articleParams;
	const paramsArticleId = params.articleId ? params.articleId : '';
	const [editArticleDialogOpen, setEditArticleDialogOpen] = useState(false);
	const [deleteArticleDialogOpen, setDeleteArticleDialogOpen] = useState(false);
	const snackbar = useSnackbar();
	const project = useActiveProject();
	const me = useMe();
	const classes = useStyles();

	let canDelete = false;
	if (me) {
		const projectPermissions = me?.admin
			? null
			: getProjectPermissions(me, project.id);
		canDelete = projectPermissions?.canDelete || me?.admin;
	}

	const saveArticleDeletion = useDeleteArticle((cache, mutationResult) =>
		updateHandlerByName.deleteArticle(cache, mutationResult, itemId)
	);

	const handleEditArticleDialogClose = () => setEditArticleDialogOpen(false);
	const handleDeleteArticleDialogClose = () =>
		setDeleteArticleDialogOpen(false);
	const handleConfirmDeleteArticle = async () => {
		try {
			setDeleteArticleDialogOpen(false);
			await saveArticleDeletion({
				id: article.id,
			});
			snackbar.enqueueSnackbar(`Deleted article  ${article.id}`, {
				variant: 'success',
			});
		} catch (e) {
			const errorMessage = extractErrorMessage(e);
			snackbar.enqueueSnackbar(errorMessage, {
				variant: 'error',
			});
		} finally {
			setDeleteArticleDialogOpen(false);
		}
	};

	return (
		<>
			<TableRow
				className={article.id === paramsArticleId ? classes.flicker : ''}
			>
				<TableCell>{article?.id}</TableCell>
				<TableCell>{article.room?.floor?.building?.name}</TableCell>
				<TableCell>{article?.room?.name}</TableCell>
				<TableCell>{article?.placement}</TableCell>
				<TableCell>{article?.condition}</TableCell>
				<TableCell>
					{article.comment ? article.comment : 'No comment.'}
				</TableCell>
				<TableCell align="center">
					<IconButton onClick={() => setEditArticleDialogOpen(true)}>
						<EditIcon />
					</IconButton>
					<Tooltip
						title={canDelete ? 'Delete article' : 'Permission denied'}
						aria-label="create"
					>
						<span>
							<IconButton
								disabled={!canDelete}
								onClick={() => setDeleteArticleDialogOpen(true)}
							>
								<DeleteIcon />
							</IconButton>
						</span>
					</Tooltip>
				</TableCell>
			</TableRow>
			<EditArticleDialog
				open={editArticleDialogOpen}
				article={article}
				handleClose={handleEditArticleDialogClose}
				host={host}
			/>
			<DeleteArticleDialog
				open={deleteArticleDialogOpen}
				onClose={handleDeleteArticleDialogClose}
				onConfirm={handleConfirmDeleteArticle}
			/>
		</>
	);
};

export default ArticleRow;
