import { PutProjPermsArgsWithName } from 'components/EditEmploymentDialog';
import {
	Employment,
	Maybe,
	ProjectPermissions,
	User,
} from '../typings/graphql';

/**
 * Returns the employment of a possibly undefined user, and throws if the user is undefined or
 * if the number of employments for the user is not exactly 1.
 */
export const getEmployment = (u: Maybe<User>): Employment => {
	if (!u) {
		throw new Error('cannot get employment of undefined user');
	}
	if (u.employments.length !== 1) {
		throw new Error('expected exactly 1 employment for user ' + u.id);
	}
	return u.employments[0];
};

/**
 * Retrieves a list of the users unexpired permissions related to the project.
 * Implements {@link getEmployment()} to verify employment.
 */
export const getProjectPermissions = (
	user: Maybe<User>,
	projectId: string
): Maybe<ProjectPermissions> => {
	return getEmployment(user).projectPermissionsList.find(
		(permission) =>
			permission.project.id === projectId &&
			Date.parse(permission.expiresAt) > new Date().getTime()
	);
};

/**
 * Returns true if the user is an auth-admin or a global admin.
 * Implements {@link getEmployment()} to verify employment, unless the user is a global admin.
 */

export const isAuthAdmin = (user: Maybe<User>): boolean => {
	return user?.admin ? true : getEmployment(user).authAdmin;
};

/**
 * Returns true if the user is an struct-admin or a global admin.
 * Implements {@link getEmployment()} to verify employment, unless the user is a global admin.
 */
export const isStructAdmin = (user: Maybe<User>): boolean => {
	return user?.admin ? true : getEmployment(user).structAdmin;
};

/**
 * Converts a ProjectPermissions object into an input for the putProjectPermissions mutation. Requires
 * the `project.id` and `employment.id` fields of the ProjectPermissions object to be correctly set.
 */
export const projPermsToPutInputWithName = (
	p: ProjectPermissions
): PutProjPermsArgsWithName => ({
	input: {
		canCreate: p.canCreate,
		canDelete: p.canDelete,
		canRead: p.canRead,
		canUpdate: p.canUpdate,
		employmentId: p.employment.id,
		expiresAt: p.expiresAt,
		projectId: p.project.id,
	},
	projectName: p.project.name,
});
