import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { ActionType } from '../store/types';
import { getOnline, setOnline } from '../store/online';
import useEventListener from './useEventListener';

export default (): boolean => {
	const dispatch = useDispatch<Dispatch<ActionType>>();

	useEventListener('online', () => dispatch(setOnline(true)));
	useEventListener('offline', () => dispatch(setOnline(false)));

	return useSelector(getOnline);
};
