import { Popover } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import nameSort from '../utils/nameSort';
import { v4 as uuid } from 'uuid';
import { CellOrder, TableItem } from '../elements/SimpleTable';
import { useCreateMaterial, useDeleteMaterial, useMe } from '../hooks';
import CreateNameForm, { FormValues } from './CreateNameForm';
import EnhancedTable from './EnhancedTable';
import { Query } from '../typings/graphql';
import { extractErrorMessage } from '../utils/errorMessages';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';

export const QUERY = gql`
	query materials {
		materials {
			id
			name
		}
	}
`;

const cellOrder: CellOrder[] = [
	{
		align: 'left',
		name: 'Material',
		itemName: 'name',
	},
];

type Props = {
	onSelection?: (item: TableItem) => void;
};

const CategoriesTable = ({ onSelection }: Props): JSX.Element => {
	const createMaterial = useCreateMaterial();
	const deleteMaterial = useDeleteMaterial();
	const me = useMe();
	const { enqueueSnackbar } = useSnackbar();

	const { data, refetch } = useQuery<Pick<Query, 'materials'>>(QUERY);

	const materials = data?.materials;

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleTriggerPopover = (
		event: React.MouseEvent<HTMLButtonElement>
	): void => setAnchorEl(event.currentTarget);

	const handlePopoverClose = (): void => setAnchorEl(null);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const variables = {
			material: {
				id: uuid(),
				name: values.name,
			},
		};
		try {
			await createMaterial(variables);
			enqueueSnackbar(`Successfully created material: ${values.name}`, {
				variant: 'success',
			});
		} catch (error) {
			const message = extractErrorMessage(error);
			enqueueSnackbar(message, { variant: 'error' });
		}
	};

	const handleDelete = async (item: TableItem): Promise<void> => {
		try {
			await deleteMaterial({ id: item.id });
			enqueueSnackbar(`Successfully deleted material: ${item.name}`, {
				variant: 'info',
			});
		} catch (error) {
			const message = extractErrorMessage(error);
			enqueueSnackbar(message, { variant: 'error' });
		}
	};

	const handleListItemClick = (item: TableItem): void => {
		onSelection && onSelection(item);
	};

	const sorted = materials?.slice(0).sort(nameSort);

	const open = Boolean(anchorEl);
	const id = open ? 'create-material-popover' : undefined;

	return (
		<>
			<EnhancedTable
				empty="No materials exists yet"
				reload={refetch}
				items={sorted ? (sorted as TableItem[]) : []}
				searchPlaceholder="Search for material name"
				order={cellOrder}
				onClick={handleTriggerPopover}
				onListItemClick={handleListItemClick}
				onDeleteClick={handleDelete}
				canCreate={me?.admin}
				canDelete={me?.admin}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<CreateNameForm
					label="Material Name"
					onSubmit={handleSubmit}
					onClose={handlePopoverClose}
				/>
			</Popover>
		</>
	);
};

export default CategoriesTable;
