import {
	createStyles,
	FormControl,
	Grid,
	makeStyles,
	TextField,
	Theme,
	Typography,
	Button,
	CardActionArea,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { useScreenSizeIndicator } from 'hooks';
import React, { useState } from 'react';
import Image from 'react-image';
import * as yup from 'yup';
import ImageEnlargeDialog from './ImageEnlargeDialog';
import { Query } from '../typings/graphql';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';

export type FormValues = {
	comment: string;
};

type Props = {
	item: string;
	onClose: () => void;
	onSubmit: (values: FormValues) => Promise<void>;
};

type FormProps = {
	initialComment?: string;
};

const useStyles = makeStyles<Theme, { screenSizeIndicator: string }>(
	(theme: Theme) =>
		createStyles({
			formHeader: {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
			imageButton: {
				width: '100%',
				height: '100%',
				backgroundColor: theme.palette.grey[400],
			},
			imageSrc: {
				height: '100%',
				width: '100%',
				maxHeight: 250,
				maxWidth: 300,
				objectFit: 'cover',
			},
			textRight: {
				textAlign: 'right',
			},
			previewImage: {
				textAlign: 'center',
				maxWidth: 300,
				minHeight: 200,
				maxHeight: 250,
			},
			importButton: {
				textAlign: 'right',
			},
		})
);

const ITEM_QUERY = gql`
	query getItem($id: ID!) {
		item(id: $id) {
			id
			width
			height
			depth
			secondaryDepth
			diameter
			image {
				id
				url
			}
			subcategory {
				id
				name
				category {
					id
					name
				}
			}
		}
	}
`;

type Row = {
	title?: string;
	content: string;
	subtitle?: boolean;
	noGutters?: boolean;
};

const InnerForm = (
	props: FormikProps<FormValues> & Props
): JSX.Element | null => {
	const screenSizeIndicator = useScreenSizeIndicator();
	const classes = useStyles({ screenSizeIndicator });
	const [imageEnlargeOpen, setImageEnlargeOpen] = useState(false);

	const { data } = useQuery<Pick<Query, 'item'>>(ITEM_QUERY, {
		variables: { id: props.item },
		skip: !props.item,
		returnPartialData: true,
	});

	if (!data?.item) return null;

	const { item } = data;

	const { values, errors, touched, handleChange, handleSubmit, isSubmitting } =
		props;

	const rows: Row[] = item
		? [
				{
					title: 'Category',
					content: item.subcategory.category.name || '',
				},
				{
					title: 'Subcategory',
					content: item.subcategory.name || '',
				},
		  ]
		: [];
	if (item?.material)
		rows.push({ title: 'Material:', content: item.material.name });
	if (item?.color) rows.push({ title: 'Color:', content: item.color.name });
	if (item?.width) {
		rows.push({
			title: 'Dimensions:',
			content: `${item.width}x${item.height}x${item.depth}`,
			noGutters: true,
		});
		rows.push({
			title: '',
			content: 'WxHxD',
			subtitle: true,
		});
	}
	if (item?.secondaryDepth)
		rows.push({
			title: 'Secondary Depth:',
			content: `${item.secondaryDepth}`,
		});
	if (item?.diameter)
		rows.push({
			title: 'Diameter:',
			content: `${item.diameter}`,
		});

	const Row = ({ title, content, subtitle, noGutters }: Row): JSX.Element => {
		return (
			<>
				<Grid item xs={5} style={{ paddingTop: 0, paddingBottom: 0 }}>
					<Typography
						gutterBottom={!noGutters}
						variant="body2"
						color="textPrimary"
						component="p"
					>
						{title}
					</Typography>
				</Grid>
				<Grid item xs={7} style={{ paddingTop: 0, paddingBottom: 0 }}>
					<Typography
						gutterBottom={!noGutters}
						variant={subtitle ? 'caption' : 'body2'}
						color={subtitle ? 'textSecondary' : 'textPrimary'}
						component="p"
						className={classes.textRight}
					>
						{content}
					</Typography>
				</Grid>
			</>
		);
	};

	return (
		<form onSubmit={handleSubmit}>
			{item && (
				<>
					<Typography className={classes.formHeader} component="h3">
						Chosen Item
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<Grid container spacing={2}>
								{rows.map(({ title, content, subtitle, noGutters }) => (
									<Row
										key={`${item.id}-${title}-${content}`}
										title={title}
										content={content}
										subtitle={subtitle}
										noGutters={noGutters}
									/>
								))}
							</Grid>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CardActionArea
								onClick={(): void => setImageEnlargeOpen(true)}
								className={classes.imageButton}
								style={{ width: 'unset', margin: 'auto' }}
							>
								<Image src={item.image.url} className={classes.imageSrc} />
							</CardActionArea>
							<ImageEnlargeDialog
								open={imageEnlargeOpen}
								onClose={(): void => setImageEnlargeOpen(false)}
								imageUrl={item.image.url}
							/>
						</Grid>
					</Grid>

					<Typography className={classes.formHeader} component="h3">
						Add comment
					</Typography>

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormControl
								variant="outlined"
								className={classes.formControl}
								fullWidth
							>
								<TextField
									id="choose-comment-input"
									label="Comment"
									name="comment"
									multiline
									variant="outlined"
									value={values.comment}
									onChange={handleChange}
									error={!!errors.comment && touched.comment}
									helperText={errors.comment}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} className={classes.importButton}>
							<Button
								color="primary"
								variant="contained"
								disabled={isSubmitting}
								type="submit"
							>
								Import
							</Button>
						</Grid>
					</Grid>
				</>
			)}
		</form>
	);
};

const ImportItemForm = withFormik<FormProps & Props, FormValues>({
	mapPropsToValues: (props) => ({
		comment: props.initialComment || '',
	}),

	validationSchema: yup.object().shape({
		comment: yup.string().max(255, 'Max 255 characters').trim(),
	}),

	async handleSubmit(values, { props }) {
		return props.onSubmit(values);
	},
})(InnerForm);

export default ImportItemForm;
