import React from 'react';
import { useActiveRoom } from 'hooks';
import { List, ListSubheader, Divider } from '@material-ui/core';
import ListItemLink from 'elements/ListItemLink';

const ActiveRoomList = (): JSX.Element | null => {
	const room = useActiveRoom();

	if (!room.id) return null;

	return (
		<>
			<List
				component="nav"
				subheader={
					<ListSubheader component="div">Room - {room.name}</ListSubheader>
				}
			>
				<ListItemLink
					leftTextPad={true}
					primary="Articles"
					to="/room"
				></ListItemLink>
			</List>
			<Divider />
		</>
	);
};

export default ActiveRoomList;
