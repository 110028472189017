import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const ARTICLESBYROOM_QUERY = gql`
	query getArticlesByRoom($input: QueryArticlesInput!) {
		articles(input: $input) {
			id
			comment
			item {
				id
			}
			room {
				id
				name
			}
		}
	}
`;

const ARTICLE_COLLECTIONS = gql`
	query articleCollections($query: QueryArticleCollectionsInput!) {
		articleCollections(query: $query) {
			id
			count
			comment
			item {
				id
			}
		}
	}
`;

export const handleCreateArticleUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createArticle'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createArticle } = data;

	if (!createArticle) return;

	try {
		const cacheData = cache.readQuery<Pick<Query, 'articleCollections'>>({
			query: ARTICLE_COLLECTIONS,
			variables: { query: { roomId: createArticle.room.id } },
		});
		if (cacheData === null) return;

		const itemInRoom = cacheData.articleCollections.find((collection) => {
			if (createArticle.comment) {
				return (
					collection.id === `${createArticle.item.id}@${createArticle.comment}`
				);
			} else {
				return collection.id === createArticle.item.id;
			}
		});

		if (itemInRoom) {
			const articleCollections = cacheData.articleCollections.map(
				(collection) => {
					if (
						(createArticle.comment &&
							collection.id ===
								`${createArticle.item.id}@${createArticle.comment}`) ||
						(!createArticle.comment && collection.id === createArticle.item.id)
					) {
						collection.count++;
						return collection;
					} else {
						return collection;
					}
				}
			);

			cache.writeQuery({
				query: ARTICLE_COLLECTIONS,
				variables: { query: { roomId: createArticle.room.id } },
				data: { articleCollections },
			});
		} else {
			const id = createArticle.comment
				? `${createArticle.item.id}@${createArticle.comment}`
				: createArticle.item.id;
			const freshCollection = {
				__typename: 'ArticleCollection',
				id,
				count: 1,
				comment: createArticle.comment || null,
				item: {
					__typename: 'Item',
					id: createArticle.item.id,
				},
			};
			const articleCollections = [
				...cacheData.articleCollections,
				freshCollection,
			];

			cache.writeQuery({
				query: ARTICLE_COLLECTIONS,
				variables: { query: { roomId: createArticle.room.id } },
				data: { articleCollections },
			});
		}

		// const articles = [...cacheData.articles, createArticle];
	} catch (e) {
		// We optimistically write data since nothing exists
		const id = createArticle.comment
			? `${createArticle.item.id}@${createArticle.comment}`
			: createArticle.item.id;
		const freshCollection = {
			__typename: 'ArticleCollection',
			id,
			count: 1,
			comment: createArticle.comment || null,
			item: {
				__typename: 'Item',
				id: createArticle.item.id,
			},
		};
		cache.writeQuery({
			query: ARTICLESBYROOM_QUERY,
			variables: { query: { roomId: createArticle.room.id } },
			data: { articleCollections: [freshCollection] },
		});
	}
};

export const handleUpdateArticleInRoom: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'updateArticle'>
> = (cache, { data }, roomId): void => {
	if (data === undefined || data === null) return;

	const { updateArticle } = data;

	if (!updateArticle) return;

	const cacheData = cache.readQuery<Pick<Query, 'articles'>>({
		query: ARTICLESBYROOM_QUERY,
		variables: { input: { room: roomId } },
	});

	let articles = cacheData?.articles.filter(
		(article) => article.id !== updateArticle.id
	);
	if (articles) {
		articles.push(updateArticle);
	} else {
		articles = [updateArticle];
	}

	cache.writeQuery({
		query: ARTICLESBYROOM_QUERY,
		variables: { input: { room: roomId } },
		data: { articles },
	});
};

export const handleDeleteArticle: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteArticle'>
> = (cache, { data }, itemId): void => {
	if (data === undefined || data === null) {
		return;
	}

	const articleId = data.deleteArticle?.id;
	const normalizedArticleId = cache.identify({
		id: articleId,
		__typename: 'Article',
	});
	cache.evict({ id: normalizedArticleId });
	cache.gc();

	//update the corresponding item, so the quantity column is correct
	const normalizedItemId = cache.identify({ id: itemId, __typename: 'Item' });
	cache.modify({
		id: normalizedItemId,
		fields: {
			numberOfArticles: (numberOfArticles) => numberOfArticles - 1,
		},
	});
};

export const handleDeleteArticleInRoom: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteArticle'>
> = (cache, { data }, roomId): void => {
	if (data === undefined || data === null) return;

	const { deleteArticle } = data;

	if (!deleteArticle) return;

	const cacheData = cache.readQuery<Pick<Query, 'articles'>>({
		query: ARTICLESBYROOM_QUERY,
		variables: { input: { room: roomId } },
	});

	const articles = cacheData?.articles.filter(
		(article) => article.id !== deleteArticle.id
	);

	cache.writeQuery({
		query: ARTICLESBYROOM_QUERY,
		variables: { input: { room: roomId } },
		data: { articles },
	});
};

export const handleDeleteArticlesInRoom: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteArticles'>
> = (cache, { data }): void => {
	if (data === undefined || data === null) return;

	if (data.deleteArticles) {
		data.deleteArticles.forEach((article) => {
			const articleIdToDelete = article?.id;
			const normalizedArticleId = cache.identify({
				id: articleIdToDelete,
				__typename: 'Article',
			});

			cache.evict({ id: normalizedArticleId });
			cache.gc();
		});
	}
};
