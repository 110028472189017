import React, { MouseEvent, useState } from 'react';
import { Button, Typography, Popover } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Arrow from '../elements/Arrow';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			color: theme.palette.common.white,
		},
		label: {
			display: 'flex',
			flexDirection: 'column',
		},
	})
);

type LocationButtonProps = {
	label: string;
	location?: string;
	arrow?: boolean;
	renderTable: (close: () => void) => JSX.Element;
	disabled?: boolean;
};

const maxLength = 20;

const LocationButton = ({
	label,
	location,
	arrow = false,
	renderTable,
	disabled,
}: LocationButtonProps): JSX.Element => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: MouseEvent<HTMLButtonElement>): void =>
		setAnchorEl(event.currentTarget);

	const handleClose = (): void => setAnchorEl(null);

	const shorten = (str: string): string => {
		if (str.length > maxLength) return `${str.slice(0, maxLength)}...`;
		return str;
	};

	const name = location ? shorten(location) : '';
	const open = Boolean(anchorEl);

	return (
		<>
			<Button
				classes={{ root: classes.root, label: classes.label }}
				onClick={handleClick}
				disabled={disabled}
				style={{ color: 'white' }}
			>
				<Typography variant="caption">{label}</Typography>
				{name}
			</Button>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				{renderTable(handleClose)}
			</Popover>
			{arrow && <Arrow />}
		</>
	);
};

export default LocationButton;
