import { Box, Button, Dialog, DialogTitle } from '@material-ui/core';
import React from 'react';

type Props = {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
};

const DeleteArticleDialog = (props: Props): JSX.Element => {
	const { open, onClose, onConfirm } = props;

	const handleConfirm = () => {
		onConfirm();
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="add-comment-to-article-dialog"
		>
			<DialogTitle>Really delete this article?</DialogTitle>
			<Box my={2} mx={'auto'}>
				<Button
					variant="contained"
					style={{ margin: '5px' }}
					color="secondary"
					onClick={handleConfirm}
				>
					Yes
				</Button>
				<Button
					variant="outlined"
					style={{ margin: '5px' }}
					color="primary"
					onClick={handleCancel}
				>
					Cancel
				</Button>
			</Box>
		</Dialog>
	);
};

export default DeleteArticleDialog;
