import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import CompaniesTable from './CompaniesTable';
import LocationButton from 'components/LocationButton';
import {
	useActiveCompany,
	useActiveProject,
	useActiveBuilding,
	useActiveFloor,
	useActiveRoom,
	useMe,
	useSetActiveCompany,
} from 'hooks';
import ProjectsTable from './ProjectsTable';
import BuildingsTable from './BuildingsTable';
import FloorsTable from './FloorsTable';
import RoomsTable from './RoomsTable';

const useStyles = makeStyles(() =>
	createStyles({
		location: {
			display: 'flex',
			marginLeft: 'auto',
		},
	})
);

const LocationList = (): JSX.Element => {
	const me = useMe();
	const setActiveCompany = useSetActiveCompany();
	const classes = useStyles();
	const company = useActiveCompany();
	const project = useActiveProject();
	const building = useActiveBuilding();
	const floor = useActiveFloor();
	const room = useActiveRoom();

	if (!me?.admin && me?.employments.length === 1 && !company.id) {
		setActiveCompany(me?.employments[0].company);
	}
	return (
		<div className={classes.location}>
			<LocationButton
				arrow
				label="Company"
				location={company?.name}
				renderTable={(close): JSX.Element => (
					<CompaniesTable onSelection={close} />
				)}
				disabled={!me?.admin}
			/>
			{company.id && (
				<LocationButton
					arrow
					label="City"
					location={project?.name}
					renderTable={(close): JSX.Element => (
						<ProjectsTable onSelection={close} />
					)}
				/>
			)}
			{project.id && (
				<LocationButton
					arrow
					label="Building"
					location={building?.name}
					renderTable={(close): JSX.Element => (
						<BuildingsTable onSelection={close} />
					)}
				/>
			)}
			{building.id && (
				<LocationButton
					arrow
					label="Floor"
					location={floor?.name}
					renderTable={(close): JSX.Element => (
						<FloorsTable onSelection={close} />
					)}
				/>
			)}
			{floor.id && (
				<LocationButton
					label="Room"
					location={room?.name}
					renderTable={(close): JSX.Element => (
						<RoomsTable onSelection={close} />
					)}
				/>
			)}
		</div>
	);
};

export default LocationList;
