import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const QUERY = gql`
	query colors {
		colors {
			id
			name
		}
	}
`;

export const handleCreateColorUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createColor'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createColor } = data;

	if (!createColor) return;

	const cacheData = cache.readQuery<Pick<Query, 'colors'>>({
		query: QUERY,
	});
	if (cacheData === null) return;

	const colors = [...cacheData.colors];

	colors.push(createColor);

	cache.writeQuery({
		query: QUERY,
		data: { colors },
	});
};

export const handleDeleteColorUpdate: MutationUpdaterFn<
	Pick<Mutation, 'deleteColor'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { deleteColor } = data;

	if (!deleteColor) return;

	const cacheData = cache.readQuery<Pick<Query, 'colors'>>({
		query: QUERY,
	});
	if (cacheData === null) return;

	const colors = cacheData.colors.filter(
		(material) => material.id !== deleteColor.id
	);

	cache.writeQuery({ query: QUERY, data: { colors } });
};
