import { Box, Button, Dialog, DialogTitle } from '@material-ui/core';
import useDeleteUser from 'hooks/useDeleteUser';
import { useSnackbar } from 'notistack';
import React from 'react';
import { extractErrorMessage } from 'utils/errorMessages';
import { updateHandlerByName } from '../graphql';
import { MutationDeleteUserArgs } from '../typings/graphql';

type Props = {
	open: boolean;
	handleClose: () => void;
	userId: string;
	userName: string;
	companyId: string;
};

const DeleteEmploymentDialog = ({
	open,
	userId,
	userName,
	companyId,
	handleClose,
}: Props): JSX.Element => {
	const saveUserDeletion = useDeleteUser((cache, mutationResult) =>
		updateHandlerByName.deleteUser(cache, mutationResult, companyId)
	);

	const snackbar = useSnackbar();

	const handleDelete = async () => {
		const userToDelete: MutationDeleteUserArgs = {
			id: userId,
		};

		try {
			await saveUserDeletion({
				id: userToDelete.id,
			});
			handleClose();
			snackbar.enqueueSnackbar(`Deleted ${userName}`, {
				variant: 'success',
			});
		} catch (e) {
			const errorMessage = extractErrorMessage(e);
			snackbar.enqueueSnackbar(errorMessage, {
				variant: 'error',
			});
		} finally {
			handleClose();
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Really delete {userName}?</DialogTitle>
			<Box my={2} mx={'auto'}>
				<Button
					variant="contained"
					style={{ margin: '5px' }}
					color="secondary"
					onClick={handleDelete}
				>
					Yes
				</Button>
				<Button
					variant="outlined"
					style={{ margin: '5px' }}
					color="primary"
					onClick={handleClose}
				>
					Cancel
				</Button>
			</Box>
		</Dialog>
	);
};

export default DeleteEmploymentDialog;
