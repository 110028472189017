import { TableItem } from 'elements/SimpleTable';
import { Query } from '../typings/graphql';

type Props = {
	companyToDelete?: TableItem | undefined;
	projectToDelete?: TableItem | undefined;
	buildingToDelete?: TableItem | undefined;
	floorToDelete?: TableItem | undefined;
	roomToDelete?: TableItem | undefined;
	locationsToDelete?: Pick<Query, 'locations'> | undefined;
};

const useLocationWarning = ({
	companyToDelete,
	projectToDelete,
	buildingToDelete,
	floorToDelete,
	roomToDelete,
	locationsToDelete,
}: Props) => {
	let warning;

	if (companyToDelete) {
		warning = `Are you sure that you want to delete company ${companyToDelete.name} with 
		${locationsToDelete?.locations.projects?.length} cities,
		${locationsToDelete?.locations.buildings?.length} buildings, 
		${locationsToDelete?.locations.floors?.length} floors and 
		${locationsToDelete?.locations.rooms?.length} rooms containing 
		${locationsToDelete?.locations.articles?.length} articles?`;
	} else if (projectToDelete) {
		warning = `Are you sure that you want to delete city ${projectToDelete.name} with 
		${locationsToDelete?.locations.buildings?.length} buildings, 
		${locationsToDelete?.locations.floors?.length} floors and 
		${locationsToDelete?.locations.rooms?.length} rooms containing 
		${locationsToDelete?.locations.articles?.length} articles?`;
	} else if (buildingToDelete) {
		warning = `Are you sure that you want to delete building ${buildingToDelete.name} with 
		${locationsToDelete?.locations.floors?.length} floors and 
		${locationsToDelete?.locations.rooms?.length} rooms containing 
		${locationsToDelete?.locations.articles?.length} articles?`;
	} else if (floorToDelete) {
		warning = `Are you sure that you want to delete floor ${floorToDelete?.name} and
		${locationsToDelete?.locations.rooms?.length} rooms containing 
		${locationsToDelete?.locations.articles?.length} articles?`;
	} else if (roomToDelete) {
		warning = `Are you sure that you want to delete room ${roomToDelete.name} containing
		${locationsToDelete?.locations.articles?.length} articles?`;
	}

	return warning;
};

export default useLocationWarning;
