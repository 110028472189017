import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: 'fixed',
			display: 'flex',
			flexDirection: 'column-reverse',
			bottom: theme.spacing(2),
			right: theme.spacing(2),
			alignItems: 'center',
			'& > button:nth-child(n+2)': {
				marginBottom: theme.spacing(1),
			},
		},
	})
);

type Props = {
	children: React.ReactNode;
};

const FabsContainer = (props: Props): JSX.Element => {
	const classes = useStyles();

	return <div className={classes.root}>{props.children}</div>;
};

export default FabsContainer;
