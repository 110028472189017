import { useEffect, useState } from 'react';

export default (): number[] => {
	const [width, setWidth] = useState(5000);
	const [height, setHeight] = useState(5000);

	useEffect(() => {
		const handleResize = (): void => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return (): void => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return [width, height];
};
