import React, { useEffect } from 'react';
import Content from '../../components/Content';
import {
	useActiveProject,
	useMe,
	useUpdateAppBar,
	useSetActiveProject,
	useSetActiveCompany,
} from '../../hooks';
import ItemsTableWrapper from './ItemsTableWrapper';
import { getProjectPermissions } from 'utils/helpers';
import { articleParams } from './ArticlesTable';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Query } from '../../typings/graphql';

export const QUERY = gql`
	query getProjectsByArticle($input: QueryProjectInput!) {
		project(input: $input) {
			id
			name
			company {
				id
				name
			}
		}
	}
`;

const InventoryView = (): JSX.Element => {
	const params = useParams() as articleParams;
	const paramsArticleId = params.articleId ? params.articleId : '';
	const project = useActiveProject();
	const activeProjectExists = !!project.id;
	const setActiveProject = useSetActiveProject();
	const setActiveCompany = useSetActiveCompany();
	const me = useMe();
	useUpdateAppBar(
		activeProjectExists ? 'Search items for ' + project.name : ''
	);

	const { data: projectByArticle } = useQuery<Pick<Query, 'project'>>(QUERY, {
		variables: { input: { article: paramsArticleId } },
		skip: !paramsArticleId,
	});

	let canView = false;
	if (me) {
		const projectPermissions = me?.admin
			? null
			: getProjectPermissions(me, project.id);
		canView = projectPermissions?.canRead || me?.admin;
	}

	useEffect(() => {
		if (projectByArticle && projectByArticle?.project) {
			setActiveCompany({
				id: projectByArticle.project.company.id,
				name: projectByArticle.project.company.name,
			});
			setActiveProject({
				id: projectByArticle.project.id,
				name: projectByArticle.project.name,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectByArticle]);

	return (
		<>
			<Content>
				{activeProjectExists ? (
					canView ? (
						<ItemsTableWrapper />
					) : (
						<Box
							justifyContent="center"
							display="flex"
							alignItems="center"
							marginTop="25%"
						>
							Permission denied. You do not have read permissions for this city.
						</Box>
					)
				) : (
					<Box
						justifyContent="center"
						display="flex"
						alignItems="center"
						marginTop="25%"
					>
						Please select a city.
					</Box>
				)}
			</Content>
		</>
	);
};

export default InventoryView;
