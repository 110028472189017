import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	makeStyles,
	Theme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useActiveRoom, useCreateArticle } from '../hooks';
import ImportItemForm, { FormValues } from './ImportItemForm';
import ImportItemGrid from './ImportItemGrid';

type Props = {
	open: boolean;
	onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			height: '70vh',
		},
		container: {
			height: '100%',
		},
		containerColumn: {
			width: '100%',
			height: '100%',
		},
		actions: {
			margin: theme.spacing(2),
		},
	})
);

const ImportItemDialog = ({ open, onClose }: Props): JSX.Element | null => {
	const room = useActiveRoom();
	const createArticle = useCreateArticle();
	const classes = useStyles();
	const [selectedItem, setSelectedItem] = useState<string>('');

	const handleClick = (item: string): void => setSelectedItem(item);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const articleVariables = {
			id: uuid(),
			comment: values.comment || undefined,
			roomId: room.id,
			itemId: selectedItem,
		};

		createArticle({ article: articleVariables });
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="import-item-dialog"
			style={{ overflow: 'hidden' }}
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle id="import-item-dialog">Import article to room</DialogTitle>
			<DialogContent className={classes.content}>
				<Grid container spacing={2} className={classes.container}>
					<Grid item xs={12} sm={12} md={6} className={classes.containerColumn}>
						<ImportItemGrid onClick={handleClick} />
					</Grid>
					<Grid item xs={12} sm={12} md={6} className={classes.containerColumn}>
						<ImportItemForm
							item={selectedItem}
							onClose={onClose}
							onSubmit={handleSubmit}
						/>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions className={classes.actions}>
				<Button color="primary" onClick={onClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImportItemDialog;
