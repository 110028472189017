import {
	gql,
	MutationUpdaterFn,
	useApolloClient,
	useMutation,
} from '@apollo/client';
import useActiveRoom from './useActiveRoom';
import {
	Mutation,
	MutationRemoveCommentFromArticleInCollectionArgs,
	Query,
	ArticleCollection,
	Item,
} from '../typings/graphql';

const MUTATION = gql`
	mutation RemoveCommentFromArticleInCollection(
		$query: QueryArticleCollectionInput!
	) {
		removeCommentFromArticleInCollection(query: $query) {
			id
			comment
			count
			item {
				id
			}
		}
	}
`;

const LOCAL_QUERY = gql`
	query articleCollections($query: QueryArticleCollectionsInput!) {
		articleCollections(query: $query) {
			id
			count
			comment
			item {
				id
				height
				width
				depth
				image {
					id
					url
				}
				color {
					id
					name
				}
				material {
					id
					name
				}
				subcategory {
					id
					name
					category {
						id
						name
					}
				}
			}
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<
		Pick<Mutation, 'removeCommentFromArticleInCollection'>
	>
): ((
	variables: MutationRemoveCommentFromArticleInCollectionArgs
) => Promise<void>) => {
	const [removeCommentFromArticleInCollection] = useMutation<
		Pick<Mutation, 'removeCommentFromArticleInCollection'>,
		MutationRemoveCommentFromArticleInCollectionArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandler,
	});

	const room = useActiveRoom();
	const client = useApolloClient();
	let cacheData: Pick<Query, 'articleCollections'> | null;

	try {
		cacheData = client.readQuery<Pick<Query, 'articleCollections'>>({
			query: LOCAL_QUERY,
			variables: { query: { roomId: room.id } },
		});
	} catch (error) {
		// fail silently
		// This needs to be done because this will try to read the cache
		// before the query has ever been executed and so will throw and error because of that.
		// It will however fix itself since the query is executed at the same time as this code
		// is reached. Not pretty, but hey, such is life.
	}

	return async (variables): Promise<void> => {
		try {
			const { query } = variables;

			const cloned = cacheData?.articleCollections.slice(0);

			if (!cloned) return;

			// Check if collection with correct item, and comment exists
			const collectionExists = cloned.find(
				(collection) => collection.id === `${query.itemId}@${query.comment}`
			);

			if (collectionExists) {
				collectionExists.count--;
			}

			const nextCollection = cloned?.find(
				(collection) => collection.id === query.itemId
			);

			if (nextCollection) {
				nextCollection.count++;
			} else {
				const collection = {
					__typename: 'ArticleCollection',
					id: `${query.itemId}`,
					comment: query.comment,
					count: 1,
					item: {
						__typename: 'Item',
						id: query.itemId,
					} as Item,
				} as ArticleCollection;

				cloned.push(collection as ArticleCollection);
			}

			await removeCommentFromArticleInCollection({
				variables,
				optimisticResponse: {
					removeCommentFromArticleInCollection: cloned,
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
