import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
	Dialog,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	createStyles,
	makeStyles,
	Grid,
	Box,
} from '@material-ui/core';
import { QRCodeSVG } from 'qrcode.react';
import { ArticleCollection } from '../typings/graphql';

const useStyles = makeStyles(() =>
	createStyles({
		printOne: {
			display: 'flex',
			justifyContent: 'center',
			['@media print']: {
				margin: '1em',
				display: 'block',
			},
		},
		printAllWrapper: {
			display: 'none',
			['@media print']: {
				margin: '1em',
				display: 'grid',
				gridTemplateColumns: 'repeat(3, 1fr)',
				gridColumnGap: '2mm',
				gridRowGap: '2mm',
				'&>*:nth-child(24n)': {
					marginBottom: '10mm',
				},
			},
		},
		printContainer: {
			display: 'flex',
			width: '66mm',
			height: '34mm',
			padding: '1em',
			border: '1px dotted black',
		},
		flexItem: {
			width: '50%',
		},
		qrCode: {
			height: '90px',
			width: '90px',
		},
	})
);

type Props = {
	articleId?: string;
	itemId?: string;
	articles?: ArticleCollection[] | undefined;
	printAllArticles: boolean;
	open: boolean;
	onClose: () => void | undefined;
	host: string;
};

const PrintArticleDialog = (props: Props): JSX.Element => {
	const { open, onClose, articleId, itemId, articles, printAllArticles, host } =
		props;
	const printOneRef = useRef<HTMLParagraphElement>(null);
	const printAllRef = useRef<HTMLDivElement>(null);
	const classes = useStyles();
	const baseURL =
		host === 'localhost:3000' ? 'http://' + host : 'https://' + host;

	const printOneQrCode = useReactToPrint({
		content: () => printOneRef.current,
	});

	const printAllQrCodes = useReactToPrint({
		content: () => printAllRef.current,
	});

	const handlePrint = () => {
		if (printAllArticles) {
			printAllQrCodes();
		} else {
			printOneQrCode();
		}
	};

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="print-qr-code">
			<DialogTitle id="print-qr-code">
				{printAllArticles
					? 'Print all QR-codes for this room'
					: 'Print QR-code for this article'}
			</DialogTitle>
			<DialogContent>
				{printAllArticles ? (
					<Grid ref={printAllRef} className={classes.printAllWrapper}>
						{articles &&
							articles.map((article) => {
								return (
									<Box className={classes.printContainer} key={article.id}>
										<Box className={classes.flexItem}>
											<QRCodeSVG
												key={article.id}
												value={baseURL + '/inventory/' + article.id}
												className={classes.qrCode}
											/>
										</Box>
										<Box className={classes.flexItem}>
											<small>Item ID: {article.item.id}</small>
											<p>Zon:</p>
										</Box>
									</Box>
								);
							})}
					</Grid>
				) : (
					<div ref={printOneRef} className={classes.printOne}>
						<Box className={classes.printContainer}>
							<Box className={classes.flexItem}>
								<QRCodeSVG
									value={baseURL + '/inventory/' + articleId}
									className={classes.qrCode}
								/>
							</Box>
							<Box className={classes.flexItem}>
								<small>Item ID: {itemId}</small>
								<p>Zon:</p>
							</Box>
						</Box>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handlePrint} color="primary" variant="contained">
					Print
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PrintArticleDialog;
