import {
	Button,
	createStyles,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PublishIcon from '@material-ui/icons/Publish';
import { FormikProps, withFormik } from 'formik';
import _ from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import * as yup from 'yup';
import Camera from './Camera';
import {
	ItemCategory,
	Material,
	Color,
	ItemSubcategory,
} from '../typings/graphql';

export type FormValues = {
	name: string;
	category: string;
	subcategory: string;
	material: string;
	color: string;
	width: string;
	depth: string;
	height: string;
	secondaryDepth: string;
	diameter: string;
	comment: string;
	image: {
		file: string;
		imagePreviewUrl: string;
	};
};

type Props = {
	itemName?: string;
	disableComment?: boolean;
	title: string;
	itemCategories: ItemCategory[];
	materials: Material[];
	colors: Color[];
	onClose: () => void;
	onSubmit: (values: FormValues) => Promise<void>;
};

type FormProps = {
	initialName?: string;
	initialCategory?: string;
	initialSubcategory?: string;
	initialMaterial?: string;
	initialColor?: string;
	initialWidth?: string;
	initialDepth?: string;
	initialHeight?: string;
	initialSecondaryDepth?: string;
	initialDiameter?: string;
	initialComment?: string;
	initialImage?: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formHeader: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
		select: {
			minWidth: 200,
		},
		uploadInput: {
			display: 'none',
		},
		imagePreview: {
			width: '100%',
		},
	})
);

type SelectInput = {
	labelId: string;
	id: string;
	name: string;
	labelText: string;
	width: number;
	onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
	items: (ItemCategory | ItemSubcategory | Material | Color)[];
};

type InputField = {
	size: {
		xs: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
		sm: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	};
	id: string;
	name: string;
	label: string;
	helperText?: string;
};

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const InnerForm = (props: FormikProps<FormValues> & Props): JSX.Element => {
	const [cameraOpen, setCameraOpen] = useState(false);
	const classes = useStyles();

	const {
		values,
		touched,
		errors,
		handleChange,
		handleSubmit,
		setFieldValue,
		onClose,
		isSubmitting,
		disableComment,
	} = props;

	const categorySelects: SelectInput[] = [
		{
			labelId: 'choose-item-category-label',
			id: 'choose-item-category-select',
			name: 'category',
			labelText: 'Item Category',
			width: 102,
			onChange: (event: React.ChangeEvent<{ value: unknown }>): void => {
				setFieldValue('category', event.target.value as string);
				setFieldValue('subcategory', '');
			},
			items: props.itemCategories,
		},
		{
			labelId: 'choose-item-subcategory-label',
			id: 'choose-item-subcategory-select',
			name: 'subcategory',
			labelText: 'Item Subcategory',
			width: 127,
			onChange: handleChange,
			items:
				_.find(
					props.itemCategories,
					(category) => category.id === values.category
				)?.subcategories || [],
		},
	];

	const materialColorSelects: SelectInput[] = [
		{
			labelId: 'choose-item-material-label',
			id: 'choose-item-material-select',
			name: 'material',
			labelText: 'Material',
			width: 58,
			onChange: handleChange,
			items: props.materials,
		},
		{
			labelId: 'choose-item-color-label',
			id: 'choose-item-color-select',
			name: 'color',
			labelText: 'Color',
			width: 37,
			onChange: handleChange,
			items: props.colors,
		},
	];

	const inputFields: InputField[] = [
		{
			size: {
				xs: 12,
				sm: 4,
			},
			id: 'choose-width-label',
			name: 'width',
			label: 'Width(cm)',
		},
		{
			size: {
				xs: 12,
				sm: 4,
			},
			id: 'choose-height-label',
			name: 'height',
			label: 'Height(cm)',
		},
		{
			size: {
				xs: 12,
				sm: 4,
			},
			id: 'choose-depth-label',
			name: 'depth',
			label: 'Depth(cm)',
		},
		{
			size: {
				xs: 12,
				sm: 6,
			},
			id: 'choose-secondaryDepth-label',
			name: 'secondaryDepth',
			label: 'Secondary Depth(cm)',
		},
		{
			size: {
				xs: 12,
				sm: 6,
			},
			id: 'choose-diameter-label',
			name: 'diameter',
			label: 'Diameter(cm)',
		},
	];

	const savePhoto = (dataURI: string): void => {
		setCameraOpen(false);

		// convert base64 to raw binary data held in a string
		const byteString = atob(dataURI.split(',')[1]);

		// separate out the mime component
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to an ArrayBuffer
		// eslint-disable-next-line no-undef
		const arrayBuffer = new ArrayBuffer(byteString.length);
		// eslint-disable-next-line no-undef
		const _ia = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			_ia[i] = byteString.charCodeAt(i);
		}

		// eslint-disable-next-line no-undef
		const dataView = new DataView(arrayBuffer);
		const blob = new Blob([dataView], { type: mimeString });

		setFieldValue('image', {
			file: blob,
			imagePreviewUrl: dataURI,
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<DialogTitle id="form-create-item">{props.title}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<FormControl variant="outlined" fullWidth>
							<TextField
								name={'name'}
								label={'Name'}
								variant="outlined"
								value={values.name}
								onChange={handleChange}
								error={false}
								helperText={''}
							/>
						</FormControl>
						<Typography className={classes.formHeader} component="h3">
							Category
						</Typography>
						<Grid container spacing={2}>
							{categorySelects.map((select: SelectInput) => (
								<Grid key={select.id + '-grid-item'} item xs={12} sm={6}>
									<FormControl
										variant="outlined"
										fullWidth
										error={!!errors[select.name] && touched[select.name]}
									>
										<InputLabel id={select.labelId}>
											{select.labelText}
										</InputLabel>
										<Select
											labelId={select.labelId}
											id={select.id}
											value={values[select.name]}
											onChange={select.onChange}
											className={classes.select}
											labelWidth={select.width}
											name={select.name}
											fullWidth
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{select.items.map((el) => (
												<MenuItem key={el.id} value={el.id}>
													{el.name}
												</MenuItem>
											))}
										</Select>
										<FormHelperText>
											{errors[select.name] || 'Required'}
										</FormHelperText>
									</FormControl>
								</Grid>
							))}
						</Grid>

						<Typography className={classes.formHeader} component="h3">
							Material and Color
						</Typography>
						<Grid container spacing={2}>
							{materialColorSelects.map((select: SelectInput) => (
								<Grid key={select.id + '-grid-item'} item xs={12} sm={6}>
									<FormControl
										variant="outlined"
										fullWidth
										error={!!errors[select.name] && touched[select.name]}
									>
										<InputLabel id={select.labelId}>
											{select.labelText}
										</InputLabel>
										<Select
											labelId={select.labelId}
											id={select.id}
											value={values[select.name]}
											onChange={select.onChange}
											className={classes.select}
											labelWidth={select.width}
											name={select.name}
											fullWidth
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{select.items.map((el) => (
												<MenuItem key={el.id} value={el.id}>
													{el.name}
												</MenuItem>
											))}
										</Select>
										<FormHelperText>{errors[select.name]}</FormHelperText>
									</FormControl>
								</Grid>
							))}
						</Grid>

						<Typography className={classes.formHeader} component="h3">
							Measurements
						</Typography>
						<Grid container spacing={2}>
							{inputFields.map((inputField) => (
								<Grid
									key={inputField.id + '-text-input'}
									item
									xs={inputField.size.xs}
									sm={inputField.size.sm}
								>
									<FormControl variant="outlined" fullWidth>
										<TextField
											id={inputField.id}
											name={inputField.name}
											label={inputField.label}
											variant="outlined"
											value={values[inputField.name]}
											onChange={handleChange}
											error={
												!!errors[inputField.name] && touched[inputField.name]
											}
											helperText={errors[inputField.name]}
										/>
									</FormControl>
								</Grid>
							))}
						</Grid>

						{!disableComment && (
							<>
								<Typography className={classes.formHeader} component="h3">
									Comment
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FormControl variant="outlined" fullWidth>
											<TextField
												id="choose-comment-input"
												label="Comment"
												name="comment"
												multiline
												variant="outlined"
												value={values.comment}
												onChange={handleChange}
												error={!!errors.comment && touched.comment}
												helperText={errors.comment}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</>
						)}
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography className={classes.formHeader} component="h3">
							Image
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<Grid container direction="column" spacing={2}>
									<Grid item>
										<Button
											variant="outlined"
											component="span"
											onClick={() => setCameraOpen(true)}
											startIcon={<PhotoCameraIcon />}
										>
											Take Photo
										</Button>
									</Grid>
									<Camera
										open={cameraOpen}
										onClose={() => setCameraOpen(false)}
										savePhoto={savePhoto}
									/>
									<Grid item>
										<FormControl
											error={!!errors.image?.file && touched.image?.file}
										>
											<input
												accept={SUPPORTED_FORMATS.join(', ')}
												id="image-upload-button"
												className={classes.uploadInput}
												type="file"
												name="image"
												onChange={(
													event: ChangeEvent<{ files: FileList | null }>
												): void => {
													event.preventDefault();
													if (
														event.target.files &&
														event.target.files?.length > 0
													) {
														const reader = new FileReader();
														const file = event.target.files[0];

														reader.onloadend = (): void => {
															setFieldValue('image', {
																file: file,
																imagePreviewUrl: reader.result,
															});
														};

														reader.readAsDataURL(file);
													}
												}}
											></input>
											<label htmlFor="image-upload-button">
												<Button
													variant="outlined"
													component="span"
													startIcon={<PublishIcon />}
												>
													Upload image
												</Button>
											</label>
											<FormHelperText style={{ paddingLeft: 16 }}>
												{errors.image?.file || 'Required'}
											</FormHelperText>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={8}>
								{values.image.imagePreviewUrl ? (
									<img
										alt="item-preview"
										className={classes.imagePreview}
										src={values.image.imagePreviewUrl}
									/>
								) : (
									<span>Please select an Image for preview</span>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<DialogActions>
					<Button color="primary" onClick={onClose}>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						disabled={isSubmitting}
					>
						Submit
					</Button>
				</DialogActions>
			</DialogContent>
		</form>
	);
};

const CreateItemForm = withFormik<FormProps & Props, FormValues>({
	mapPropsToValues: (props) => ({
		name: props.initialName || '',
		category: props.initialCategory || '',
		subcategory: props.initialSubcategory || '',
		material: props.initialMaterial || '',
		color: props.initialColor || '',
		width: props.initialWidth || '',
		depth: props.initialDepth || '',
		height: props.initialHeight || '',
		secondaryDepth: props.initialSecondaryDepth || '',
		diameter: props.initialDiameter || '',
		comment: props.initialComment || '',
		image: {
			file: '',
			imagePreviewUrl: props.initialImage || '',
		},
	}),

	validationSchema: yup.object().shape(
		{
			name: yup.string().required('Required').max(128, 'Max 128 characters'),
			category: yup.string().required('Required'),
			subcategory: yup.string().required('Required'),
			material: yup.string(),
			color: yup.string(),
			width: yup.number().when(['height', 'depth'], {
				is: (height, depth) => height !== undefined || depth !== undefined,
				then: yup.number().positive('Must be positive').required('Required'),
				otherwise: yup.number().positive('Must be positive').notRequired(),
			}),
			height: yup.number().when(['width', 'depth'], {
				is: (width, depth) => width !== undefined || depth !== undefined,
				then: yup.number().positive('Must be positive').required('Required'),
				otherwise: yup.number().positive('Must be positive').notRequired(),
			}),
			depth: yup.number().when(['width', 'height'], {
				is: (width, height) => width !== undefined || height !== undefined,
				then: yup.number().positive('Must be positive').required('Required'),
				otherwise: yup.number().positive('Must be positive').notRequired(),
			}),
			secondaryDepth: yup.number().positive('Must be positive'),
			diameter: yup.number().positive('Must be positive'),
			comment: yup.string().max(255, 'Max 255 characters').trim(),
			image: yup.object().shape({
				file: yup.mixed().when(['image.previewUrl'], {
					is: (url) => url !== undefined,
					then: yup
						.mixed()
						.test('file', 'No image chosen', (value) => {
							return !!value;
						})
						.test('fileType', 'Unsupported File Format', (value) => {
							if (value)
								return (
									SUPPORTED_FORMATS.includes(value.type) ||
									SUPPORTED_FORMATS.includes(value.imageType)
								);
							return false;
						}),
				}),
			}),
		},
		[
			['width', 'height'],
			['width', 'depth'],
			['depth', 'height'],
		]
	),

	async handleSubmit(values, { props }) {
		return props.onSubmit(values);
	},
})(InnerForm);

export default CreateItemForm;
