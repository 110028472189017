import { Popover } from '@material-ui/core';
import React, { useState } from 'react';
import nameSort from '../utils/nameSort';
import { v4 as uuid } from 'uuid';
import { CellOrder, TableItem } from '../elements/SimpleTable';
import { useCreateItemCategory, useDeleteItemCategory, useMe } from '../hooks';
import CreateNameForm, { FormValues } from './CreateNameForm';
import EnhancedTable from './EnhancedTable';
import { useSnackbar } from 'notistack';
import { Query } from '../typings/graphql';
import { extractErrorMessage } from '../utils/errorMessages';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';

export const QUERY = gql`
	query itemCategories {
		itemCategories {
			id
			name
		}
	}
`;

const cellOrder: CellOrder[] = [
	{
		align: 'left',
		name: 'Category',
		itemName: 'name',
	},
];

type Props = {
	onSelection: (item: TableItem) => void;
};

const CategoriesTable = ({ onSelection }: Props): JSX.Element => {
	const createItemCategory = useCreateItemCategory();
	const deleteItemCategory = useDeleteItemCategory();
	const me = useMe();
	const { enqueueSnackbar } = useSnackbar();

	const { data, refetch } = useQuery<Pick<Query, 'itemCategories'>>(QUERY);

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleTriggerPopover = (
		event: React.MouseEvent<HTMLButtonElement>
	): void => setAnchorEl(event.currentTarget);

	const handlePopoverClose = (): void => setAnchorEl(null);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const variables = {
			itemCategory: {
				id: uuid(),
				name: values.name,
			},
		};
		try {
			await createItemCategory(variables);
			enqueueSnackbar(`Successfully created item category: ${values.name}`, {
				variant: 'success',
			});
		} catch (error) {
			const message = extractErrorMessage(error);
			enqueueSnackbar(message, { variant: 'error' });
		}
	};

	const handleDelete = async (item: TableItem): Promise<void> => {
		try {
			await deleteItemCategory({ id: item.id });
			enqueueSnackbar(`Successfully deleted item category: ${item.name}`, {
				variant: 'info',
			});
		} catch (error) {
			const message = extractErrorMessage(error);
			enqueueSnackbar(message, { variant: 'error' });
		}
	};

	const handleListItemClick = (item: TableItem): void => {
		onSelection(item);
	};

	const sorted = data?.itemCategories.slice(0).sort(nameSort);

	const open = Boolean(anchorEl);
	const id = open ? 'create-project-popover' : undefined;

	return (
		<>
			<EnhancedTable
				empty="No item categories exists yet"
				reload={refetch}
				items={sorted ? (sorted as TableItem[]) : []}
				searchPlaceholder="Search for item category name"
				order={cellOrder}
				onClick={handleTriggerPopover}
				onListItemClick={handleListItemClick}
				onDeleteClick={handleDelete}
				canCreate={me?.admin}
				canDelete={me?.admin}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<CreateNameForm
					label="Item Category Name"
					onSubmit={handleSubmit}
					onClose={handlePopoverClose}
				/>
			</Popover>
		</>
	);
};

export default CategoriesTable;
