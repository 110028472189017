import {
	Button,
	Card,
	CardActions,
	createStyles,
	IconButton,
	makeStyles,
	Popover,
	Theme,
	Tooltip,
	useTheme,
} from '@material-ui/core';
import {
	useActiveRoom,
	useSetArticleCollectionCount,
	useRemoveCommentFromArticleInCollection,
} from '../hooks';
import ArticleInstancesForm, { FormValues } from './ArticleInstancesForm';
import { mdiCommentPlus, mdiCommentRemove, mdiPrinter } from '@mdi/js';
import { updateHandlerByName } from '../graphql';
import { ArticleCollection } from '../typings/graphql';
import React, { useState } from 'react';
import UpdateArticleCommentDialog from './UpdateArticleCommentDialog';
import DeleteArticleDialog from './DeleteArticleDialog';
import ArticleCardContent from './ArticleCardContent';
import PrintArticleDialog from './PrintArticleDialog';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@mdi/react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			width: 240,
		},
		actions: {
			justifyContent: 'flex-end',
		},
		comment: {
			marginRight: 'auto',
		},
		confirmButtons: {
			margin: theme.spacing(1),
		},
		button: {
			padding: '0px',
		},
	})
);

type Props = {
	articleCollection: ArticleCollection;
	editable: boolean;
	articleId: string;
};

const ArticlesCard = (props: Props): JSX.Element => {
	const { articleCollection, editable, articleId } = props;
	const { item, comment, count } = articleCollection;
	const host = window.location.host;
	const name = item.name || null;
	const width = item.width;
	const depth = item.depth;
	const height = item.height;
	const imageUrl = item.image.url;
	const subcategory = item.subcategory.name!;
	const category = item.subcategory.category.name!;
	const material = item.material?.name || null;
	const color = item.color?.name || null;
	const itemId = item.id;
	const [commentDialogOpen, setCommentDialogOpen] = useState(false);
	const [deleteArticleDialogOpen, setDeleteArticleDialogOpen] = useState(false);
	const [editCountActive, setEditCountActive] = useState(false);
	const [printDialogOpen, setPrintDialogOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const room = useActiveRoom();
	const theme = useTheme();
	const classes = useStyles();

	const setArticleCollectionCount = useSetArticleCollectionCount(
		(cache, mutationResult) =>
			updateHandlerByName.setArticleCollectionCount(
				cache,
				mutationResult,
				room.id
			)
	);

	const removeCommentFromArticleInCollection =
		useRemoveCommentFromArticleInCollection((cache, mutationResult) =>
			updateHandlerByName.removeCommentFromArticleInCollection(
				cache,
				mutationResult,
				room.id
			)
		);

	const handleCommentDialogClose = (): void => {
		setCommentDialogOpen(false);
	};

	const handleDeleteArticleDialogClose = (): void => {
		setDeleteArticleDialogOpen(false);
	};

	const handlePrintDialogClose = (): void => {
		setPrintDialogOpen(false);
	};

	const handlePopoverClose = (): void => setAnchorEl(null);

	const setCollectionCountHandler = (newCount: number): void => {
		const variables = {
			query: {
				comment: comment,
				itemId: item.id,
				roomId: room?.id,
			},
			count: newCount,
		};

		setArticleCollectionCount(variables);
	};

	const removeCommentHandler = (): void => {
		const variables = {
			query: {
				comment,
				itemId: item.id,
				roomId: room.id,
			},
		};

		removeCommentFromArticleInCollection(variables);

		handlePopoverClose();
	};

	const handleCountClick = (): void => {
		setEditCountActive(!editCountActive);
	};

	const handleTriggerPopover = (
		event: React.MouseEvent<HTMLButtonElement>
	): void => setAnchorEl(event.currentTarget);

	const popoverOpen = Boolean(anchorEl);
	const id = commentDialogOpen ? 'remove-article-popover' : undefined;

	return (
		<Card className={classes.card}>
			<ArticleCardContent
				itemName={name}
				id={itemId}
				imageUrl={imageUrl}
				category={category}
				subcategory={subcategory}
				width={width}
				depth={depth}
				height={height}
				material={material}
				color={color}
				comment={comment}
				instantImage
			/>

			<CardActions className={classes.actions}>
				<Tooltip title="Print QR-code for this article" aria-label="print-qr">
					<span className={classes.comment}>
						<IconButton onClick={(): void => setPrintDialogOpen(true)}>
							<Icon path={mdiPrinter} size={1} />
						</IconButton>
					</span>
				</Tooltip>

				<PrintArticleDialog
					open={printDialogOpen}
					onClose={handlePrintDialogClose}
					articleId={articleId}
					itemId={itemId}
					printAllArticles={false}
					host={host}
				/>

				{comment ? (
					<>
						<Tooltip
							title="Remove comment from one"
							aria-label="remove-comment"
						>
							<span className={classes.comment}>
								<IconButton
									onClick={handleTriggerPopover}
									disabled={!editable}
									className={classes.button}
								>
									<Icon
										path={mdiCommentRemove}
										size={1}
										color={
											editable
												? theme.palette.grey[600]
												: theme.palette.grey[400]
										}
									/>
								</IconButton>
							</span>
						</Tooltip>

						<Popover
							id={id}
							open={popoverOpen}
							anchorEl={anchorEl}
							onClose={handlePopoverClose}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							transformOrigin={{ vertical: 'top', horizontal: 'center' }}
						>
							<Card>
								<Button
									className={classes.confirmButtons}
									onClick={handlePopoverClose}
									color="primary"
								>
									Cancel
								</Button>
								<Button
									className={classes.confirmButtons}
									onClick={removeCommentHandler}
									variant="contained"
									color="secondary"
								>
									Remove
								</Button>
							</Card>
						</Popover>
					</>
				) : (
					<>
						<Tooltip title="Add comment to one" aria-label="add-comment">
							<span className={classes.comment}>
								<IconButton
									onClick={(): void => setCommentDialogOpen(true)}
									disabled={!editable}
									className={classes.button}
								>
									<Icon
										path={mdiCommentPlus}
										size={1}
										color={
											editable
												? theme.palette.grey[600]
												: theme.palette.grey[400]
										}
									/>
								</IconButton>
							</span>
						</Tooltip>

						<UpdateArticleCommentDialog
							title="Add Comment To Article"
							description="Add a comment to one of the articles in this collection."
							open={commentDialogOpen}
							onClose={handleCommentDialogClose}
							itemId={item.id}
							roomId={room.id}
						/>
					</>
				)}

				{!editCountActive && (
					<Tooltip title="Remove article" aria-label="remove-article">
						<span>
							<IconButton
								onClick={
									count === 1
										? (): void => setDeleteArticleDialogOpen(true)
										: (): void => setCollectionCountHandler(count - 1)
								}
								disabled={!editable}
							>
								<RemoveIcon />
							</IconButton>
						</span>
					</Tooltip>
				)}

				<DeleteArticleDialog
					open={deleteArticleDialogOpen}
					onClose={handleDeleteArticleDialogClose}
					onConfirm={(): void => setCollectionCountHandler(count - 1)}
				/>

				<ArticleInstancesForm
					onClick={handleCountClick}
					onSubmit={async (variables: FormValues): Promise<void> => {
						setCollectionCountHandler(variables.instances);
					}}
					editable={editable}
					initialInstances={count}
				/>

				{!editCountActive && (
					<Tooltip title="Add article" aria-label="add-article">
						<span>
							<IconButton
								onClick={(): void => setCollectionCountHandler(count + 1)}
								disabled={!editable}
							>
								<AddIcon />
							</IconButton>
						</span>
					</Tooltip>
				)}
			</CardActions>
		</Card>
	);
};

export default ArticlesCard;
