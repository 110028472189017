import React, { useState } from 'react';
import EnhancedTable from './EnhancedTable';
import { CellOrder, TableItem } from '../elements/SimpleTable';
import {
	useSetActiveBuilding,
	useActiveProject,
	useCreateBuilding,
	useMe,
} from '../hooks';
import nameSort from '../utils/nameSort';
import { Popover } from '@material-ui/core';
import CreateNameForm, { FormValues } from './CreateNameForm';
import { v4 as uuid } from 'uuid';
import { Query } from '../typings/graphql';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { isStructAdmin } from 'utils/helpers';
import DeleteLocations from './DeleteLocations';

const QUERY = gql`
	query buildings($input: QueryBuildingsInput!) {
		buildings(input: $input) {
			id
			name
			project {
				id
			}
		}
	}
`;

const cellOrder: CellOrder[] = [
	{
		align: 'left',
		name: 'Building',
		itemName: 'name',
	},
];

type Props = {
	onSelection: (event?: MouseEvent) => void;
};

const BuildingsTable = ({ onSelection }: Props): JSX.Element => {
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [buildingToDelete, setBuildingToDelete] = useState<
		TableItem | undefined
	>();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const project = useActiveProject();
	const me = useMe();
	const structAdmin = me ? isStructAdmin(me) : false;
	const setActiveBuilding = useSetActiveBuilding();
	const createBuilding = useCreateBuilding();

	const { data, refetch } = useQuery<Pick<Query, 'buildings'>>(QUERY, {
		variables: { input: { project: project?.id } },
	});

	const handleTriggerPopover = (
		event: React.MouseEvent<HTMLButtonElement>
	): void => setAnchorEl(event.currentTarget);

	const handlePopoverClose = (): void => setAnchorEl(null);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const variables = {
			building: {
				id: uuid(),
				name: values.name,
				projectId: project.id,
			},
		};
		createBuilding(variables);
	};

	const handleListItemClick = (item: TableItem): void => {
		setActiveBuilding(item);
		onSelection();
	};

	const sorted = data?.buildings.slice(0).sort(nameSort);

	const open = Boolean(anchorEl);
	const id = open ? 'create-company-popover' : undefined;

	const handleDeleteLocation = (item: TableItem) => {
		setBuildingToDelete(item);
		setOpenDeleteDialog(true);
	};

	return (
		<>
			<EnhancedTable
				empty="No buildings exist for this project yet"
				reload={refetch}
				items={sorted ? (sorted as TableItem[]) : []}
				searchPlaceholder="Search for a building name"
				order={cellOrder}
				onClick={handleTriggerPopover}
				onDeleteClick={handleDeleteLocation}
				onListItemClick={handleListItemClick}
				canCreate={structAdmin}
				canDelete={me?.admin}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<CreateNameForm
					label="Building Name"
					onSubmit={handleSubmit}
					onClose={handlePopoverClose}
				/>
			</Popover>
			<DeleteLocations
				setOpenDeleteDialog={setOpenDeleteDialog}
				openDeleteDialog={openDeleteDialog}
				buildingToDelete={buildingToDelete}
			/>
		</>
	);
};

export default BuildingsTable;
