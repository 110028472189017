import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const QUERY = gql`
	query getBuildingsByProject($input: QueryBuildingsInput!) {
		buildings(input: $input) {
			id
			name
			project {
				id
			}
		}
	}
`;

export const handleCreateBuildingUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createBuilding'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createBuilding } = data;

	if (!createBuilding) return;

	try {
		const cacheData = cache.readQuery<Pick<Query, 'buildings'>>({
			query: QUERY,
			variables: { input: { project: createBuilding.project.id } },
		});
		if (cacheData === null) return;

		const buildings = cacheData.buildings.filter(
			(building) => building.id !== createBuilding.id
		);
		buildings.push(createBuilding);

		cache.writeQuery({
			query: QUERY,
			variables: { input: { project: createBuilding.project.id } },
			data: { buildings },
		});
	} catch (e) {
		// We optimistically write data since nothing exists
		const buildings = [createBuilding];
		cache.writeQuery({
			query: QUERY,
			variables: { input: { project: createBuilding.project.id } },
			data: { buildings },
		});
	}
};

export const handleDeleteBuildingUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteBuilding'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const buildingIdToDelete = data.deleteBuilding?.id;
	const normalizedBuildingId = cache.identify({
		id: buildingIdToDelete,
		__typename: 'Building',
	});
	cache.evict({ id: normalizedBuildingId });
	cache.gc();
};
