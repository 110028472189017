import { Mutation, MutationDeleteColorArgs } from '../typings/graphql';
import { updateHandlerByName } from '../graphql';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

const MUTATION = gql`
	mutation deleteColor($id: ID!) {
		deleteColor(id: $id) {
			id
			name
		}
	}
`;

export default (): ((variables: MutationDeleteColorArgs) => Promise<void>) => {
	const [deleteColor] = useMutation<
		Pick<Mutation, 'deleteColor'>,
		MutationDeleteColorArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.deleteColor,
	});

	return async (variables): Promise<void> => {
		try {
			await deleteColor({
				variables,
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
