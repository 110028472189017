import { gql } from '@apollo/client/core';

export default gql`
	type AppBar {
		title: String
	}

	extend type Query {
		appBar: AppBar
	}

	extend type Mutation {
		setAppBarTitle(title: String!): AppBar
	}
`;
