import { gql, MutationUpdaterFn, useMutation } from '@apollo/client';
import {
	Mutation,
	MutationUpdateItemArgs,
	ItemSubcategory,
	Material,
	Color,
	Image,
	Project,
	Item,
} from '../typings/graphql';

const MUTATION = gql`
	mutation updateItem($item: UpdateItemInput!) {
		updateItem(item: $item) {
			id
			name
			width
			height
			depth
			secondaryDepth
			diameter
			project {
				id
			}
			subcategory {
				id
			}
			material {
				id
			}
			color {
				id
			}
			image {
				id
				url
			}
		}
	}
`;

type ItemIds = {
	project: string;
	subcategory: string;
	material: string;
	color: string;
	image: string;
};

export default (
	updateHandler: MutationUpdaterFn<Pick<Mutation, 'updateItem'>>
): ((
	variables: MutationUpdateItemArgs,
	previewImageUrl: string,
	ids: ItemIds
) => Promise<void>) => {
	const [updateItem] = useMutation<
		Pick<Mutation, 'updateItem'>,
		MutationUpdateItemArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandler,
	});

	return async (variables, previewImageUrl, ids): Promise<void> => {
		try {
			await updateItem({
				variables,
				optimisticResponse: {
					updateItem: {
						__typename: 'Item',
						id: variables.item.id,
						name: variables.item.name,
						width: variables.item.width,
						depth: variables.item.depth,
						height: variables.item.height,
						secondaryDepth: variables.item.secondaryDepth || null,
						diameter: variables.item.diameter || null,
						project: {
							__typename: 'Project',
							id: variables.item.projectId || ids.project,
						} as Project,
						subcategory: {
							__typename: 'ItemSubcategory',
							id: variables.item.itemSubcategoryId || ids.subcategory,
						} as ItemSubcategory,
						material: {
							__typename: 'Material',
							id: variables.item.materialId || ids.material,
						} as Material,
						color: {
							__typename: 'Color',
							id: variables.item.colorId || ids.color,
						} as Color,
						image: {
							__typename: 'Image',
							id: variables.item.imageId,
							url: previewImageUrl,
						} as Image,
					} as Item,
				},
			});
		} catch (error) {
			console.log('err', error);
		}
	};
};
