import React, { useState } from 'react';
import Cam, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {
	Dialog,
	DialogContent,
	Button,
	Grid,
	IconButton,
	makeStyles,
	createStyles,
	Theme,
} from '@material-ui/core';
import FlipCameraIcon from '@material-ui/icons/FlipCameraAndroidOutlined';

type Props = {
	open: boolean;
	onClose: () => void;
	savePhoto: (dataUri: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		flipCamera: {
			position: 'absolute',
			right: theme.spacing(2),
			bottom: theme.spacing(2),
			zIndex: 1000,
			color: theme.palette.common.white,
		},
	})
);

const Camera = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { open, onClose, savePhoto } = props;
	const [dataUri, setDataUri] = useState('');
	const [idealFacingMode, setIdealFacingMode] = useState<string>(
		FACING_MODES.ENVIRONMENT as string
	);

	const handleTakePhoto = (dataUri: string): void => {
		setDataUri(dataUri);
	};

	const handleFlipCamera = (): void => {
		if (idealFacingMode === FACING_MODES.ENVIRONMENT) {
			setIdealFacingMode(FACING_MODES.USER as string);
		} else {
			setIdealFacingMode(FACING_MODES.ENVIRONMENT as string);
		}
	};

	return (
		<Dialog open={open} maxWidth={false} onClose={onClose}>
			<DialogContent style={{ padding: 16, maxWidth: '80vw' }}>
				{dataUri ? (
					<>
						<Grid container spacing={2} justifyContent="flex-end">
							<Grid item xs={12}>
								<img alt="preview" style={{ width: '100%' }} src={dataUri} />
							</Grid>
							<Grid item>
								<Button color="primary" onClick={(): void => setDataUri('')}>
									Discard Photo
								</Button>
							</Grid>

							<Grid item>
								<Button
									color="primary"
									variant="contained"
									onClick={(): void => savePhoto(dataUri)}
								>
									Choose Photo
								</Button>
							</Grid>
						</Grid>
					</>
				) : (
					<Cam
						isMaxResolution
						isImageMirror={idealFacingMode !== FACING_MODES.ENVIRONMENT}
						idealFacingMode={idealFacingMode}
						onTakePhotoAnimationDone={handleTakePhoto}
					/>
				)}
				{!dataUri && (
					<IconButton className={classes.flipCamera} onClick={handleFlipCamera}>
						<FlipCameraIcon />
					</IconButton>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default Camera;
