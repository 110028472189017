import {
	Box,
	Collapse,
	IconButton,
	TableCell,
	TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ArticlesTable, { articleParams } from './ArticlesTable';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Item, Query } from '../../typings/graphql';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

type Props = {
	item: Item;
	reset: boolean;
	articlesQueryInput: Record<string, unknown>;
};

export const ARTICLES_BY_ITEM_QUERY = gql`
	query getArticlesByItem($input: QueryArticlesInput!) {
		articles(input: $input) {
			id
			item {
				id
			}
		}
	}
`;

const ItemRow = ({ item, articlesQueryInput, reset }: Props): JSX.Element => {
	const params = useParams() as articleParams;
	const paramsArticleId = params.articleId ? params.articleId : '';
	const [open, setOpen] = useState(false);
	const { data: articleData } = useQuery<Pick<Query, 'articles'>>(
		ARTICLES_BY_ITEM_QUERY,
		{
			variables: {
				input: { item: item.id },
			},
			skip: !paramsArticleId,
			returnPartialData: true,
			fetchPolicy: 'cache-and-network',
		}
	);

	useEffect(() => {
		if (reset) {
			setOpen(false);
		}
	}, [reset]);

	useEffect(() => {
		if (paramsArticleId) {
			const articleIds = articleData
				? articleData.articles.map((article) => article.id)
				: [];
			if (articleIds.includes(paramsArticleId)) {
				setOpen(true);
			}
		} else {
			setOpen(false);
		}
	}, [paramsArticleId, articleData]);
	return (
		<>
			<TableRow>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell>{item.name}</TableCell>
				<TableCell>{item.subcategory.category.name}</TableCell>
				<TableCell>{item.subcategory.name}</TableCell>
				<TableCell>{item.material?.name}</TableCell>
				<TableCell>{item.color?.name}</TableCell>
				<TableCell>{item.height}</TableCell>
				<TableCell>{item.width}</TableCell>
				<TableCell>{item.depth}</TableCell>
				<TableCell>{item.diameter}</TableCell>
				<TableCell>{item.numberOfArticles}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }}
					colSpan={12}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={'auto'}>
							<ArticlesTable itemId={item.id} input={articlesQueryInput} />
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default ItemRow;
