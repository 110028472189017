import {
	createStyles,
	Dialog,
	IconButton,
	makeStyles,
	Theme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			position: 'absolute',
			color: '#fff',
			top: theme.spacing(1),
			right: theme.spacing(1),
		},

		image: {
			overflow: 'hidden',
			padding: theme.spacing(1),
			height: 'auto',
			width: 'auto',
			maxHeight: '90vh',
			maxWidth: '90vw',
		},
	})
);

type Props = {
	open: boolean;
	onClose: () => void;
	imageUrl: string;
};

const ImageEnlargeDialog = (props: Props): JSX.Element => {
	const { open, onClose, imageUrl } = props;
	const classes = useStyles();

	return (
		<Dialog open={open} onClose={onClose} maxWidth={false}>
			<IconButton className={classes.icon} onClick={onClose}>
				<CloseIcon />
			</IconButton>
			<img alt="enlarged-imaged" className={classes.image} src={imageUrl}></img>
		</Dialog>
	);
};

export default ImageEnlargeDialog;
