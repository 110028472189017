import { gql } from '@apollo/client/core';
import _ from 'lodash';
import setActiveFloor from '../activeFloor/setActiveFloor';
import setActiveRoom from '../activeRoom/setActiveRoom';

type TVariables = {
	input: {
		rid: string;
		name: string;
	};
};

type ActiveBuilding = {
	__typename: 'ActiveBuilding';
	rid: string;
	name: string;
};

const QUERY = gql`
	query activeBuilding {
		activeBuilding @client {
			rid
			name
		}
	}
`;

export default (
	_root: void,
	variables: TVariables,
	{ cache }: TContext
): ActiveBuilding | null => {
	const cacheData = cache.readQuery({ query: QUERY });
	if (cacheData === null) return null;

	const newCache = _.cloneDeep(cacheData);
	newCache.activeBuilding.rid = variables.input.rid;
	newCache.activeBuilding.name = variables.input.name;

	cache.writeQuery({ query: QUERY, data: newCache });

	setActiveFloor(undefined, { input: { rid: '', name: '' } }, { cache });
	setActiveRoom(undefined, { input: { rid: '', name: '' } }, { cache });

	return newCache.activeBuilding;
};

export { QUERY as SET_ACTIVE_BUILDING_QUERY };
