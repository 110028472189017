import { DEFAULT_ERROR_MESSAGE } from 'config';

export const generateErrorMessage = (error: unknown): string =>
	error instanceof Error ? error.message : DEFAULT_ERROR_MESSAGE;

export const extractErrorMessage = (error: unknown): string =>
	error instanceof Error
		? error.message.includes(':')
			? error.message.split(':')[1].trim()
			: error.message
		: DEFAULT_ERROR_MESSAGE;
