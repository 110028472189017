import { gql } from '@apollo/client/core';
import { Mutation, Query } from '../typings/graphql';

const QUERY = gql`
	query articleCollections($query: QueryArticleCollectionsInput!) {
		articleCollections(query: $query) {
			id
			count
			comment
			item {
				id
			}
		}
	}
`;

export const handleSetArticleCollectionCount: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'setArticleCollectionCount'>
> = (cache, { data }, roomId): void => {
	if (data === undefined || data === null) return;

	const { setArticleCollectionCount } = data;

	if (!setArticleCollectionCount) return;

	const cacheData = cache.readQuery<Pick<Query, 'articleCollections'>>({
		query: QUERY,
		variables: { query: { roomId } },
	});

	const filtered =
		cacheData?.articleCollections.filter(
			(collection) => collection.id !== setArticleCollectionCount.id
		) || [];

	if (setArticleCollectionCount.count !== 0) {
		filtered.push(setArticleCollectionCount);
	}

	cache.writeQuery({
		query: QUERY,
		variables: { query: { roomId } },
		data: { articleCollections: filtered },
	});
};

export const handleAddCommentToArticleInCollection: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'addCommentToArticleInCollection'>
> = (cache, { data }, roomId): void => {
	if (data === undefined || data === null) return;

	const { addCommentToArticleInCollection } = data;

	cache.writeQuery({
		query: QUERY,
		variables: { query: { roomId } },
		data: { articleCollections: addCommentToArticleInCollection },
	});
};

export const handleRemoveCommentFromArticleInCollection: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'removeCommentFromArticleInCollection'>
> = (cache, { data }, roomId): void => {
	if (data === undefined || data === null) return;

	const { removeCommentFromArticleInCollection } = data;

	cache.writeQuery({
		query: QUERY,
		variables: { query: { roomId } },
		data: { articleCollections: removeCommentFromArticleInCollection },
	});
};
