import { gql, OperationVariables, useQuery } from '@apollo/client';
import { TableBody } from '@material-ui/core';
import { useActiveProject } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Item, Query } from '../../typings/graphql';
import ItemRow from './ItemRow';

export const PAGINATED_ITEMS_QUERY = gql`
	query getItemsByProject($input: QueryItemsInput!) {
		paginatedItems(input: $input) {
			numberOfItems
			items {
				id
				name
				height
				width
				depth
				diameter
				numberOfArticles
				project {
					id
				}
				image {
					id
					url
				}
				color {
					id
					name
				}
				material {
					id
					name
				}
				subcategory {
					id
					name
					category {
						id
						name
					}
				}
			}
		}
	}
`;

type Props = {
	queryVariables: OperationVariables;
	onQueryCompleted: (numberOfItems: number) => void;
	reset: boolean;
};

const ItemsTableData = ({
	queryVariables,
	onQueryCompleted,
	reset,
}: Props): JSX.Element => {
	const project = useActiveProject();
	const [items, setItems] = useState<Item[]>([]);
	const { data: itemData } = useQuery<Pick<Query, 'paginatedItems'>>(
		PAGINATED_ITEMS_QUERY,
		{
			variables: { ...queryVariables, project: project.id },
			returnPartialData: true,
			fetchPolicy: 'cache-and-network',
		}
	);
	const itemsData = itemData?.paginatedItems.items;
	const numberOfItems = itemData?.paginatedItems.numberOfItems || 0;
	useEffect(() => {
		onQueryCompleted(numberOfItems);
		if (itemsData) setItems(itemsData);
	}, [itemData, numberOfItems, onQueryCompleted, items, itemsData]);

	return (
		<TableBody>
			{items.map((item) => {
				return (
					<ItemRow
						key={item.id}
						item={item}
						reset={reset}
						articlesQueryInput={{
							room: queryVariables.input.location.roomId,
							floor: queryVariables.input.location.floorId,
							building: queryVariables.input.location.buildingId,
							searchInput: queryVariables.input.searchInput,
						}}
					/>
				);
			})}
		</TableBody>
	);
};

export default ItemsTableData;
