import React, { Component } from 'react';
import ImageComp, { ImgProps } from 'react-image';

type Props = { retries: number } & ImgProps;

type State = {
	loaded: boolean;
	retryCount: number;
};

class ImageWrapper extends Component<Props, State> {
	_isMounted = false;
	timeout?: NodeJS.Timeout = undefined;

	constructor(props: Props) {
		super(props);

		this.state = {
			loaded: false,
			retryCount: 1,
		};
	}

	componentDidMount(): void {
		this._isMounted = true;

		this.loadImage();
	}

	componentWillUnmount(): void {
		this._isMounted = false;
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	setLoaded(loaded: boolean): void {
		if (this._isMounted) {
			this.setState({ loaded });
		}
	}

	handleImageRetry(image: HTMLImageElement): void {
		if (!this._isMounted) return;

		this.setLoaded(false);

		if (this.state.retryCount <= this.props.retries) {
			this.timeout = setTimeout(() => {
				image.src = this.props.src as string;
				this.setState({ retryCount: this.state.retryCount + 1 });
			}, 1000 * this.state.retryCount);
		}
	}

	loadImage(): void {
		const image = new Image();
		image.onload = (): void => this.setLoaded(true);
		image.onerror = (): void => this.handleImageRetry(image);
		image.src = this.props.src as string;
	}

	render(): JSX.Element {
		const src = this.state.loaded ? this.props.src : '';
		return <ImageComp {...this.props} src={src} />;
	}
}

export default ImageWrapper;
