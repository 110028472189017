import {
	gql,
	MutationUpdaterFn,
	useApolloClient,
	useMutation,
} from '@apollo/client';
import {
	ArticleCollection,
	Item,
	Mutation,
	MutationAddCommentToArticleInCollectionArgs,
	Query,
} from '../typings/graphql';
import useActiveRoom from './useActiveRoom';

const MUTATION = gql`
	mutation addCommentToArticleInCollection(
		$query: QueryArticleCollectionInput!
		$comment: String!
	) {
		addCommentToArticleInCollection(query: $query, comment: $comment) {
			id
			comment
			count
			item {
				id
			}
		}
	}
`;

const LOCAL_QUERY = gql`
	query articleCollections($query: QueryArticleCollectionsInput!) {
		articleCollections(query: $query) {
			id
			count
			comment
			item {
				id
				height
				width
				depth
				image {
					id
					url
				}
				color {
					id
					name
				}
				material {
					id
					name
				}
				subcategory {
					id
					name
					category {
						id
						name
					}
				}
			}
		}
	}
`;

export default (
	updateHandler: MutationUpdaterFn<
		Pick<Mutation, 'addCommentToArticleInCollection'>
	>
) => {
	const [addCommentToArticleInCollection] = useMutation<
		Pick<Mutation, 'addCommentToArticleInCollection'>,
		MutationAddCommentToArticleInCollectionArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandler,
	});

	const room = useActiveRoom();
	const client = useApolloClient();
	let cacheData: Pick<Query, 'articleCollections'> | null;

	try {
		cacheData = client.readQuery<Pick<Query, 'articleCollections'>>({
			query: LOCAL_QUERY,
			variables: { query: { roomId: room.id } },
		});
	} catch (error) {
		// fail silently
		// This needs to be done because this will try to read the cache
		// before the query has ever been executed and so will throw and error because of that.
		// It will however fix itself since the query is executed at the same time as this code
		// is reached. Not pretty, but hey, such is life.
	}

	return async (
		variables: MutationAddCommentToArticleInCollectionArgs
	): Promise<void> => {
		try {
			const { query, comment } = variables;

			const cloned = cacheData?.articleCollections.slice(
				0
			) as ArticleCollection[];

			// Check if collection with correct item, and comment exists
			const collectionExists = cloned.find(
				(collection) => collection.id === `${query.itemId}@${comment}`
			);

			if (collectionExists) {
				collectionExists.count++;
			} else {
				const collection = {
					__typename: 'ArticleCollection',
					id: `${query.itemId}@${comment}`,
					comment,
					count: 1,
					item: {
						__typename: 'Item',
						id: query.itemId,
					},
				} as Omit<ArticleCollection, 'item'> & Pick<Item, '__typename' | 'id'>;

				cloned.push(collection as ArticleCollection);
			}

			const previousCollection = cloned?.find(
				(collection) => collection.id === query.itemId
			);

			if (previousCollection) {
				previousCollection.count--;
			}

			await addCommentToArticleInCollection({
				variables,
				optimisticResponse: {
					addCommentToArticleInCollection: cloned,
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
