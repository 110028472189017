import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';

type ActiveBuilding = {
	activeBuilding: {
		id: string;
		name: string;
	};
};

const QUERY = gql`
	query activeBuilding {
		activeBuilding @client {
			id: rid
			name
		}
	}
`;

export default (): IdName => {
	const { data } = useQuery<ActiveBuilding>(QUERY);

	if (!data) return { id: '', name: '' };

	return data.activeBuilding;
};
