import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

const QUERY = gql`
	query materials {
		materials {
			id
			name
		}
	}
`;

export const handleCreateMaterialUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createMaterial'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { createMaterial } = data;

	if (!createMaterial) return;

	const cacheData = cache.readQuery<Pick<Query, 'materials'>>({
		query: QUERY,
	});
	if (cacheData === null) return;

	const materials = [...cacheData.materials];

	materials.push(createMaterial);

	cache.writeQuery({
		query: QUERY,
		data: { materials },
	});
};

export const handleDeleteMaterialUpdate: MutationUpdaterFn<
	Pick<Mutation, 'deleteMaterial'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const { deleteMaterial } = data;

	if (!deleteMaterial) return;

	const cacheData = cache.readQuery<Pick<Query, 'materials'>>({
		query: QUERY,
	});
	if (cacheData === null) return;

	const materials = cacheData.materials.filter(
		(material) => material.id !== deleteMaterial.id
	);

	cache.writeQuery({ query: QUERY, data: { materials } });
};
