import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';

const QUERY = gql`
	query activeCompany {
		activeCompany @client {
			id: rid
			name
		}
	}
`;

export default (): IdName => {
	const { data } = useQuery<ActiveCompany>(QUERY);

	if (!data) return { id: '', name: '' };

	return data.activeCompany;
};
