import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

type ActiveCompany = {
	id: string;
	name: string;
};

const MUTATION = gql`
	mutation setActiveCompany($input: SetActiveCompanyInput!) @client {
		setActiveCompany(input: $input) @client
	}
`;

export default () => {
	const [setActiveCompany] = useMutation(MUTATION);

	return (company: ActiveCompany): void => {
		setActiveCompany({
			variables: { input: { rid: company.id, name: company.name } },
		});
	};
};
