import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	Card,
	Button,
	Popover,
	Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';

type StyleProps = {
	cursor: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			width: '100%',
			overflowX: 'auto',
		},
		tableRow: {
			cursor: (props: StyleProps): string => props.cursor,
		},
		confirmButtons: {
			margin: theme.spacing(1),
		},
	})
);

export interface TableItem {
	id: string;
	name: string;
	query?: any;
}

export type CellOrder = {
	align: 'left' | 'right';
	name: string;
	itemName: string;
};

type Props = {
	order: CellOrder[];
	items: TableItem[];
	onClick?: (item: TableItem) => void;
	onHover?: (t: TableItem) => void;
	onDeleteClick?: (item: TableItem) => void;
	canDelete?: boolean;
};

const SimpleTable = ({
	order,
	items,
	onClick = (): void => undefined,
	onHover = (): void => undefined,
	onDeleteClick,
	canDelete,
}: Props): JSX.Element => {
	// might want to render cursor in some special way in the future, see line below for inspiration
	// const classes = useStyles({ cursor: onClick ? 'pointer' : 'auto' });
	const classes = useStyles({ cursor: 'pointer' });
	const [selectedItem, setSelectedItem] = useState<TableItem | null>(null);

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleTriggerPopover = (
		event: React.MouseEvent<HTMLButtonElement>,
		item: TableItem
	): void => {
		setAnchorEl(event.currentTarget);
		setSelectedItem(item);
	};

	const handlePopoverClose = (): void => setAnchorEl(null);

	const handleDeleteConfirmation = (): void => {
		handlePopoverClose();
		if (onDeleteClick && selectedItem) {
			onDeleteClick(selectedItem);
		}
	};

	const handleRowClick = (item: TableItem): void => {
		onClick(item);
		setSelectedItem(item);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'delete-item-category-popover' : undefined;

	return (
		<Table stickyHeader className={classes.table}>
			<TableHead>
				<TableRow>
					{order.map((el, index) => (
						<TableCell key={index} align={el.align}>
							{el.name}
						</TableCell>
					))}
					{onDeleteClick && <TableCell />}
				</TableRow>
			</TableHead>
			<TableBody>
				{items.map((item: TableItem) => (
					<TableRow
						key={item.id}
						hover={!!onClick}
						className={classes.tableRow}
						onMouseOver={(): void => onHover(item)}
					>
						{order.map((el, index) => {
							return index === 0 ? (
								<TableCell
									key={el.name + item.id}
									component="th"
									scope="row"
									align={el.align}
									onClick={(): void => handleRowClick(item)}
								>
									{item[el.itemName]}
								</TableCell>
							) : (
								<TableCell
									key={el.name + item.id}
									align={el.align}
									onClick={(): void => handleRowClick(item)}
								>
									{item[el.itemName]}
								</TableCell>
							);
						})}
						{onDeleteClick && (
							<TableCell padding="checkbox">
								<Tooltip
									title={canDelete ? 'Delete' : 'Permission denied'}
									aria-label="delete"
								>
									<span>
										<IconButton
											disabled={!canDelete}
											onClick={(event): void =>
												handleTriggerPopover(event, item)
											}
										>
											<DeleteIcon />
										</IconButton>
									</span>
								</Tooltip>
							</TableCell>
						)}
					</TableRow>
				))}
			</TableBody>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Card>
					<Button
						className={classes.confirmButtons}
						onClick={handlePopoverClose}
						color="primary"
					>
						Cancel
					</Button>
					<Button
						className={classes.confirmButtons}
						onClick={handleDeleteConfirmation}
						variant="contained"
						color="secondary"
					>
						Remove
					</Button>
				</Card>
			</Popover>
		</Table>
	);
};

export default SimpleTable;
