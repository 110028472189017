import { gql, MutationUpdaterFn } from '@apollo/client';
import { Mutation, Query } from '../typings/graphql';

export const QUERY = gql`
	query getCompanies {
		companies {
			id
			name
			createdAt
		}
	}
`;

export const handleCreateCompanyUpdate: MutationUpdaterFn<
	Pick<Mutation, 'createCompany'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}
	const { createCompany } = data;

	if (!createCompany) return;

	try {
		const cacheData = cache.readQuery<Pick<Query, 'companies'>>({
			query: QUERY,
		});
		if (cacheData === null) {
			return;
		}

		const companies = cacheData.companies.filter(
			(company) => company.id !== createCompany.id
		);
		companies.push(createCompany);

		cache.writeQuery({
			query: QUERY,
			data: { companies },
		});
	} catch (err) {
		// We optimistically write data since nothing exists already.
		const companies = [createCompany];
		cache.writeQuery({ query: QUERY, data: { companies } });
	}
};

export const handleDeleteCompanyUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteCompany'>
> = (cache, { data }) => {
	if (data === undefined || data === null) {
		return;
	}

	const companyIdToDelete = data.deleteCompany?.id;
	const normalizedCompanyId = cache.identify({
		id: companyIdToDelete,
		__typename: 'Company',
	});
	cache.evict({ id: normalizedCompanyId });
	cache.gc();
};
