import Button from '@material-ui/core/Button/Button';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

export type FormValues = {
	password: string;
	username: string;
};

type InnerProps = {
	companyId: string;
	handleSubmit: (values: FormValues) => void;
};

// Form which is later wrapped with Formik
const InnerCreateUserForm = (props: FormikProps<FormValues> & InnerProps) => {
	const { values, touched, errors, handleChange, handleSubmit, isSubmitting } =
		props;

	return (
		<form onSubmit={handleSubmit}>
			<DialogTitle id="form-create-user">Create new user</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item sm={12}>
						<FormControl variant="outlined" fullWidth>
							<TextField
								autoFocus
								id="new-username-input"
								name="username"
								type="new-username"
								label="Username"
								value={values.username}
								onChange={handleChange}
								error={!!errors.username && touched.username}
								helperText={errors.username}
							/>
						</FormControl>
					</Grid>
					<Grid item sm={12}>
						<FormControl variant="outlined" fullWidth>
							<TextField
								id="new-password-input"
								name="password"
								type="password"
								label="Password"
								value={values.password}
								onChange={handleChange}
								error={!!errors.password && touched.password}
								helperText={errors.password || ' '}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					disabled={isSubmitting}
				>
					Submit
				</Button>
			</DialogActions>
		</form>
	);
};

export const CreateUserForm = withFormik<
	FormikFormProps<FormValues, InnerProps>,
	FormValues
>({
	mapPropsToValues: (props) => ({
		password: props.initialPassword || '',
		username: props.initialUsername || '',
	}),

	validationSchema: yup.object().shape({
		username: yup
			.string()
			.required('Required')
			.matches(/^[a-zA-Z0-9.\-_]*$/, {
				message:
					'Name must only contain letters A to Z, numbers 0-9 or any of the following symbols: ., -, and _.',
			})
			.min(5)
			.trim(),
		password: yup
			.string()
			.required('Required')
			.min(6, 'Password must be at least 6 characters.'),
	}),

	async handleSubmit(values, { props }) {
		return props.handleSubmit(values);
	},
})(InnerCreateUserForm);
