import {
	createStyles,
	Fab,
	Grid,
	makeStyles,
	Tooltip,
	useTheme,
	Zoom,
} from '@material-ui/core';
import {
	useUpdateAppBar,
	useActiveRoom,
	useMe,
	useActiveProject,
} from '../hooks';
import React, { useState } from 'react';
import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { getProjectPermissions } from 'utils/helpers';
import { mdiPrinter, mdiImport } from '@mdi/js';
import { Query } from '../typings/graphql';
import CreateItemDialog from '../components/CreateItemDialog';
import ImportItemDialog from '../components/ImportItemDialog';
import PrintArticleDialog from 'components/PrintArticleDialog';
import FabsContainer from 'components/FabsContainer';
import ArticlesCard from 'components/ArticlesCard';
import ReloadIcon from '@material-ui/icons/Replay';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Content from '../components/Content';
import Icon from '@mdi/react';

export const ROOM_QUERY = gql`
	query articleCollections($query: QueryArticleCollectionsInput!) {
		articleCollections(query: $query) {
			id
			count
			comment
			item {
				id
				name
				height
				width
				depth
				image {
					id
					url
				}
				color {
					id
					name
				}
				material {
					id
					name
				}
				subcategory {
					id
					name
					category {
						id
						name
					}
				}
			}
		}
	}
`;

const useStyles = makeStyles(() =>
	createStyles({
		grid: {
			justifyContent: 'center',
		},
		icon: {
			position: 'absolute',
			margin: 'auto',
		},
	})
);

const RoomView = (): JSX.Element | null => {
	useUpdateAppBar('Articles');
	const host = window.location.host;
	const activeRoom = useActiveRoom();
	const project = useActiveProject();
	const classes = useStyles();
	const me = useMe();
	const projectPermissions = me?.admin
		? null
		: getProjectPermissions(me, project.id);
	const [createDialogOpen, setCreateDialogOpen] = useState(false);
	const [importDialogOpen, setImportDialogOpen] = useState(false);
	const [printDialogOpen, setPrintDialogOpen] = useState(false);
	const [editable, setEditable] = useState(false);
	const theme = useTheme();

	const transitionDuration = {
		enter: theme.transitions.duration.enteringScreen,
		exit: theme.transitions.duration.leavingScreen,
	};

	/**
	 * For some reason this useQuery throws a warning whenever the activeRoom is updated and this view is active.
	 * It doesn't do anything, everything works as intended. The warning also disappears in the production version.
	 * See https://github.com/apollographql/react-apollo/issues/3863
	 * It seems to be because of reading a value from the cache that is updated. For some reason useQuery dislikes this.
	 */
	const { data, refetch } = useQuery<Pick<Query, 'articleCollections'>>(
		ROOM_QUERY,
		{
			variables: { query: { roomId: activeRoom.id } },
		}
	);

	if (!data?.articleCollections) return null;

	const allArticles = data.articleCollections;

	const renderArticles = data.articleCollections
		.slice(0)
		.sort((a, b) => a.id.localeCompare(b.id))
		.map((articleCollection) => {
			return (
				<Grid item key={articleCollection.id}>
					<ArticlesCard
						articleId={articleCollection.id}
						editable={editable}
						articleCollection={articleCollection}
					/>
				</Grid>
			);
		});

	const handleOpenCreateDialog = (): void => setCreateDialogOpen(true);
	const handleOpenImportDialog = (): void => setImportDialogOpen(true);
	const handlePrintDialogClose = (): void => setPrintDialogOpen(false);

	const handleCloseDialogs = (): void => {
		setImportDialogOpen(false);
		setCreateDialogOpen(false);
	};

	return (
		<Content>
			<Grid className={classes.grid} container spacing={2}>
				{renderArticles}
			</Grid>
			<FabsContainer>
				{(projectPermissions?.canCreate || me?.admin) && (
					<Tooltip
						title="Create new article"
						aria-label="create-article"
						placement="left"
					>
						<Fab
							color="primary"
							aria-label="create-article"
							onClick={handleOpenCreateDialog}
						>
							<AddIcon />
						</Fab>
					</Tooltip>
				)}
				{(projectPermissions?.canCreate || me?.admin) && (
					<Tooltip
						title="Import article"
						aria-label="import-article"
						placement="left"
					>
						<Fab
							size="medium"
							color="secondary"
							aria-label="import-article"
							onClick={handleOpenImportDialog}
						>
							<Icon path={mdiImport} size={1} color="#fff" />
						</Fab>
					</Tooltip>
				)}
				{(projectPermissions?.canUpdate || me?.admin) && (
					<Tooltip
						title="Edit Articles"
						aria-label="edit-articles"
						placement="left"
					>
						<Fab
							size="medium"
							color="primary"
							aria-label="edit-articles-fab"
							onClick={(): void => setEditable(!editable)}
						>
							<Zoom
								in={editable}
								timeout={transitionDuration}
								style={{
									transitionDelay: `${
										editable ? transitionDuration.enter : 0
									}ms`,
								}}
							>
								<CloseIcon className={classes.icon} />
							</Zoom>
							<Zoom
								in={!editable}
								timeout={transitionDuration}
								style={{
									transitionDelay: `${
										!editable ? transitionDuration.enter : 0
									}ms`,
								}}
							>
								<EditIcon className={classes.icon} />
							</Zoom>
						</Fab>
					</Tooltip>
				)}
				<Tooltip title="Refetch" aria-label="refetch-articles" placement="left">
					<Fab
						size="medium"
						color="secondary"
						aria-label="import-article"
						onClick={(): Promise<
							ApolloQueryResult<Pick<Query, 'articleCollections'>>
						> => refetch()}
					>
						<ReloadIcon />
					</Fab>
				</Tooltip>
				<Tooltip
					title="Print"
					aria-label="print-article-qr-codes"
					placement="left"
				>
					<Fab
						size="medium"
						color="secondary"
						aria-label="import-article"
						onClick={(): void => setPrintDialogOpen(true)}
					>
						<Icon path={mdiPrinter} size={1} style={{ color: 'white' }} />
					</Fab>
				</Tooltip>
			</FabsContainer>

			<CreateItemDialog open={createDialogOpen} onClose={handleCloseDialogs} />
			<ImportItemDialog open={importDialogOpen} onClose={handleCloseDialogs} />
			<PrintArticleDialog
				open={printDialogOpen}
				onClose={handlePrintDialogClose}
				printAllArticles={true}
				articles={allArticles}
				host={host}
			/>
		</Content>
	);
};

export default RoomView;
