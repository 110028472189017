import { gql } from '@apollo/client/core';
import { Employment, Mutation, Query } from '../typings/graphql';

export const SMALL_USERS_QUERY = gql`
	query users($companyId: ID!) {
		users(companyId: $companyId) {
			id
			name
			employments {
				company {
					id
				}
				structAdmin
				authAdmin
			}
		}
	}
`;

export const USER_PERMS_QUERY = gql`
	query user($id: ID!) {
		user(id: $id) {
			id
			name
			employments {
				id
				structAdmin
				authAdmin
				projectPermissionsList {
					id
					project {
						id
						name
					}
					employment {
						id
					}
					expiresAt
					canCreate
					canRead
					canUpdate
					canDelete
				}
			}
		}
	}
`;

export const handlePutProjectPermissionsUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'putProjectPermissions'>
> = (cache, { data }, userId) => {
	if (data && data.putProjectPermissions) {
		handleNewEmployment(cache, data.putProjectPermissions, userId);
	}
};

export const handleUpdateEmploymentUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'updateEmployment'>
> = (cache, { data }, userId) => {
	if (data && data.updateEmployment) {
		handleNewEmployment(cache, data.updateEmployment, userId);
	}
};

export const handleDeleteProjectPermissionsUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteProjectPermissions'>
> = (cache, { data }, userId) => {
	if (data && data.deleteProjectPermissions) {
		handleNewEmployment(cache, data.deleteProjectPermissions, userId);
	}
};

// Updates the cache for the corresponding 'user' query when a new employment arrives.
const handleNewEmployment = <TMutation extends keyof Mutation>(
	cache: Parameters<ExtendedMutationUpdaterFn<Pick<Mutation, TMutation>>>[0],
	newEmployment: Employment,
	userId: string
) => {
	const QUERY = gql`
		query user($id: ID!) {
			user(id: $id) {
				id
				employments {
					id
					structAdmin
					authAdmin
					projectPermissionsList {
						id
						expiresAt
						canCreate
						canRead
						canUpdate
						canDelete
					}
				}
			}
		}
	`;
	const cacheData = cache.readQuery<Pick<Query, 'user'>>({
		query: QUERY,
		variables: { id: userId },
	});
	if (cacheData === null || !cacheData.user) {
		return;
	}

	const cachedUser = cacheData.user;

	const updatedCacheUser = { ...cachedUser };
	updatedCacheUser.employments = cachedUser.employments.map((e) =>
		e.id === newEmployment.id ? { ...e, ...newEmployment } : e
	);

	cache.writeQuery({
		query: QUERY,
		variables: { id: userId },
		data: { user: updatedCacheUser },
	});
};

export const handleCreateUserUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'createUser'>
> = (cache, mutationResult, companyId) => {
	console.log('handleCreateUserUpdate called');
	const QUERY = gql`
		query users($companyId: ID!) {
			users(companyId: $companyId) {
				id
				name
				employments {
					structAdmin
					authAdmin
				}
			}
		}
	`;
	const cacheData = cache.readQuery<Pick<Query, 'users'>>({
		query: QUERY,
		variables: { companyId },
	});
	if (
		mutationResult.data?.createUser &&
		cacheData !== null &&
		cacheData.users
	) {
		const newUser = mutationResult.data.createUser;
		const newCachedUsers = [...cacheData.users];
		newCachedUsers.push(newUser);
		cache.writeQuery<Pick<Query, 'users'>>({
			query: QUERY,
			variables: { companyId },
			data: { users: newCachedUsers },
		});
	}
};

export const handleDeleteUserUpdate: ExtendedMutationUpdaterFn<
	Pick<Mutation, 'deleteUser'>
> = (cache, mutationResult, companyId) => {
	if (mutationResult.data === undefined || mutationResult.data === null) {
		return;
	}

	const QUERY = gql`
		query users($companyId: ID!) {
			users(companyId: $companyId) {
				id
				name
			}
		}
	`;

	const cacheData = cache.readQuery<Pick<Query, 'users'>>({
		query: QUERY,
		variables: { companyId },
	});
	if (
		mutationResult.data?.deleteUser &&
		cacheData !== null &&
		cacheData.users
	) {
		const deletedUser = mutationResult.data.deleteUser;
		const newCachedUsers = cacheData.users.filter(
			(user) => user.id !== deletedUser
		);
		cache.writeQuery<Pick<Query, 'users'>>({
			query: QUERY,
			variables: { companyId },
			data: { users: newCachedUsers },
		});
	}
};
