export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/**
	 * This is a custom scalar. Dates are represented as strings for server communication.
	 * Responses format dates using the spacetime 'iso' format. This format should also
	 * be used in any requests sent to the server.
	 */
	Date: string;
};

export type Article = {
	__typename?: 'Article';
	comment?: Maybe<Scalars['String']>;
	condition?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	inventoryDoneBy?: Maybe<Scalars['String']>;
	item: Item;
	lastInventoryDate?: Maybe<Scalars['Date']>;
	leaseEnd?: Maybe<Scalars['Date']>;
	leaseStart?: Maybe<Scalars['Date']>;
	owner?: Maybe<Scalars['String']>;
	placement?: Maybe<Scalars['String']>;
	purchasedAt?: Maybe<Scalars['Date']>;
	room: Room;
};

export type ArticleCollection = {
	__typename?: 'ArticleCollection';
	comment?: Maybe<Scalars['String']>;
	count: Scalars['Int'];
	id: Scalars['ID'];
	item: Item;
	owner?: Maybe<Scalars['String']>;
};

export type ArticleFieldsInput = {
	comment?: InputMaybe<Scalars['String']>;
	itemId: Scalars['String'];
	owner?: InputMaybe<Scalars['String']>;
	roomId: Scalars['String'];
};

export type AuthPayload = {
	__typename?: 'AuthPayload';
	token?: Maybe<Scalars['String']>;
	user?: Maybe<User>;
};

export type Building = {
	__typename?: 'Building';
	createdAt: Scalars['Date'];
	floors: Array<Floor>;
	id: Scalars['ID'];
	name: Scalars['String'];
	project: Project;
};

export type ChangePasswordInput = {
	newPassword: Scalars['String'];
	oldPassword: Scalars['String'];
};

export type Color = {
	__typename?: 'Color';
	id: Scalars['ID'];
	items: Array<Item>;
	name: Scalars['String'];
};

export type Company = {
	__typename?: 'Company';
	createdAt: Scalars['Date'];
	id: Scalars['ID'];
	name: Scalars['String'];
	projects: Array<Project>;
};

export type CreateArticleInput = {
	comment?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	inventoryDoneBy?: InputMaybe<Scalars['String']>;
	itemId: Scalars['ID'];
	lastInventoryDate?: InputMaybe<Scalars['Date']>;
	leaseEnd?: InputMaybe<Scalars['Date']>;
	leaseStart?: InputMaybe<Scalars['Date']>;
	owner?: InputMaybe<Scalars['String']>;
	placement?: InputMaybe<Scalars['String']>;
	purchasedAt?: InputMaybe<Scalars['Date']>;
	roomId: Scalars['ID'];
};

export type CreateBuildingInput = {
	id?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
	projectId: Scalars['ID'];
};

export type CreateColorInput = {
	id?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
};

export type CreateCompanyInput = {
	id?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
};

export type CreateFloorInput = {
	buildingId: Scalars['ID'];
	id?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
};

export type CreateImageInput = {
	id?: InputMaybe<Scalars['ID']>;
	projectId: Scalars['ID'];
};

export type CreateImagePayload = {
	__typename?: 'CreateImagePayload';
	image?: Maybe<Image>;
	signedUploadUrl: Scalars['String'];
};

export type CreateItemCategoryInput = {
	id?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
};

export type CreateItemInput = {
	colorId?: InputMaybe<Scalars['ID']>;
	depth?: InputMaybe<Scalars['Float']>;
	diameter?: InputMaybe<Scalars['Float']>;
	height?: InputMaybe<Scalars['Float']>;
	id?: InputMaybe<Scalars['ID']>;
	imageId: Scalars['ID'];
	itemSubcategoryId: Scalars['ID'];
	materialId?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
	projectId: Scalars['ID'];
	secondaryDepth?: InputMaybe<Scalars['Float']>;
	width?: InputMaybe<Scalars['Float']>;
};

export type CreateItemSubcategoryInput = {
	id?: InputMaybe<Scalars['ID']>;
	itemCategoryId: Scalars['ID'];
	name: Scalars['String'];
};

export type CreateMaterialInput = {
	id?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
};

export type CreateProjectInput = {
	companyId: Scalars['ID'];
	id?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
};

export type CreateRoomInput = {
	floorId: Scalars['ID'];
	id?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
};

export type CreateUserInput = {
	companyId: Scalars['String'];
	name: Scalars['String'];
	password: Scalars['String'];
};

export type DeleteProjectPermissionsInput = {
	employmentId: Scalars['ID'];
	projectId: Scalars['ID'];
};

export type DimensionRange = {
	__typename?: 'DimensionRange';
	max: Scalars['Float'];
	min: Scalars['Float'];
};

export type Employment = {
	__typename?: 'Employment';
	authAdmin: Scalars['Boolean'];
	company: Company;
	createdAt: Scalars['Date'];
	id: Scalars['ID'];
	projectPermissionsList: Array<ProjectPermissions>;
	structAdmin: Scalars['Boolean'];
	user: User;
};

export type FileExport = {
	__typename?: 'FileExport';
	format: FileFormat;
	id: Scalars['ID'];
	name: Scalars['String'];
	project: Project;
	url?: Maybe<Scalars['String']>;
};

export enum FileExportVariant {
	Csv = 'CSV',
	PdfByRoom = 'PDF_BY_ROOM',
	PdfSummary = 'PDF_SUMMARY',
}

export enum FileFormat {
	Csv = 'csv',
	Pdf = 'pdf',
}

export type FilterOption = {
	__typename?: 'FilterOption';
	id: Scalars['ID'];
	value: Scalars['String'];
};

export type FilterOptions = {
	__typename?: 'FilterOptions';
	categories: Array<Maybe<FilterOption>>;
	colors: Array<Maybe<FilterOption>>;
	depth?: Maybe<DimensionRange>;
	diameter?: Maybe<DimensionRange>;
	height?: Maybe<DimensionRange>;
	materials: Array<Maybe<FilterOption>>;
	subcategories: Array<Maybe<FilterOption>>;
	width?: Maybe<DimensionRange>;
};

export type Filters = {
	categories: Array<InputMaybe<Scalars['ID']>>;
	colors: Array<InputMaybe<Scalars['ID']>>;
	depth: Array<InputMaybe<Scalars['Float']>>;
	diameter: Array<InputMaybe<Scalars['Float']>>;
	height: Array<InputMaybe<Scalars['Float']>>;
	materials: Array<InputMaybe<Scalars['ID']>>;
	subcategories: Array<InputMaybe<Scalars['ID']>>;
	width: Array<InputMaybe<Scalars['Float']>>;
};

export type Floor = {
	__typename?: 'Floor';
	building: Building;
	createdAt: Scalars['Date'];
	id: Scalars['ID'];
	name: Scalars['String'];
	rooms: Array<Room>;
};

export type GenerateFileExportInput = {
	id?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
	projectId: Scalars['ID'];
	variant: FileExportVariant;
};

export type Image = {
	__typename?: 'Image';
	id: Scalars['ID'];
	items: Array<Item>;
	url: Scalars['String'];
};

export type Item = {
	__typename?: 'Item';
	articles: Array<Article>;
	color?: Maybe<Color>;
	depth?: Maybe<Scalars['Float']>;
	diameter?: Maybe<Scalars['Float']>;
	height?: Maybe<Scalars['Float']>;
	id: Scalars['ID'];
	image: Image;
	material?: Maybe<Material>;
	name?: Maybe<Scalars['String']>;
	numberOfArticles?: Maybe<Scalars['Int']>;
	project: Project;
	secondaryDepth?: Maybe<Scalars['Float']>;
	subcategory: ItemSubcategory;
	width?: Maybe<Scalars['Float']>;
};

export type ItemCategory = {
	__typename?: 'ItemCategory';
	id: Scalars['ID'];
	name?: Maybe<Scalars['String']>;
	subcategories: Array<ItemSubcategory>;
};

export type ItemSubcategory = {
	__typename?: 'ItemSubcategory';
	category: ItemCategory;
	id: Scalars['ID'];
	name?: Maybe<Scalars['String']>;
};

export type Location = {
	buildingId?: InputMaybe<Scalars['ID']>;
	floorId?: InputMaybe<Scalars['ID']>;
	roomId?: InputMaybe<Scalars['ID']>;
};

export type Locations = {
	__typename?: 'Locations';
	articles?: Maybe<Array<Maybe<Article>>>;
	buildings?: Maybe<Array<Maybe<Building>>>;
	company?: Maybe<Array<Maybe<Company>>>;
	floors?: Maybe<Array<Maybe<Floor>>>;
	projects?: Maybe<Array<Maybe<Project>>>;
	rooms?: Maybe<Array<Maybe<Room>>>;
};

export type LoginInput = {
	name: Scalars['String'];
	password: Scalars['String'];
};

export type Material = {
	__typename?: 'Material';
	id: Scalars['ID'];
	items: Array<Item>;
	name: Scalars['String'];
};

export type Mutation = {
	__typename?: 'Mutation';
	addCommentToArticleInCollection: Array<ArticleCollection>;
	changePassword?: Maybe<Scalars['Boolean']>;
	createArticle?: Maybe<Article>;
	createBuilding?: Maybe<Building>;
	createColor?: Maybe<Color>;
	createCompany?: Maybe<Company>;
	createFloor?: Maybe<Floor>;
	createImage?: Maybe<CreateImagePayload>;
	createItem?: Maybe<Item>;
	createItemCategory?: Maybe<ItemCategory>;
	createItemSubcategory?: Maybe<ItemSubcategory>;
	createMaterial?: Maybe<Material>;
	createProject?: Maybe<Project>;
	createRoom?: Maybe<Room>;
	createUser?: Maybe<User>;
	deleteArticle?: Maybe<Article>;
	deleteArticles?: Maybe<Array<Article>>;
	deleteBuilding?: Maybe<Building>;
	deleteColor?: Maybe<Color>;
	deleteCompany?: Maybe<Company>;
	deleteFileExport?: Maybe<FileExport>;
	deleteFloor?: Maybe<Floor>;
	deleteItem?: Maybe<Item>;
	deleteItemCategory?: Maybe<ItemCategory>;
	/**
	 * Since it deletes the subcategory, you cannot retrieve its category
	 * in the same query as you delete the subcategory.
	 */
	deleteItemSubcategory?: Maybe<ItemSubcategory>;
	/** Cannot fetch the items of a Material since the material will have been deleted. */
	deleteMaterial?: Maybe<Material>;
	deleteProject?: Maybe<Project>;
	deleteProjectPermissions?: Maybe<Employment>;
	deleteRoom?: Maybe<Room>;
	/** Returns the ID of the deleted user if the user is deleted. Returns an error if the deletion failed. */
	deleteUser: Scalars['ID'];
	generateFileExport?: Maybe<FileExport>;
	login?: Maybe<AuthPayload>;
	putProjectPermissions?: Maybe<Employment>;
	removeCommentFromArticleInCollection: Array<ArticleCollection>;
	setArticleCollectionCount?: Maybe<ArticleCollection>;
	updateArticle?: Maybe<Article>;
	updateBuilding?: Maybe<Building>;
	updateColor?: Maybe<Color>;
	updateCompany?: Maybe<Company>;
	updateEmployment?: Maybe<Employment>;
	updateFloor?: Maybe<Floor>;
	updateItem?: Maybe<Item>;
	updateItemCategory?: Maybe<ItemCategory>;
	updateItemSubcategory?: Maybe<ItemSubcategory>;
	updateMaterial?: Maybe<Material>;
	updateProject?: Maybe<Project>;
	updateRoom?: Maybe<Room>;
};

export type MutationAddCommentToArticleInCollectionArgs = {
	comment: Scalars['String'];
	query: QueryArticleCollectionInput;
};

export type MutationChangePasswordArgs = {
	input: ChangePasswordInput;
};

export type MutationCreateArticleArgs = {
	article: CreateArticleInput;
};

export type MutationCreateBuildingArgs = {
	building: CreateBuildingInput;
};

export type MutationCreateColorArgs = {
	color: CreateColorInput;
};

export type MutationCreateCompanyArgs = {
	company: CreateCompanyInput;
};

export type MutationCreateFloorArgs = {
	floor: CreateFloorInput;
};

export type MutationCreateImageArgs = {
	image: CreateImageInput;
};

export type MutationCreateItemArgs = {
	item: CreateItemInput;
};

export type MutationCreateItemCategoryArgs = {
	itemCategory: CreateItemCategoryInput;
};

export type MutationCreateItemSubcategoryArgs = {
	itemSubcategory: CreateItemSubcategoryInput;
};

export type MutationCreateMaterialArgs = {
	material: CreateMaterialInput;
};

export type MutationCreateProjectArgs = {
	project: CreateProjectInput;
};

export type MutationCreateRoomArgs = {
	room: CreateRoomInput;
};

export type MutationCreateUserArgs = {
	input: CreateUserInput;
};

export type MutationDeleteArticleArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteArticlesArgs = {
	ids: Array<Scalars['ID']>;
};

export type MutationDeleteBuildingArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteColorArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteCompanyArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteFileExportArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteFloorArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteItemArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteItemCategoryArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteItemSubcategoryArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteMaterialArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteProjectArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteProjectPermissionsArgs = {
	input: DeleteProjectPermissionsInput;
};

export type MutationDeleteRoomArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
	id: Scalars['ID'];
};

export type MutationGenerateFileExportArgs = {
	input: GenerateFileExportInput;
};

export type MutationLoginArgs = {
	input: LoginInput;
};

export type MutationPutProjectPermissionsArgs = {
	input: PutProjectPermissionsInput;
};

export type MutationRemoveCommentFromArticleInCollectionArgs = {
	query: QueryArticleCollectionInput;
};

export type MutationSetArticleCollectionCountArgs = {
	count: Scalars['Int'];
	query: QueryArticleCollectionInput;
};

export type MutationUpdateArticleArgs = {
	article: UpdateArticleInput;
};

export type MutationUpdateBuildingArgs = {
	building: UpdateBuildingInput;
};

export type MutationUpdateColorArgs = {
	color: UpdateColorInput;
};

export type MutationUpdateCompanyArgs = {
	company: UpdateCompanyInput;
};

export type MutationUpdateEmploymentArgs = {
	input: UpdateEmploymentInput;
};

export type MutationUpdateFloorArgs = {
	floor: UpdateFloorInput;
};

export type MutationUpdateItemArgs = {
	item: UpdateItemInput;
};

export type MutationUpdateItemCategoryArgs = {
	itemCategory: UpdateItemCategoryInput;
};

export type MutationUpdateItemSubcategoryArgs = {
	itemSubcategory: UpdateItemSubcategoryInput;
};

export type MutationUpdateMaterialArgs = {
	material: UpdateMaterialInput;
};

export type MutationUpdateProjectArgs = {
	project: UpdateProjectInput;
};

export type MutationUpdateRoomArgs = {
	room: UpdateRoomInput;
};

export type PaginatedItems = {
	__typename?: 'PaginatedItems';
	items?: Maybe<Array<Item>>;
	numberOfItems?: Maybe<Scalars['Int']>;
};

export type PaginatedRooms = {
	__typename?: 'PaginatedRooms';
	numberOfRooms: Scalars['Int'];
	rooms: Array<Room>;
};

export type Project = {
	__typename?: 'Project';
	buildings: Array<Building>;
	company: Company;
	createdAt: Scalars['Date'];
	id: Scalars['ID'];
	items: Array<Item>;
	name: Scalars['String'];
};

export type ProjectPermissions = {
	__typename?: 'ProjectPermissions';
	canCreate: Scalars['Boolean'];
	canDelete: Scalars['Boolean'];
	canRead: Scalars['Boolean'];
	canUpdate: Scalars['Boolean'];
	employment: Employment;
	expiresAt: Scalars['Date'];
	id: Scalars['ID'];
	project: Project;
};

export type PutProjectPermissionsInput = {
	canCreate: Scalars['Boolean'];
	canDelete: Scalars['Boolean'];
	canRead: Scalars['Boolean'];
	canUpdate: Scalars['Boolean'];
	employmentId: Scalars['ID'];
	expiresAt: Scalars['Date'];
	projectId: Scalars['ID'];
};

export type Query = {
	__typename?: 'Query';
	article?: Maybe<Article>;
	articleCollections: Array<ArticleCollection>;
	articles: Array<Article>;
	building?: Maybe<Building>;
	buildings: Array<Building>;
	color?: Maybe<Color>;
	colors: Array<Color>;
	companies: Array<Company>;
	company?: Maybe<Company>;
	fileExport?: Maybe<FileExport>;
	fileExports: Array<FileExport>;
	filterOptions: FilterOptions;
	floor?: Maybe<Floor>;
	floors: Array<Floor>;
	image?: Maybe<Image>;
	images: Array<Image>;
	item?: Maybe<Item>;
	itemCategories: Array<ItemCategory>;
	itemCategory?: Maybe<ItemCategory>;
	itemSubcategories: Array<ItemSubcategory>;
	itemSubcategory?: Maybe<ItemSubcategory>;
	items: Array<Item>;
	locations: Locations;
	material?: Maybe<Material>;
	materials: Array<Material>;
	me?: Maybe<User>;
	paginatedItems: PaginatedItems;
	paginatedRooms: PaginatedRooms;
	project?: Maybe<Project>;
	projects: Array<Project>;
	room?: Maybe<Room>;
	rooms: Array<Room>;
	user?: Maybe<User>;
	users?: Maybe<Array<User>>;
};

export type QueryArticleArgs = {
	id: Scalars['ID'];
};

export type QueryArticleCollectionsArgs = {
	query: QueryArticleCollectionsInput;
};

export type QueryArticlesArgs = {
	input: QueryArticlesInput;
};

export type QueryBuildingArgs = {
	id: Scalars['ID'];
};

export type QueryBuildingsArgs = {
	input: QueryBuildingsInput;
};

export type QueryColorArgs = {
	id: Scalars['ID'];
};

export type QueryColorsArgs = {
	input?: InputMaybe<QueryColorsInput>;
};

export type QueryCompanyArgs = {
	id: Scalars['ID'];
};

export type QueryFileExportArgs = {
	id: Scalars['ID'];
};

export type QueryFileExportsArgs = {
	input: QueryFileExportsInput;
};

export type QueryFilterOptionsArgs = {
	input: QueryFilterOptionsInput;
};

export type QueryFloorArgs = {
	id: Scalars['ID'];
};

export type QueryFloorsArgs = {
	input: QueryFloorsInput;
};

export type QueryImageArgs = {
	id: Scalars['ID'];
};

export type QueryImagesArgs = {
	input: QueryImagesInput;
};

export type QueryItemArgs = {
	id: Scalars['ID'];
};

export type QueryItemCategoriesArgs = {
	input?: InputMaybe<QueryItemCategoriesInput>;
};

export type QueryItemCategoryArgs = {
	id: Scalars['ID'];
};

export type QueryItemSubcategoriesArgs = {
	input: QueryItemSubcategoriesInput;
};

export type QueryItemSubcategoryArgs = {
	id: Scalars['ID'];
};

export type QueryItemsArgs = {
	input: QueryItemsInput;
};

export type QueryLocationsArgs = {
	input: QueryLocationsInput;
};

export type QueryMaterialArgs = {
	id: Scalars['ID'];
};

export type QueryMaterialsArgs = {
	input?: InputMaybe<QueryMaterialsInput>;
};

export type QueryPaginatedItemsArgs = {
	input: QueryItemsInput;
};

export type QueryPaginatedRoomsArgs = {
	input: QueryRoomsInput;
};

export type QueryProjectArgs = {
	input: QueryProjectInput;
};

export type QueryProjectsArgs = {
	input: QueryProjectsInput;
};

export type QueryRoomArgs = {
	id: Scalars['ID'];
};

export type QueryRoomsArgs = {
	input: QueryRoomsInput;
};

export type QueryUserArgs = {
	id: Scalars['ID'];
};

export type QueryUsersArgs = {
	companyId: Scalars['ID'];
};

export type QueryArticleCollectionInput = {
	comment?: InputMaybe<Scalars['String']>;
	itemId: Scalars['ID'];
	owner?: InputMaybe<Scalars['String']>;
	roomId: Scalars['ID'];
};

export type QueryArticleCollectionsInput = {
	roomId: Scalars['ID'];
};

export type QueryArticlesInput = {
	building?: InputMaybe<Scalars['ID']>;
	floor?: InputMaybe<Scalars['ID']>;
	ids?: InputMaybe<Array<Scalars['ID']>>;
	item?: InputMaybe<Scalars['ID']>;
	room?: InputMaybe<Scalars['ID']>;
	searchInput?: InputMaybe<Scalars['String']>;
};

export type QueryBuildingsInput = {
	ids?: InputMaybe<Array<Scalars['ID']>>;
	project?: InputMaybe<Scalars['ID']>;
};

export type QueryColorsInput = {
	ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryFileExportsInput = {
	project?: InputMaybe<Scalars['ID']>;
};

export type QueryFilterOptionsInput = {
	project: Scalars['ID'];
};

export type QueryFloorsInput = {
	building?: InputMaybe<Scalars['ID']>;
	ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryImagesInput = {
	ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryItemCategoriesInput = {
	ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryItemSubcategoriesInput = {
	category?: InputMaybe<Scalars['ID']>;
	ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryItemsInput = {
	filters?: InputMaybe<Filters>;
	ids?: InputMaybe<Array<Scalars['ID']>>;
	limit?: InputMaybe<Scalars['Int']>;
	location?: InputMaybe<Location>;
	offset?: InputMaybe<Scalars['Int']>;
	project?: InputMaybe<Scalars['ID']>;
	reverseSortOrder?: InputMaybe<Scalars['Boolean']>;
	searchInput?: InputMaybe<Scalars['String']>;
	sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryLocationsInput = {
	building?: InputMaybe<Scalars['ID']>;
	company?: InputMaybe<Scalars['ID']>;
	floor?: InputMaybe<Scalars['ID']>;
	project?: InputMaybe<Scalars['ID']>;
	room?: InputMaybe<Scalars['ID']>;
};

export type QueryMaterialsInput = {
	ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryProjectInput = {
	article?: InputMaybe<Scalars['ID']>;
	project?: InputMaybe<Scalars['ID']>;
};

export type QueryProjectsInput = {
	company?: InputMaybe<Scalars['ID']>;
	ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryRoomsInput = {
	floor?: InputMaybe<Scalars['ID']>;
	ids?: InputMaybe<Array<Scalars['ID']>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	project?: InputMaybe<Scalars['ID']>;
};

export type Room = {
	__typename?: 'Room';
	articleCollections: Array<ArticleCollection>;
	createdAt: Scalars['Date'];
	floor: Floor;
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type UpdateArticleInput = {
	comment?: InputMaybe<Scalars['String']>;
	condition?: InputMaybe<Scalars['String']>;
	id: Scalars['ID'];
	inventoryDoneBy?: InputMaybe<Scalars['String']>;
	itemId?: InputMaybe<Scalars['ID']>;
	lastInventoryDate?: InputMaybe<Scalars['Date']>;
	leaseEnd?: InputMaybe<Scalars['Date']>;
	leaseStart?: InputMaybe<Scalars['Date']>;
	owner?: InputMaybe<Scalars['String']>;
	placement?: InputMaybe<Scalars['String']>;
	purchasedAt?: InputMaybe<Scalars['Date']>;
	roomId?: InputMaybe<Scalars['ID']>;
};

export type UpdateBuildingInput = {
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type UpdateColorInput = {
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type UpdateCompanyInput = {
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type UpdateEmploymentInput = {
	authAdmin?: InputMaybe<Scalars['Boolean']>;
	employmentId: Scalars['ID'];
	structAdmin?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateFloorInput = {
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type UpdateItemCategoryInput = {
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type UpdateItemInput = {
	colorId?: InputMaybe<Scalars['ID']>;
	depth?: InputMaybe<Scalars['Float']>;
	diameter?: InputMaybe<Scalars['Float']>;
	height?: InputMaybe<Scalars['Float']>;
	id: Scalars['ID'];
	imageId?: InputMaybe<Scalars['ID']>;
	itemSubcategoryId?: InputMaybe<Scalars['ID']>;
	materialId?: InputMaybe<Scalars['ID']>;
	name?: InputMaybe<Scalars['String']>;
	projectId?: InputMaybe<Scalars['ID']>;
	secondaryDepth?: InputMaybe<Scalars['Float']>;
	width?: InputMaybe<Scalars['Float']>;
};

export type UpdateItemSubcategoryInput = {
	id: Scalars['ID'];
	itemCategoryId?: InputMaybe<Scalars['ID']>;
	name?: InputMaybe<Scalars['String']>;
};

export type UpdateMaterialInput = {
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type UpdateProjectInput = {
	companyId?: InputMaybe<Scalars['ID']>;
	id: Scalars['ID'];
	name?: InputMaybe<Scalars['String']>;
};

export type UpdateRoomInput = {
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type User = {
	__typename?: 'User';
	admin: Scalars['Boolean'];
	employments: Array<Employment>;
	id: Scalars['ID'];
	name: Scalars['String'];
};
