import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { updateHandlerByName } from '../graphql';
import {
	Mutation,
	MutationCreateArticleArgs,
	Item,
	Room,
} from '../typings/graphql';

const MUTATION = gql`
	mutation createArticle($article: CreateArticleInput!) {
		createArticle(article: $article) {
			id
			comment
			item {
				id
			}
			room {
				id
			}
		}
	}
`;

export default (): ((
	variables: MutationCreateArticleArgs
) => Promise<void>) => {
	const [addArticle] = useMutation<
		Pick<Mutation, 'createArticle'>,
		MutationCreateArticleArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createArticle,
	});

	return async (variables): Promise<void> => {
		try {
			await addArticle({
				variables,
				optimisticResponse: {
					createArticle: {
						__typename: 'Article',
						id: variables.article.id!,
						comment: variables.article?.comment || undefined,
						item: {
							__typename: 'Item',
							id: variables.article.itemId,
						} as Item,
						room: {
							__typename: 'Room',
							id: variables.article.roomId,
						} as Room,
					},
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
