import {
	Button,
	CardActions,
	CardContent,
	FormControl,
	Grid,
	TextField,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

export type FormValues = {
	name: string;
	password: string;
};

type Props = {
	onSubmit: (values: FormValues) => Promise<void>;
};

type FormProps = {
	initialName?: string;
};

const InnerForm = (props: FormikProps<FormValues> & Props): JSX.Element => {
	const { values, errors, touched, handleChange, handleSubmit, isSubmitting } =
		props;

	return (
		<form onSubmit={handleSubmit}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControl fullWidth>
							<TextField
								id="input-user-field"
								label="User"
								name="name"
								autoFocus
								value={values.name}
								onChange={handleChange}
								error={!!errors.name && touched.name}
								helperText={touched.name && errors.name}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl fullWidth>
							<TextField
								id="input-password-field"
								label="Password"
								name="password"
								type="password"
								value={values.password}
								onChange={handleChange}
								error={!!errors.password && touched.password}
								helperText={touched.password && errors.password}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions style={{ flexDirection: 'row-reverse' }}>
				<Button color="primary" type="submit" disabled={isSubmitting}>
					Login
				</Button>
			</CardActions>
		</form>
	);
};

const LoginForm = withFormik<FormProps & Props, FormValues>({
	mapPropsToValues: (props) => ({
		name: props.initialName || '',
		password: '',
	}),

	validationSchema: yup.object({
		name: yup
			.string()
			.max(255, 'Max 255 characters')
			.trim()
			.required('Username is required.'),
		password: yup.string().trim().required('Password is required'),
	}),

	async handleSubmit(values, { props }) {
		return props.onSubmit(values);
	},
})(InnerForm);

export default LoginForm;
