import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks/useQuery';

const QUERY = gql`
	query activeProject {
		activeProject @client {
			id: rid
			name
		}
	}
`;

export default (): IdName => {
	const { data } = useQuery<ActiveProject>(QUERY);

	if (!data) return { id: '', name: '' };

	return data.activeProject;
};
