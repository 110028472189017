import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React from 'react';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			alignSelf: 'center',
		},
	})
);

const Arrow = (): JSX.Element => {
	const classes = useStyles();
	return <ArrowRightIcon className={classes.root} fontSize="small" />;
};

export default Arrow;
