import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { updateHandlerByName } from '../graphql';
import {
	MutationCreateFloorArgs,
	Mutation,
	Building,
	Floor,
} from '../typings/graphql';

const MUTATION = gql`
	mutation createFloor($floor: CreateFloorInput!) {
		createFloor(floor: $floor) {
			id
			name
			building {
				id
			}
		}
	}
`;

export default (): ((variables: MutationCreateFloorArgs) => Promise<void>) => {
	const [addFloor] = useMutation<
		Pick<Mutation, 'createFloor'>,
		MutationCreateFloorArgs
	>(MUTATION, {
		context: {
			serializationKey: 'MUTATION',
			tracked: true,
		},

		update: updateHandlerByName.createFloor,
	});

	return async (variables): Promise<void> => {
		try {
			await addFloor({
				variables,
				optimisticResponse: {
					createFloor: {
						__typename: 'Floor',
						id: variables.floor.id!,
						name: variables.floor.name,
						building: {
							__typename: 'Building',
							id: variables.floor.buildingId,
						} as Building,
					} as Floor,
				},
			});
		} catch (error) {
			console.log('err', error);
			// Let error silently die
		}
	};
};
