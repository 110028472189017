import { Popover } from '@material-ui/core';
import React, { useState } from 'react';
import spacetime from 'spacetime';
import nameSort from '../utils/nameSort';
import { v4 as uuid } from 'uuid';
import { CellOrder, TableItem } from '../elements/SimpleTable';
import { useCreateCompany, useMe, useSetActiveCompany } from '../hooks';
import CreateNameForm, { FormValues } from './CreateNameForm';
import EnhancedTable from './EnhancedTable';
import { Query } from '../typings/graphql';
import { gql } from '@apollo/client/core';
import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import DeleteLocations from './DeleteLocations';

const QUERY = gql`
	query allCompanies {
		companies {
			id
			name
			createdAt
		}
	}
`;

const PROJECTS_QUERY = gql`
	query projectsByCompany($input: QueryProjectsInput!) {
		projects(input: $input) {
			id
			name
		}
	}
`;

const cellOrder: CellOrder[] = [
	{
		align: 'left',
		name: 'Company',
		itemName: 'name',
	},
	{
		align: 'right',
		name: 'Created',
		itemName: 'createdAt',
	},
];

type Props = {
	onSelection?: (event?: MouseEvent) => void;
};

const CompaniesTable = ({ onSelection }: Props): JSX.Element => {
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [companyToDelete, setCompanyToDelete] = useState<
		TableItem | undefined
	>();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const client = useApolloClient();
	const me = useMe();
	const setActiveCompany = useSetActiveCompany();
	const createCompany = useCreateCompany();

	const { data, refetch } = useQuery<Pick<Query, 'companies'>>(QUERY, {
		returnPartialData: true,
	});
	const companies = data?.companies;

	const handleTriggerPopover = (
		event: React.MouseEvent<HTMLButtonElement>
	): void => setAnchorEl(event.currentTarget);

	const handlePopoverClose = (): void => setAnchorEl(null);

	const handleSubmit = async (values: FormValues): Promise<void> => {
		const variables = {
			company: {
				id: uuid(),
				name: values.name,
			},
		};
		createCompany(variables);
	};

	const handleListItemClick = (item: TableItem): void => {
		setActiveCompany(item);
		if (onSelection) onSelection();
	};

	const handleListItemHover = (item: TableItem): void => {
		item.query &&
			client.query({
				query: PROJECTS_QUERY,
				variables: { input: { company: item.id } },
			});
	};

	const sorted = companies
		?.slice(0)
		.sort(nameSort)
		.map((company) => {
			const createdAt = company.createdAt ? company.createdAt : '';
			const createdAtFormatted = spacetime(createdAt)
				.format('iso-short')
				.toString();
			return { ...company, createdAt: createdAtFormatted };
		});

	const open = Boolean(anchorEl);
	const id = open ? 'create-company-popover' : undefined;

	const handleDeleteLocation = (item: TableItem) => {
		setCompanyToDelete(item);
		setOpenDeleteDialog(true);
	};

	return (
		<>
			<EnhancedTable
				empty="No companies exists yet"
				reload={refetch}
				items={sorted ? (sorted as TableItem[]) : []}
				searchPlaceholder="Search for company name"
				order={cellOrder}
				onClick={handleTriggerPopover}
				onDeleteClick={handleDeleteLocation}
				onListItemClick={handleListItemClick}
				onListItemHover={handleListItemHover}
				canCreate={me?.admin}
				canDelete={me?.admin}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<CreateNameForm
					label="Company Name"
					onSubmit={handleSubmit}
					onClose={handlePopoverClose}
				/>
			</Popover>
			<DeleteLocations
				setOpenDeleteDialog={setOpenDeleteDialog}
				openDeleteDialog={openDeleteDialog}
				companyToDelete={companyToDelete}
			/>
		</>
	);
};

export default CompaniesTable;
