import React from 'react';
import { RouteProps, Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({
	children,
	allow,
	...rest
}: RouteProps & { allow: boolean }): JSX.Element => {
	if (allow) {
		return <Route {...rest}>{children}</Route>;
	}
	return <Redirect to={{ pathname: '/' }} />;
};

export default PrivateRoute;
