import React from 'react';
import Content from '../components/Content';
import { useUpdateAppBar } from '../hooks';

const HomeView = (): JSX.Element => {
	useUpdateAppBar('Home');

	return (
		<Content>
			<div>Here an overview of all the data should be shown</div>
		</Content>
	);
};

export default HomeView;
