import CssBaseline from '@material-ui/core/CssBaseline';
import SyncMutations from './components/SyncMutations';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ApolloPersist from './graphql/Persist';
import Router from './router';
import { persistor, store } from './store';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#00294D',
		},
	},
});

const App = (): JSX.Element => {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<ApolloPersist>
					<SyncMutations>
						<SnackbarProvider
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							maxSnack={3}
						>
							<ThemeProvider theme={theme}>
								<CssBaseline />
								<BrowserRouter>
									<Router />
								</BrowserRouter>
							</ThemeProvider>
						</SnackbarProvider>
					</SyncMutations>
				</ApolloPersist>
			</PersistGate>
		</Provider>
	);
};

export default App;
